import { Button } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";

interface CreateUserButtonProps {
    openDialog: () => void;
};

export default function CreateUserButton(props: CreateUserButtonProps) {
    const language = useLanguage();

    return <Button onClick={props.openDialog} variant="contained">
        {language.users.create}
    </Button>
}