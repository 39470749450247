import { Box } from "@mui/material";
import Header from "./Header";

interface DashboardPanelContainerProps {
    drawerWidth: number;
    children: JSX.Element | JSX.Element[];
};

function DashboardPanelContainer(props: DashboardPanelContainerProps) {
    return <Box className="dashboard-panel" sx={{marginLeft: props.drawerWidth, pt: 0, mt: 0}}>
        <Header />
        {props.children}
    </Box>
};

export default DashboardPanelContainer;