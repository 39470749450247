import "./AssignmentTable.css";
import { Box, Button, InputAdornment, Stack, Switch, TextField, Typography } from '@mui/material';
import { DataGrid, GridCellParams, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { Assignment, User } from 'inspector-common/lib/model';
import { Spinner } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";
import OptionsMenu from "./OptionsMenu";
import { getDateString } from "../../../../utils";
import { useNavigate } from "react-router-dom";
import { useState } from "react";
import SearchIcon from '@mui/icons-material/Search';
import { useAuth } from "../../../../auth";
import { formatAddress } from "inspector-common/lib/utils/utils";
import { Archive } from "@mui/icons-material";

interface AssignmentTableProps {
    archive: boolean
    setArchive: (archive: boolean) => void
    loadingAssignments: boolean,
    assignments: Array<Assignment>
    users: Array<User>
    refresh: () => void
};

const styles = {
  tableBox: {
    height: "80%"
  },
  table: {
    height: "100%",
    borderColor: "#DDDDDD",
    borderSize: "1px",
  },
  archiveButton: {
    whiteSpace: "nowrap",
    minWidth: "220px"
  }
}

function getUserName(id: string, users: Array<User>, defaultName: string): string {
  for (var user of users) {
    if (user.identifier === id) {
      return `${user.first_name} ${user.last_name}`;
    }
  }
  return defaultName
}

function AssignmentTable(props: AssignmentTableProps) {
  const language = useLanguage();
  const navigate = useNavigate();
  const [search, setSearch] = useState<string>("");
  const [filterMine, setFilterMine] = useState<boolean>(true);
  const { config } = useAuth();

  const columns: GridColDef[] = [
    { 
      field: 'reference', 
      headerName: language.document.reference, 
      headerClassName: "assignment-table-header",
      description: language.document.reference, 
      valueGetter: (params: GridValueGetterParams) => params.row.reference ?? "",
      flex: 1
    },
    { 
      field: 'name', 
      headerName: language.assignments.name,
      headerClassName: "assignment-table-header",
      description: language.assignments.name, 
      flex: 1
    },
    { 
      field: 'planDate', 
      headerName: language.assignments.planDate, 
      headerClassName: "assignment-table-header",
      description: language.assignments.planDate, 
      valueGetter: (params: GridValueGetterParams) => getDateString(params.row.plan_date) ?? "",
      flex: 1
    },
    { 
      field: 'address', 
      headerName: language.assignments.attributes.address.address, 
      headerClassName: "assignment-table-header",
      description: language.assignments.planDate, 
      valueGetter: (params: GridValueGetterParams) => formatAddress(params.row.address) ?? "",
      flex: 1
    },
    { 
      field: 'status', 
      headerName: language.assignments.status.label, 
      headerClassName: "assignment-table-header",
      description: language.assignments.status.label,
      valueGetter: (params: GridValueGetterParams) => (language.assignments.status as any)[params.row.status.toString()],
      flex: 1
    },
    {
      field: 'assignee',
      headerName: language.assignments.assignee,
      headerClassName: "assignment-table-header",
      description: language.assignments.assignee,
      valueGetter: (params: GridValueGetterParams) => params.row.assignee === null ? "" : getUserName(params.row.assignee, props.users, language.users.unknown),
      flex: 1
    },
    {
      field: "Opties",
      //disableReorder: true,
      disableColumnMenu: true,
      headerClassName: "assignment-table-header",
      sortable: false,
      renderCell: (cellValues) => {
        return <OptionsMenu 
          users={props.users} 
          assignment={cellValues.row} 
          onDelete={props.refresh}
          onAssign={props.refresh}
          onMarkStatus={props.refresh}
          archive={props.archive}
          onArchive={props.refresh}
        />;
      }
    }
  ];

    const filteredRows = props.assignments.filter((assignment) => {
      return (
        assignment.name.toLowerCase().includes(search.toLowerCase()) ||
        formatAddress(assignment.address).toLowerCase().includes(search.toLowerCase()) ||
        (assignment.reference ?? "").toLowerCase().includes(search.toLowerCase())
      )  && (!filterMine || assignment.assignee === config.userId);
    }).sort((a, b) => ((a.plan_date ?? 0) > (b.plan_date ?? 1) ? 1 : -1))

    return <Stack direction="column" spacing={2} sx={styles.tableBox}>
        <Stack spacing={4} direction={"row"} alignItems={"center"}>
            <Typography>{language.assignments.filterMine}</Typography>
            <Switch checked={filterMine} value={filterMine} onChange={() => {setFilterMine(!filterMine)}} />
        </Stack>
        <Stack direction="row" spacing={2} sx={{width: "100%"}} justifyContent={"space-between"} alignItems={"stretch"}>
          <TextField
            value={search}
            onChange={(event) => {setSearch(event.target.value)}}
            sx={{width: "100%"}}
            placeholder={language.generic.search}
            InputProps={{
              startAdornment: <InputAdornment position="start"><SearchIcon /></InputAdornment>,
            }}
          />
          <Button 
            onClick={() => props.setArchive(!props.archive)} startIcon={<Archive />}
            variant="outlined"
            sx={styles.archiveButton}
          >
            {props.archive ? language.assignments.showActive : language.assignments.showArchive}
          </Button>
        </Stack>
        <DataGrid<Assignment>
            sx={styles.table}
            disableSelectionOnClick
            rows={props.loadingAssignments ? [] : filteredRows}
            columns={columns}
            pageSize={25}
            components={{
              NoRowsOverlay: props.loadingAssignments ? Spinner : undefined
            }}
            getRowId={(row: Assignment) => row.identifier}
            onCellDoubleClick={(params: GridCellParams<any, any, any>) => {
              if (!props.archive) {
                navigate(`/assignments/edit/${params.row.identifier}`)
              }
            }}
        />
    </Stack>
};

export default AssignmentTable;