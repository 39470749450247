import { Container, ContainerProps } from "@mui/material";

interface ProfilePageContainerProps extends ContainerProps {}


function ProfilePageContainer(props: ProfilePageContainerProps) {
    return <Container 
        sx={{
            pt: 8,
            width: "100%",
            height: "100vh",
            margin: "auto",
            alignItems: "center",
            display: "flex",
            flexDirection: "column"
        }} {...props} />
};

export default ProfilePageContainer;