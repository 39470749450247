import { CalendarMonth } from "@mui/icons-material";
import { Typography, Box} from "@mui/material";
import { useLanguage } from "inspector-common/lib/language"
import { Assignment } from "inspector-common/lib/model"
import { getDateString } from "inspector-common/lib/utils";

interface AssignmentListItemPlanDateProps {
    assignment: Assignment
};

function AssignmentListItemPlanDate(props: AssignmentListItemPlanDateProps) {
    const language = useLanguage();
    return <Box sx={{display: "flex", flexDirection: "row", marginY: 1}}>
        <CalendarMonth sx={{mr: 2}}/>
        <Typography>{language.assignments.planDate + ": " + getDateString(props.assignment.plan_date)}</Typography>
    </ Box>
};

export default AssignmentListItemPlanDate;