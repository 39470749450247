import { Container, Box, Stack } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { ChangePasswordButton, ChangePasswordTitle } from "../components/change-password";
import { useLanguage } from "inspector-common/lib/language";
import { useAuth } from "../../../auth";
import { LoginEmail, LoginPassword } from "../components";
import ErrorMessage from "../components/change-password/ErrorMessage";

interface SignInProps {};

const styles = {
    container: {
        display: "flex",
        width: "100%",
        height: "100vh",
        justifyContent: "center",
        alignItems: "center"
    },
    loginForm: {
        width: "60%",
        display: "flex",
        flexDirection: "column",
        borderRadius: "25px",
        p: 6,
        pb: 8,
        boxShadow: 3
    }
}

export default function ChangePassword(props: SignInProps) {
    const language = useLanguage();
    const [email, setEmail] = useState<string>("");
    const [oldPassword, setOldPassword] = useState<string>("");
    const [newPassword, setNewPassword] = useState<string>("");
    const [error, setError] = useState<string>("");

    const auth = useAuth();
    const navigate =  useNavigate();


    async function handleChangePassword() {
        await auth.changePassword(email, oldPassword, newPassword).then((response: any) => {
            if (response.success) {
                setError("");
                navigate("/");
            } else {
                if (response.message === "LOGIN FAIL") {
                    setError(language.users.loginFailed)
                } else if (response.message === "INVALID PASSWORD FORMAT") {
                    setError(language.users.invalidPasswordFormat)
                } else {
                    setError(response.message)
                }
            }
        })
    };

    return <Container sx={styles.container}>
        <Stack spacing={2} sx={styles.loginForm}>
            <ChangePasswordTitle />
            <LoginEmail email={email} setEmail={setEmail} />
            <LoginPassword password={oldPassword} setPassword={setOldPassword} label={language.users.oldPassword}/>
            <LoginPassword password={newPassword} setPassword={setNewPassword} label={language.users.newPassword}/>
            <ChangePasswordButton changePassword={handleChangePassword} />
            <ErrorMessage error={error}/>
        </Stack>
    </Container>
};
