var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { BorderStyle, HeadingLevel, Paragraph, SectionType, Table, TableCell, TableRow, TabStopPosition, TextRun, WidthType } from "docx";
import { createSubtitle } from "./subtitle";
import { createTabbedImages } from "./imagesTabbed";
function tableCell(s, props) {
    var margin = 50;
    var cellProps = __assign({ children: [new Paragraph(s)], margins: {
            top: margin,
            bottom: margin,
            left: margin,
            right: margin
        } }, (props !== undefined ? props : {}));
    return new TableCell(cellProps);
}
export function createBody(config) {
    return config.rooms.map(function (room, index) {
        var heading = room.level1 ? HeadingLevel.HEADING_1 : room.level2 ? HeadingLevel.HEADING_2 : HeadingLevel.HEADING_3;
        var children = [
            new Paragraph({
                heading: heading,
                children: [
                    new TextRun(room.title)
                ],
                keepNext: true
            })
        ];
        if (room.state) {
            children.push(createSubtitle("".concat(config.labels.stateLabel, ": ").concat(room.state)));
        }
        if (room.remarks.length > 0) {
            children.push(createSubtitle("".concat(config.labels.remarks)));
            room.remarks.forEach(function (remark) {
                children.push(new Paragraph({
                    text: remark,
                    spacing: {
                        before: 5,
                        after: 5
                    },
                    keepNext: true,
                    keepLines: true
                }));
            });
            children.push(new Paragraph({
                text: "",
                spacing: {
                    before: 5,
                    after: 5
                },
                keepNext: false,
                keepLines: false
            }));
        }
        if (room.attributes.length > 0) {
            children.push(createSubtitle(config.labels.evaluation));
            children.push(new Table({
                columnWidths: [1800, 1800, TabStopPosition.MAX - 3600],
                rows: room.attributes.map(function (attribute) {
                    return new TableRow({
                        children: [
                            tableCell(attribute.name),
                            tableCell(" - "),
                            tableCell(attribute.value)
                        ],
                    });
                }),
                width: {
                    size: '100%',
                    type: WidthType.PERCENTAGE,
                },
                borders: {
                    top: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                    bottom: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                    left: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                    right: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                    insideHorizontal: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                    insideVertical: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                },
            }));
            children.push(new Paragraph(""));
        }
        ;
        if (room.images.length > 0) {
            children.push(createSubtitle(config.labels.photos));
            children.push.apply(children, createTabbedImages(room.images, config.executionDate));
        }
        room.observations.forEach(function (observation) {
            children.push(createSubtitle(config.labels.evaluation));
            children.push(new Table({
                columnWidths: [1500, TabStopPosition.MAX - 1500],
                rows: __spreadArray([
                    new TableRow({
                        children: [
                            tableCell(config.labels.type),
                            tableCell(observation.type)
                        ]
                    })
                ], observation.remarks.map(function (remark, index) {
                    return new TableRow({
                        children: [
                            tableCell(index === 0 ? config.labels.remarks : ""),
                            tableCell(remark)
                        ]
                    });
                }), true),
                width: {
                    size: '100%',
                    type: WidthType.PERCENTAGE,
                },
                margins: {
                    top: 20,
                    bottom: 20,
                    left: 20,
                    right: 20
                },
                borders: {
                    top: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                    bottom: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                    left: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                    right: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                    insideHorizontal: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                    insideVertical: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                },
            }));
            if (observation.images.length > 0) {
                children.push(new Paragraph(""));
                children.push(createSubtitle(config.labels.photos));
                children.push.apply(children, createTabbedImages(observation.images, config.executionDate));
            }
        });
        var sectionType = SectionType.CONTINUOUS;
        var previousRoom = config.rooms[index - 1];
        if (room.level1) {
            sectionType = SectionType.NEXT_PAGE;
        }
        else if (room.level2 && (previousRoom.observations.length > 0 || previousRoom.images.length > 0)) {
            sectionType = SectionType.NEXT_PAGE;
        }
        return {
            properties: {
                type: sectionType
            },
            children: children
        };
    });
}
