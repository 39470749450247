import { List, BoxProps } from "@mui/material";
import { CreateTemplateRequest, CreateTemplateResponse } from "inspector-common/lib/api/calls";
import { TemplateHeader } from "inspector-common/lib/model";
import { CreateTemplate } from "../../create";
import TemplateListItem from "./TemplateListItem";

interface TemplateListProps {
    templates: Array<TemplateHeader>
    selectedTemplate: TemplateHeader | null
    setSelectedTemplate: (value: TemplateHeader | null) => void
    createTemplate: (request: CreateTemplateRequest) => Promise<CreateTemplateResponse | null>
};

const sx: BoxProps = {
    display: "block"
}

function TemplateList(props: TemplateListProps) {
    return <List sx={sx}>
        <CreateTemplate create={props.createTemplate} templates={props.templates || []}/>
        {
            props.templates.map((template: TemplateHeader) => {
                return <TemplateListItem 
                    key={template.identifier} 
                    template={template}
                    selectedTemplate={props.selectedTemplate}
                    setSelectedTemplate={props.setSelectedTemplate}
                />
            })
        }
    </List>
}

export default TemplateList;