import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Button, Collapse, IconButton, Stack, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Assignment, Company, Inspection } from "inspector-common/lib/model";
import { useEffect, useState } from "react";
import TermsEditor from "../../terms/components/TermsEditor";
import GenerateTermsDialog from "./GenerateTermsDialog";

interface InspectionTermsProps {
    inspection: Inspection
    setInspection: (inspection: Inspection) => void
    assignment: Assignment
    company: Company
    executor: string
};

const styles = {
    titleStack: {
        my: 2
    },
    button: {
        mb: 2
    }
}

export default function InspectionTerms(props: InspectionTermsProps) {
    const language = useLanguage();
    const [open, setOpen] = useState(false);
    const [generateOpen, setGenerateOpen] = useState(false);

    const [localTerms, setLocalTerms] = useState(props.inspection.terms ?? {
        general: [],
        clauses: [],
        remarks: [],
        clause_cost_party: "",
        clause_cost_split: "",
        signature_text_header: "",
        signature_text_items: []
    })

    useEffect(() => {
        const timeout = setTimeout(() => props.setInspection({...props.inspection, terms: localTerms}), 1000)
        return () => clearTimeout(timeout)
    }, [localTerms])

    useEffect(() => {
        if (JSON.stringify(props.inspection.terms) !== JSON.stringify(localTerms)) {
            setLocalTerms(props.inspection.terms)
        }
    }, [JSON.stringify(props.inspection.terms)])

    return  <Stack direction="column">
        <Stack direction="row" spacing={1} sx={styles.titleStack}>
            <IconButton onClick={() => setOpen(!open)} >
                {
                    open ? <ExpandMore sx={{color: "black"}}/> : <ExpandLess sx={{color: "black"}}/>
                }
            </IconButton>
            <Typography variant="h5" >{language.document.terms.general}</Typography>
        </Stack>

        <Collapse in={open}>
            <Button onClick={() => setGenerateOpen(true)} variant="outlined" sx={styles.button}>
                {language.document.terms.regenerate}
            </Button>
            <GenerateTermsDialog
                open={generateOpen}
                setOpen={setGenerateOpen}
                inspection={props.inspection}
                setInspection={props.setInspection}

            />
            <TermsEditor 
                terms={localTerms}
                setTerms={setLocalTerms} 
                assignment={props.assignment}
                company={props.company}
                executor={props.executor}
                inspection={props.inspection}

            />
        </Collapse>
    
    
    </Stack>
}