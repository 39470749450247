import { Container, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import CompanyTable from "../components/CompanyTable";

interface ManageApplicationProps {

};

export default function ManageApplication(props: ManageApplicationProps) {
    const language = useLanguage();

    return <Container>
        <Typography variant="h1">{language.generic.manageApp}</Typography>
        <CompanyTable />
    </Container>
}