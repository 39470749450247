var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import { useGetDocumentStatus } from "./useGetDocumentStatus";
import { useLockDocument } from "./useLock";
import { getPostProcessingSignatures } from "../../../report/renderConfig";
import { useGetDocumentDownloadDetails } from "./useGetDocumentDownloadDetails";
import { JobStatusEnum } from "../../calls/documents/getDocumentStatus";
export function useConvertToPDF() {
    var lockDocument = useLockDocument().execute;
    var getDocumentStatus = useGetDocumentStatus().execute;
    var getDocumentDownloadDetails = useGetDocumentDownloadDetails().execute;
    function convert(inspection, signaturePositions) {
        return __awaiter(this, void 0, void 0, function () {
            var _this = this;
            return __generator(this, function (_a) {
                return [2 /*return*/, lockDocument({
                        assignment_id: inspection.assignment_id,
                        inspection_id: inspection.identifier,
                        signature_positions: signaturePositions ? signaturePositions : getPostProcessingSignatures({
                            extraSignatures: []
                        }, inspection),
                    }).then(function () { return __awaiter(_this, void 0, void 0, function () {
                        var done, response;
                        var _a, _b, _c;
                        return __generator(this, function (_d) {
                            switch (_d.label) {
                                case 0:
                                    done = false;
                                    _d.label = 1;
                                case 1:
                                    if (!!done) return [3 /*break*/, 7];
                                    return [4 /*yield*/, getDocumentStatus({
                                            assignment_id: inspection.assignment_id,
                                            inspection_id: inspection.identifier
                                        })];
                                case 2:
                                    response = _d.sent();
                                    if (!(((_a = response === null || response === void 0 ? void 0 : response.job_status) === null || _a === void 0 ? void 0 : _a.status) === JobStatusEnum.SUCCESS)) return [3 /*break*/, 3];
                                    done = true;
                                    return [3 /*break*/, 6];
                                case 3:
                                    if (!(((_b = response === null || response === void 0 ? void 0 : response.job_status) === null || _b === void 0 ? void 0 : _b.status) === JobStatusEnum.RUNNING)) return [3 /*break*/, 5];
                                    return [4 /*yield*/, new Promise(function (f) { return setTimeout(f, 5000); })];
                                case 4:
                                    _d.sent();
                                    return [3 /*break*/, 6];
                                case 5:
                                    if (((_c = response === null || response === void 0 ? void 0 : response.job_status) === null || _c === void 0 ? void 0 : _c.status) === JobStatusEnum.ERROR) {
                                        throw new Error("PDF Conversion failed");
                                    }
                                    _d.label = 6;
                                case 6: return [3 /*break*/, 1];
                                case 7: return [2 /*return*/, getDocumentDownloadDetails({
                                        assignment_id: inspection.assignment_id,
                                        inspection_id: inspection.identifier
                                    }).then(function (response) {
                                        if (response === null || response === void 0 ? void 0 : response.pdf_url) {
                                            return (response === null || response === void 0 ? void 0 : response.pdf_url);
                                        }
                                    })];
                            }
                        });
                    }); })];
            });
        });
    }
    return { convert: convert };
}
