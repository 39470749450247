import { Button, Tooltip } from "@mui/material";
import { DeleteDialog } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";
import DeleteIcon from '@mui/icons-material/Delete';
import { useState } from "react";
import themeColors from "../../../../theme/themeColors";

interface DeleteUserIconButtonProps {
    isLoading: boolean
    onDelete: () => void
};

function DeleteUserIconButton(props: DeleteUserIconButtonProps) {
    const tooltip = useLanguage().generic.delete
    const [openDeleteConfirmation, setOpenDeleteConfirmation] = useState(false);

    return <>
        <Tooltip title={tooltip}>
            <Button onClick={(event: any) => {event.stopPropagation(); setOpenDeleteConfirmation(true);}} 
                variant="outlined"
                sx={{ padding: 1, minWidth: 0, borderRadius: "24px", marginLeft: 1}}
                color="error"
                disabled={props.isLoading}
            >
                <DeleteIcon fontSize="small" color={"primary"} />
            </Button> 
        </Tooltip>
        <DeleteDialog
            open={openDeleteConfirmation}
            setOpen={setOpenDeleteConfirmation}
            delete={props.onDelete}
        />
    </>
};

export default DeleteUserIconButton;