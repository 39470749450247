import { useState } from "react";
import { RefreshButton, Spinner } from "../../../components";
import { useUserListState } from "../../../state/users";
import { UserPageContainer, UserTable, UserTitle } from "../components";
import { CreateUserButton, CreateUserForm } from "../components/create";
import { Stack } from "@mui/material";
import { useIsAdmin } from "../../../auth/auth";

interface UserPageProps {};

function UserPage(props: UserPageProps) {
    const isAdmin = useIsAdmin();
    const {isLoading, users, refresh } = useUserListState();
    const [createOpen, setCreateOpen] = useState<boolean>(false);

    return <UserPageContainer>
        <UserTitle />
        <Stack direction="row" spacing={2} pt={5} pb={2}>
        {
            isAdmin ? <CreateUserButton openDialog={() => setCreateOpen(true)} /> : null
        }
        <RefreshButton onRefresh={refresh} loading={isLoading} />
        </Stack>
        <CreateUserForm open={createOpen} setOpen={setCreateOpen} refresh={refresh} />
        {
            isLoading ? <Spinner/> : <>
                <UserTable users={users || []} refresh={refresh}/>        
            </>
        }
    </UserPageContainer>
};

export default UserPage;