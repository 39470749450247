import { Box, Button } from "@mui/material";
import { useAuth } from "../../../../auth";
import { useLanguage } from "inspector-common/lib/language";

interface LogoutButtonProps {}

function LogoutButton(props: LogoutButtonProps) {
    const label = useLanguage().users.logout;
    const auth = useAuth();

    return <Box m={3} sx={{textAlign: "center"}}><Button onClick={() => {auth.signOut()}} variant="contained">{label}</Button></Box>
};

export default LogoutButton;