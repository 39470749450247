import { Box, Button, Stack, TextField, Typography } from "@mui/material";
import InspectionPageContainer from "../../assignments/components/container/InspectionPageContainer";
import { useEffect, useState } from "react";
import { DocumentLanguage, Inspection, newInspectionNode, Template, TemplateHeader } from "inspector-common/lib/model";
import { InspectionType } from "inspector-common/lib/model/inspection/InspectionType";
import { useLanguage } from "inspector-common/lib/language";
import InspectionTypeSelect from "../components/InspectionTypeSelect";
import { Navigate, useNavigate, useParams } from "react-router-dom";
import { v4 as uuidv4 } from "uuid";
import InspectionCostingSystem from "inspector-common/lib/model/inspection/InspectionCostingSystem";
import { useUpdateInspection } from "inspector-common/lib/api/hooks";
import DocumentLanguageSelect from "../components/DocumentLanguageSelect";
import TemplateSelect from "../../assignments/components/inputs/InspectionsInput/TemplateSelect";
import InspectionPartiesInput from "../components/InspectionPartiesInput";
import { InspectionParty } from "inspector-common/lib/model/inspection/InspectionParty";
import ConfirmInspectionCreationDialog from "../components/ConfirmInspectionCreationDialog";
import { AddressInput, PlanDateInput } from "../../assignments/components";
import { useTemplates } from "../../templates/functions";
import { useAssignment } from "../../../state";
import { Address } from "inspector-common/lib/model/common/Address";

interface NewInspectionPageProps {};

const defaultAddress = {
    city: "",
    zip_code: "",
    street: "",
    number: "",
    bus: ""
}

export default function NewInspectionPage(props: NewInspectionPageProps) {
    const language = useLanguage();
    const { assignmentId } = useParams();
    const assignment = useAssignment(assignmentId);
    const navigate = useNavigate();
    const { execute: createInspection, isLoading: isCreating } = useUpdateInspection();
    
    const [name, setName] = useState<string>("");
    const [address, setAddress] = useState<Address>(defaultAddress);
    const [documentLanguage, setDocumentLanguage] = useState<DocumentLanguage>(DocumentLanguage.NL);
    const [inspectionType, setInspectionType] = useState<InspectionType | string>(InspectionType.AFTER_RENT);
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [template, setTemplate] = useState<Template | null>(null);
    const { templates } = useTemplates();
    console.log(template)

    const [confirmOpen, setConfirmOpen] = useState<boolean>(false);

    useEffect(() => {
        if (assignment?.address) {
            setAddress(assignment.address)
        }
    }, [assignment?.address])

    const defaultParty = {
        name: "",
        role: "",
        address: defaultAddress,
        representative: "",
        info: ""
    };

    const [party1, setParty1] = useState<InspectionParty>(defaultParty)
    const [party2, setParty2] = useState<InspectionParty>(defaultParty)
    const [extraParties, setExtraParties] = useState<InspectionParty[]>([]);

    if (!assignmentId) {
        return <Navigate to="/" />
    };

    const newInspection: Inspection = {
        assignment_id: assignmentId,
        address: address,
        identifier: uuidv4(),
        inspection_type: inspectionType,
        language: documentLanguage, 
        name: name,
        root_node: template?.root ?? {...newInspectionNode("", []), is_root: true},
        costing_system: InspectionCostingSystem.SPLIT,
        object_type: template?.name ?? "",
        start_date: startDate,
        terms: {} as any,
        remarks: [],
        party1: party1,
        party2: party2,
        extra_parties: extraParties,
        signature_executor: null,
        signature_party_1: null,
        signature_party_2: null,
        extra_signatures: []
    };


    return <InspectionPageContainer>
        <Stack spacing={2} direction="column">
            <Typography variant="h5">{language.assignments.addDocument}</Typography>
            <TextField 
                value={name}
                onChange={(event) => {setName(event.target.value)}}
                placeholder={language.generic.name}
                label = {language.generic.name}
            />
            <AddressInput address={address} setAddress={setAddress} />
            <PlanDateInput planDate={startDate} setPlanDate={setStartDate} />
            <InspectionTypeSelect inspection={newInspection} setInspection={(inspection: Inspection) => {
                setInspectionType(inspection.inspection_type)
            } } />
            <DocumentLanguageSelect language={documentLanguage} setLanguage={setDocumentLanguage} />
            <TemplateSelect templates={templates} template={template} setTemplate={setTemplate} />
            <InspectionPartiesInput 
                extraParties={extraParties}
                setExtraParties={setExtraParties}
                party1={party1}
                party2={party2}
                setParty1={setParty1}
                setParty2={setParty2}
            />
            <Box>
                <Button
                    variant="contained"
                    onClick={() => setConfirmOpen(true)}
                    disabled={isCreating}
                >
                    {language.generic.confirm}
                </Button>
            </Box>
        </Stack>
        <ConfirmInspectionCreationDialog
            open={confirmOpen}
            onClose={() => setConfirmOpen(false)}
            confirm={() => createInspection({
                assignment_id: assignmentId,
                inspection: newInspection
            }).then(() => navigate(
                "/assignments/edit/" + assignmentId + "/inspections/" + newInspection.identifier
            ))} />
    </InspectionPageContainer>
}