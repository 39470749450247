import { TextField, TextFieldContainer } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";


interface LoginEmailProps {
    email: string
    setEmail: (value: string) => void
};


function LoginEmail(props: LoginEmailProps) {
    const language = useLanguage();
    const label = language.users.email;

    return <TextFieldContainer>
        <TextField 
            label={label}
            value={props.email}
            onChange={(event: any) => props.setEmail(event.target.value)}
        />
    </TextFieldContainer>
};

export default LoginEmail;