import { Typography } from "@mui/material";

interface TemplateRoomEditorTitleProps {
    label: string
};

function TemplateRoomEditorTitle(props: TemplateRoomEditorTitleProps) {    
    return <Typography 
        variant="h6"
        sx={{textAlign: "center", marginBottom: 2, width: "100%"}}
    >{props.label}</Typography>
};

export default TemplateRoomEditorTitle;