import { Typography } from "@mui/material";
import Transaction from "inspector-common/lib/model/credits/Transaction";
import TransactionType from "inspector-common/lib/model/credits/TransactionType";

interface TransactionPriceProps {
    transaction: Transaction
};

export default function TransactionPrice(props: TransactionPriceProps) {
    return props.transaction.transaction_type !== TransactionType.USE ? 
    <Typography fontWeight="bold">€{props.transaction.price.toFixed(2)}</Typography> : <Typography color="#222222" fontWeight="bold">--</Typography>
}