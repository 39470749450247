var labels = {
    NL: {
        tableOfContents: "Inhoudstafel",
        general: "Algemene informatie",
        photos: "Foto's",
        locationDescription: "Plaatsbeschrijving",
        reference: "Referentie",
        preparedBy: "Voorbereid door",
        executedBy: "Uitgevoerd door",
        executedOn: "Uitgevoerd op",
        intro: "Inleiding",
        stateLabel: "Algemene staat",
        evaluation: "Vaststelling",
        clauses: "Clausules",
        remarks: "Opmerkingen",
        signaturesHeader: "Overeenkomst en handtekeningen",
        signatures: "Handtekeningen",
        signature: "Handtekening",
        representative: "Vertegenwoordigd door",
        page: "Pagina",
        of: "van",
        executor: "Uitvoerder",
        state: {
            NEW: "Nieuw",
            VERY_BAD: "Zeer slecht",
            BAD: "Slecht",
            DECENT: "Degelijk",
            GOOD: "Goed",
            VERY_GOOD: "Zeer goed"
        },
        type: "Type",
        goal: "Doel"
    },
    FR: {
        tableOfContents: "Table des matières",
        general: "Information général",
        photos: "Photos",
        locationDescription: "Description de lieux locative",
        reference: "Référence",
        preparedBy: "Préparé par",
        executedBy: "Exécuté par",
        executedOn: "Exécuté",
        intro: "Introduction",
        stateLabel: "Conditions générales",
        evaluation: "Evaluation",
        clauses: "Clauses",
        remarks: "Commentaires",
        signaturesHeader: "Accords et signatures",
        signatures: "Signatures",
        signature: "Signature",
        representative: "Représenté par",
        page: "Page",
        of: "de",
        executor: "Exécuteur",
        state: {
            NEW: "Nouveau",
            VERY_BAD: "Très mauvais",
            BAD: "Mauvais",
            DECENT: "Médiocre",
            GOOD: "Bon",
            VERY_GOOD: "Très bon"
        },
        type: "Genre",
        goal: "Objectif"
    },
    EN: {
        tableOfContents: "Table of Contents",
        general: "General information",
        photos: "Photos",
        locationDescription: "Locative description",
        reference: "Reference",
        preparedBy: "Prepared by",
        executedBy: "Executed by",
        executedOn: "Executed on",
        intro: "Introduction",
        stateLabel: "General state",
        evaluation: "Evaluation",
        clauses: "Clauses",
        remarks: "Remarks",
        signaturesHeader: "Agreement and signatures",
        signatures: "Signatures",
        signature: "Signature",
        representative: "Represented by",
        page: "Page",
        of: "of",
        executor: "Executor",
        state: {
            NEW: "New",
            VERY_BAD: "Very bad",
            BAD: "Bad",
            DECENT: "Decent",
            GOOD: "Good",
            VERY_GOOD: "Very good"
        },
        type: "Type",
        goal: "Goal"
    }
};
export default labels;
