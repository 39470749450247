import './App.css';
import ThemeProvider from '../theme/provider';
import Router from '../routes';
import { RecoilRoot } from "recoil";
import { ProvideAuth } from '../auth';
import { DndProvider } from "react-dnd";
import { HTML5Backend } from "react-dnd-html5-backend";

function App() {
  return (
    <ProvideAuth>
      <RecoilRoot>
        <ThemeProvider>
            <DndProvider backend={HTML5Backend}>
              <Router />
            </DndProvider>
        </ThemeProvider>
      </RecoilRoot>
    </ProvideAuth>
  );
}

export default App;