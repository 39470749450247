import { IconButton, Stack } from "@mui/material";
import { TextField, TextFieldContainer } from "../../../../../components";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode, InspectionNodeAttribute } from "inspector-common/lib/model";
import { Add, Delete } from "@mui/icons-material";

interface TemplateRoomAttributeProps {
    node: InspectionNode
    updateNode: (node: InspectionNode) => void
};

const styles = {
    textField: {
        marginTop: 1
    }
}
function TemplateRoomAttribute(props: TemplateRoomAttributeProps) {
    const language = useLanguage();

    return <TextFieldContainer>
        {
            props.node.attributes.map((attribute: InspectionNodeAttribute, index: number) => {
                return <Stack direction="row" spacing={1}>
                    <TextField
                        sx={styles.textField}
                        label={attribute.name}
                        value={attribute.name}
                        onChange={(event: any) => props.updateNode({
                            ...props.node,
                            attributes: [
                                ...props.node.attributes.slice(0, index),
                                { ...attribute, name: event.target.value },
                                ...props.node.attributes.slice(index + 1)
                            ]
                        })}
                    />
                    <IconButton onClick={() => props.updateNode({
                                    ...props.node,
                                    attributes: [
                                        ...props.node.attributes.slice(0, index),
                                        ...props.node.attributes.slice(index + 1)
                                    ]
                    })}>
                        <Delete />
                    </IconButton>
                </Stack>
            })
        }
        <IconButton onClick={() => props.updateNode({
            ...props.node,
            attributes: [
                ...props.node.attributes,
                { name: "", value: "" },
            ]
        })}>
            <Add />
        </IconButton>
    </TextFieldContainer>
};

export default TemplateRoomAttribute;