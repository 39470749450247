import "./CreateTemplate.css";
import { useState } from "react";
import { CreateTemplateButton } from "../buttons";
import CreateTemplateNodeDialog from "./CreateTemplateNodeDialog";
import { InspectionNode } from "inspector-common/lib/model";

interface CreateTemplateNodeProps {
    create: (node: InspectionNode) => void
    parent: InspectionNode
};

function CreateTemplateNode(props: CreateTemplateNodeProps) {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    return <>
        <CreateTemplateButton onClick={() => setDialogOpen(true)}/>
        <CreateTemplateNodeDialog
            open={dialogOpen}
            setOpen={setDialogOpen}
            create={props.create}
            parent={props.parent}
        />
    </>
}

export default CreateTemplateNode;