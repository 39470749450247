import React, { useState } from "react";
import {
  Container,
  Box,
  TextField,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useAuth } from "../../../../auth";
import { useLanguage } from "inspector-common/lib/language";
import ErrorMessage from "../change-password/ErrorMessage";
import PasswordInput from "../common/PasswordInput";

const styles = {
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  loginForm: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "25px",
    p: 6,
    pb: 8,
    boxShadow: 3,
  },
  succeedModal: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: 400,
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 4,
    borderRadius: 2, // optional, for rounded corners
  },
};

interface ResetPasswordComponentProps {
  email: string;
}

export default function ResetPasswordComponent(
  props: ResetPasswordComponentProps
) {
  const [code, setCode] = useState("");
  const [email, setEMail] = useState(props.email);
  const [newPassword, setNewPassword] = useState("");
  const [error, setError] = useState("");
  const [isResetSuccessful, setIsResetSuccessful] = useState(false);
  const auth = useAuth();
  const language = useLanguage();
  const navigate = useNavigate();

  const handleSuccess = () => {
    navigate("/signin", { state: { email } });
  };

  const handlePasswordConfirmation = async () => {
    const response = await auth.resetPasswordConfirmation(
      email,
      code,
      newPassword
    );
    if (response.success) {
      setIsResetSuccessful(true); // Set success status
    } else {
      setError(response.message);
    }
  };

  return (
    <Container sx={styles.container}  maxWidth="md">
      <Box sx={styles.loginForm}>
        <h2>{language.users.forgotPassword}</h2>
        <TextField
          label={language.users.email}
          value={email}
          fullWidth
          margin="normal"
          disabled={true}
        />
        <TextField
          label={language.users.verificationCode}
          value={code}
          onChange={(e) => setCode(e.target.value)}
          fullWidth
          margin="normal"
        />
        <PasswordInput
          password={newPassword}
          setPassword={setNewPassword}
          label={language.users.newPassword}
        />
        <Button
          onClick={handlePasswordConfirmation}
          variant="contained"
          color="primary"
        >
          {language.users.askReset}
        </Button>
        <ErrorMessage error={error} />
      </Box>

      <Modal
        open={isResetSuccessful}
        onClose={handleSuccess}
        aria-labelledby="success-modal-title"
        aria-describedby="success-modal-description"
      >
        <Box sx={styles.succeedModal}>
          <Typography id="success-modal-title" variant="h6" component="h2">
            {language.users.resetSuccess}
          </Typography>
          <Typography id="success-modal-description" sx={{ mt: 2 }}>
            {language.users.resetSuccessDescription}
          </Typography>
          <Button
            onClick={handleSuccess}
            variant="contained"
            color="primary"
            sx={{ mt: 2 }}
          >
            {language.users.login}
          </Button>
        </Box>
      </Modal>
    </Container>
  );
}
