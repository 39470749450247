import { TextField, TextFieldContainer } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";

interface AssignmentNameInputProps {
    name: string
    setName: (value: string) => void
};

function AssignmentNameInput(props: AssignmentNameInputProps) {
    const label = useLanguage().assignments.attributes.name;
    return <TextFieldContainer>
        <TextField 
            label={label} 
            value={props.name} 
            onChange={(event: any) => props.setName(event.target.value)}
        />
    </TextFieldContainer>
};

export default AssignmentNameInput;