import { Stack, Typography } from "@mui/material";
import { useState } from "react";
import { Navigate, NavigateFunction, useNavigate } from "react-router-dom";
import { useAuth } from "../../../auth";
import { Logo } from "../../../components";
import { useLanguage } from "inspector-common/lib/language";
import Language from "inspector-common/lib/language/language";
import { LoginEmail, LoginPassword, LoginButton } from "../components";
import { SignInContainer, SignInPageContainer } from "../components/container";
import ForgotPasswordButton from "../components/forgot-password/ForgotPasswordButton";
import RegisterButton from "../components/register/RegisterButton";

interface SignInProps {};


function SignIn(props: SignInProps) {
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [error, setError] = useState<string>("");
    const language = useLanguage();

    const auth = useAuth();
    const navigate = useNavigate();

    const { config } = useAuth();
    if (config.isAuthenticated) {
        return <Navigate to="/" />
    }

    return <SignInPageContainer>
        <SignInContainer>
            <Logo height={160} width={200} text={true} fat/>
            <LoginEmail email={email} setEmail={setEmail} />
            <LoginPassword password={password} setPassword={setPassword} label={language.users.password}/>
            <LoginButton onClick={async () => handleSignIn(email, password, setError, auth, navigate, language)}/>
            <Stack direction="row" justifyContent={"space-between"}>
                <ForgotPasswordButton />
                <RegisterButton />
            </Stack>
            <Typography sx={{color: "red"}}>{error}</Typography>
        </SignInContainer>
    </SignInPageContainer>
};

async function handleSignIn(
    email: string, 
    password: string, 
    setError: (err: string) => void,
    auth: any,
    navigate: NavigateFunction,
    language: Language
) {
    await auth.signIn(email, password).then((response: any) => {
        if (response.success) {
            setError("")
            navigate("/")
        } else {
            if (response.message === "LOGIN FAIL") {
                setError(language.users.loginFailed)
            } else if (response.message === "CHANGE PASSWORD") {
                navigate("/change-password")
            }
        }
    }).catch(() => {
        console.log("catch")
        setError(language.users.loginFailed)
    })
}
export default SignIn;