const localAwsConfigAuth = {
    region: "eu-west-1",
    userPoolId: "eu-west-1_t6asjlZXC",
    userPoolWebClientId: "6fqs71lju1u3c9hg6ibprmrvgq",
    cookieStorage: {
        domain: "localhost",
        path: "/",
        expires: 365,
        sameSite: "none",
        secure: true,
    },
    authenticationFlowType: "USER_SRP_AUTH",
};

const DevAwsConfigAuth = {
    region: "eu-west-1",
    userPoolId: "eu-west-1_t6asjlZXC",
    userPoolWebClientId: "6fqs71lju1u3c9hg6ibprmrvgq",
    cookieStorage: {
        domain: window.location.hostname,
        path: "/",
        expires: 365,
        sameSite: "strict",
        secure: true,
    },
    authenticationFlowType: "USER_SRP_AUTH",
};

const ProdAwsConfigAuth = {
    region: "eu-west-1",
    userPoolId: "eu-west-1_fcFhEULEn",
    userPoolWebClientId: "3i39hcafsslst5rkl3fj6teif2",
    cookieStorage: {
        domain: window.location.hostname,
        path: "/",
        expires: 365,
        sameSite: "strict",
        secure: true,
    },
    authenticationFlowType: "USER_SRP_AUTH",
};

function getAWSConfigAuth() {
    switch (window.location.origin) {
        case "http://localhost:3000":
            return localAwsConfigAuth;
        case "http://localhost:3001":
            return DevAwsConfigAuth;
        case "http://localhost:3002":
            return ProdAwsConfigAuth;
        case "http://loci-frontend-bucket-dev.s3-website-eu-west-1.amazonaws.com":
            return DevAwsConfigAuth;
        case "http://loci-frontend-bucket-prod.s3-website-eu-west-1.amazonaws.com":
            return ProdAwsConfigAuth;
        case "https://dev.loci.expert":
            return DevAwsConfigAuth;
        case "https://loci.expert":
            return ProdAwsConfigAuth;
        default:
            return localAwsConfigAuth;
    }
}

const AwsConfigAuth = getAWSConfigAuth();
console.log(AwsConfigAuth);
export { AwsConfigAuth };