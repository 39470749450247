export function createStyleSheet() {
    return {
        default: {
            listParagraph: {
                paragraph: {
                    spacing: {
                        before: 240,
                        after: 240
                    }
                }
            },
            heading1: {
                run: {
                    size: 32,
                    bold: true,
                    color: "000000",
                    font: "Arial"
                },
            },
            heading2: {
                run: {
                    size: 28,
                    bold: true,
                    color: "000000",
                    font: "Arial"
                }
            },
            heading3: {
                run: {
                    size: 24,
                    bold: true,
                    color: "000000",
                    font: "Arial"
                }
            },
            document: {
                run: {
                    font: "Arial"
                }
            }
        }
    };
}
