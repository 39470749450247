import { Title } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";

interface TemplateOverviewTitleProps {};

function TemplateOverviewTitle(props: TemplateOverviewTitleProps) {
    const language = useLanguage();
    
    return <Title label={language.templates.title} />
};

export default TemplateOverviewTitle;