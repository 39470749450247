import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material"
import { useSwitchCompany, useUpdateUser } from "inspector-common/lib/api/hooks"
import { useLanguage } from "inspector-common/lib/language"
import { Company } from "inspector-common/lib/model"
import { useAuth } from "../../../auth"

interface SignInToCompanyDialogProps {
    company?: Company
    open: boolean
    close: () => void
};

export default function SignInToCompanyDialog(props: SignInToCompanyDialogProps) {
    const language = useLanguage();
    const auth = useAuth();
    const { execute, isLoading } = useSwitchCompany();

    async function handleSignin(): Promise<void> {
        if (props.company) {
            return execute({
                company_id: props.company.identifier,
                user_id: auth.config.userId
            }).then((response) => {
                if (response?.user) {
                    auth.signOut();
                }
            })

        }
    }
    return <Dialog open={props.open} onClose={() => props.close()}>
        <DialogTitle>
            {props.company?.name}
        </DialogTitle>
        <DialogContent>
            {language.generic.loginToCompanyInfo}
        </DialogContent>
        <DialogActions>
            <Button variant="contained" onClick={handleSignin} disabled={isLoading}>
                {language.generic.confirm}
            </Button>
            <Button variant="outlined" onClick={() => props.close()}>
                {language.generic.cancel}
            </Button>
        </DialogActions>
    </Dialog>
}