import "./CreateButton.css";
import { ButtonProps, Button } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { useNavigate } from "react-router-dom";

interface NewAssignmentButtonProps extends ButtonProps {};

function NewAssignmentButton(props: NewAssignmentButtonProps) {
    const label = useLanguage().assignments.new;
    const navigate = useNavigate();

    return <Button className="new-assignment-button" variant="contained" onClick={() => navigate("/assignments/create")} {...props} >{label}</Button>;
};

export default NewAssignmentButton;