const themeColors = {
    primary: {
      main: '#D43640',
      light: '#dc5e66',
      dark: '#94252c',
      contrastText: '#EEEEEE'
    },
    secondary: {
      main: '#5A7178',
      light: '#3e4f54',
      dark: '#7b8d93',
      contrastText: '#000000'
    },
    success: {
      main: '#14B8A6',
      light: '#43C6B7',
      dark: '#0E8074',
      contrastText: '#FFFFFF'
    },
    info: {
      main: '#2196F3',
      light: '#64B6F7',
      dark: '#0B79D0',
      contrastText: '#FFFFFF'
    },
    warning: {
      main: '#FFB020',
      light: '#FFBF4C',
      dark: '#B27B16',
      contrastText: '#FFFFFF'
    },
    error: {
      main: '#D14343',
      light: '#DA6868',
      dark: '#922E2E',
      contrastText: '#FFFFFF'
    },
    text: {
      primary: '#121828',
      secondary: '#65748B',
      disabled: 'rgba(55, 65, 81, 0.48)'
    }
}

export default themeColors;