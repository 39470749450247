import { Box } from "@mui/material";
import { Title } from "../../../../../components"
import { Assignment } from "inspector-common/lib/model"

interface AssignmentListItemTitleProps {
    assignment: Assignment
};

function AssignmentListItemTitle(props: AssignmentListItemTitleProps) {
    return <Box sx={{mb: 2}}><Title variant="h5" label={props.assignment.name} /></Box>
};

export default AssignmentListItemTitle;