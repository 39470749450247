import "./Dashboard.css"
import React, { useState } from "react";
import { DashboardPanel } from "../types";
import DashboardDrawer from "./DashboardDrawer";
import DashboardPanelContainer from "./DashboardPanelContainer";
import { Box, Divider } from "@mui/material";
import Header from "./Header";

interface DashboardProps {
    items: Array<DashboardPanel>
    children: DashboardPanel
};

function Dashboard(props: DashboardProps) {
    const anchor = "left";
    const [open] = useState(true);
    const [drawerWidth, setDrawerWidth] = useState(0);

    return <React.Fragment key={anchor}>

        <Box className="dashboard">
            <DashboardDrawer items={props.items} open={open} setWidth={setDrawerWidth}/>
            <Divider orientation="vertical" flexItem />
            <DashboardPanelContainer drawerWidth={drawerWidth}>
                {props.children.component}
            </ DashboardPanelContainer>
        </Box>
  </React.Fragment>
};

export default Dashboard;