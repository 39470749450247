import { Button, Link } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";

interface RegisterButtonProps {}

export default function RegisterButton(props: RegisterButtonProps) {
    const language = useLanguage();

    return <Link href={`${window.location.origin.replace("loci", "portal.loci")}/#/pricing`}><Button variant="text">
        {language.portal.signup}
    </Button></Link>
}