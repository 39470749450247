import { TextField, TextFieldContainer, Title } from "../../../../../components"
import { useLanguage } from "inspector-common/lib/language"
import { InspectionNode } from "inspector-common/lib/model"

interface TemplateRoomInfoTitleProps {
    node: InspectionNode
    updateNode: (node: InspectionNode) => void
    name: string
};

function TemplateRoomInfoTitle(props: TemplateRoomInfoTitleProps) {
    const value = props.name
    const update = (value: string) => {
        props.updateNode({...props.node, name: value});
    }
    return <TextFieldContainer>
        <TextField 
            label={props.name} 
            value={value} 
            onChange={(event: any) => update(event.target.value)}
        />
    </ TextFieldContainer>
};

export default TemplateRoomInfoTitle;