;
function newInspectionNode(name, children, attributes, image_references, observations, remarks) {
    return {
        name: name,
        children: children,
        attributes: attributes !== null && attributes !== void 0 ? attributes : [],
        image_references: image_references !== null && image_references !== void 0 ? image_references : [],
        observations: observations !== null && observations !== void 0 ? observations : [],
        remarks: remarks !== null && remarks !== void 0 ? remarks : []
    };
}
;
function RootNode(children) {
    return newInspectionNode("root", children);
}
;
export { RootNode, newInspectionNode };
