import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode, newInspectionNode } from "inspector-common/lib/model";
import CopyTemplateNodeSelect from "./CopyTemplateNodeSelect";
import NameTextField from "./NameTextField";

interface CreateTemplateDialogProps {
    open: boolean
    parent: InspectionNode
    setOpen: (value: boolean) => void
    create: (node: InspectionNode) => void
}

function CreateTemplateDialog(props: CreateTemplateDialogProps) {
    const language = useLanguage();
    const handleClose = () => props.setOpen(false);

    const [name, setName] = useState<string>("");
    const [copyNode, setCopyNode] = useState<number | null>(null);

    return <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        onClick={(event) => {event.stopPropagation()}}
    >
        <Box className="add-template-dialog">
            <DialogTitle id="alert-dialog-title">
                {language.templates.add}
            </DialogTitle>
            <DialogContent>
                <NameTextField name={name} setName={setName} />
                <CopyTemplateNodeSelect parent={props.parent} selectedCopyNodeIndex={copyNode} setSelectedCopyNodeIndex={setCopyNode} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    if (copyNode === null) {
                        props.create(newInspectionNode(name, [])); 
                    } else {
                        props.create({...props.parent.children[copyNode], name} ?? newInspectionNode(name, []))
                    }
                    handleClose();
                }} variant="contained">{language.generic.confirm}</Button>
                <Button onClick={handleClose}>{language.generic.cancel}</Button>
            </DialogActions>
        </Box>
    </Dialog>
};

export default CreateTemplateDialog;