import { Role } from "../../model";
export var aron = {
    role: Role.ADMIN,
    first_name: "Aron",
    last_name: "Geerts",
    email: "arongeerts8@gmail.com",
    identifier: "U1"
};
export var john = {
    role: Role.USER,
    first_name: "John",
    last_name: "Doe",
    email: "john.doe@test.com",
    identifier: "U2"
};
