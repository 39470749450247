import "./Calendar.css";
import ReactCalendar from "react-calendar";
import 'react-calendar/dist/Calendar.css';
import { Assignment } from "inspector-common/lib/model";
import { Badge, colors } from "@mui/material";
import { getDateString } from "../../../../utils";
import { useState } from "react";

interface CalendarProps {
    assignments: Map<string, Array<Assignment>>
    selectedDate: Date
    setSelectedDate: (date: Date) => void
};

function Calendar(props: CalendarProps) {
    const today = new Date();
    const [selectedMonth, setSelectedMonth] = useState<number>(today.getMonth());
    today.setHours(0, 0, 0, 0);

    const hasAssignments = (date: Date) => {
        return (props.assignments.get(getDateString(date)) || []).length !== 0
    };
    return <ReactCalendar 
        onChange={props.setSelectedDate}
        onActiveStartDateChange={(event: any) => setSelectedMonth(event.activeStartDate.getMonth())}
        value={props.selectedDate}
        className="calendar"
        tileClassName={(tile: any) => {
            var classes = "calendar-tile "
            if (tile.date.getTime() === today.getTime()) {
                classes += "calendar-tile-today "
            }
            if (selectedMonth !== tile.date.getMonth()) {
                classes += "calendar-tile-other-month "
            }
            if (props.selectedDate.getTime() === tile.date.getTime()) {
                classes += "calendar-tile-active "
            }
            return classes
        }}
        minDetail="month"
        showNeighboringMonth={true}
        formatDay={() => ""}
        tileContent={({ date }: any) => {
            return hasAssignments(date) ? <Badge variant="dot" color="secondary">
                {date.getDate()}
            </Badge > : <>{date.getDate()}</>
        }}
    />
};

export default Calendar;