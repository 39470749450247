import "./TemplateRoomEditor.css";
import { InspectionNode, Template } from "inspector-common/lib/model";
import { TemplateRoomEditorContainer } from "../../container";
import TemplateRoomList from "./TemplateRoomList";
import { useState } from "react";
import { TemplateRoomInfo } from "../TemplateRoomInfo";
import { Divider } from "@mui/material";
import { addChildNode, deleteChildNode, getChildNode, moveInspectionNode, updateChildNode } from "inspector-common/lib/utils/inspection";

interface TemplateRoomEditorProps {
    template: Template
    setTemplate: (template: Template) => void
};

interface SelectedNode {
    identifier: Array<number>
    root: InspectionNode
}

function TemplateRoomEditor(props: TemplateRoomEditorProps) {
    const [selectedPath, setSelectedPath] = useState<Array<number> | undefined>(undefined);

    function removeNode(identifier: Array<number>): void {
        props.setTemplate({...props.template, root: deleteChildNode(props.template.root, identifier)})
    };

    function addNode(parentIdentifier: Array<number>, index: number, newNode: InspectionNode): void {
        props.setTemplate({...props.template, root: addChildNode(props.template.root, newNode, parentIdentifier.concat(index))})
    };

    function updateNode(identifier: Array<number>, node: InspectionNode): void {
        if (identifier.length === 0) {
            props.setTemplate({...props.template, root: node})
        } else {
            props.setTemplate({...props.template, root: updateChildNode(props.template.root, node, identifier)})
        }
    };

    function onSelectNode(node: InspectionNode, identifier: Array<number>) {
        setSelectedPath(identifier);
    };

    const moveNode = (identifier:  Array<number>, newIdentifier: Array<number>) => {
        props.setTemplate({...props.template, root: moveInspectionNode(props.template.root, identifier, newIdentifier)})
    }

    const selectedNode = !!selectedPath ? getChildNode(props.template.root, selectedPath ?? []) : undefined
    return <TemplateRoomEditorContainer >
        <TemplateRoomList 
            parent={props.template.root}
            addNode={addNode}
            removeNode={removeNode}
            updateNode={updateNode}
            setSelectedNode={onSelectNode}
            moveNode={moveNode}
        />
        <Divider orientation="vertical" flexItem sx={{marginLeft: 2}}/>
        {
            selectedNode !== undefined ? <TemplateRoomInfo 
                node={selectedNode} 
                identifier={selectedPath ?? []}
                updateNode={updateNode}
            /> : <></>
        }

    </TemplateRoomEditorContainer>
};

export default TemplateRoomEditor