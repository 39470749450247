import { DocumentLanguage } from "../../model";
import InspectionCostingSystem from "../../model/inspection/InspectionCostingSystem";
import { InspectionType } from "../../model/inspection/InspectionType";
import { createInspection } from "../../model/inspection/create";
import { mockGetTemplate } from "../client/mocks/templates";
export var mockGetInspections = {
    inspections: [
        createInspection({
            assignment_id: "2",
            identifier: "ABC123",
            name: "Plaatsbeschrijving",
            language: DocumentLanguage.NL,
            root_node: mockGetTemplate.template.root,
            start_date: null,
            object_type: "Huis",
            costing_system: InspectionCostingSystem.SPLIT,
            inspection_type: InspectionType.BEFORE_RENT,
            terms: {
                general: [],
                clauses: [],
                remarks: [],
                clause_cost_party: null,
                clause_cost_split: null,
                signature_text_header: "",
                signature_text_items: []
            },
            remarks: [],
            signature_executor: null,
            signature_party_1: null,
            signature_party_2: null,
            extra_signatures: [],
            party1: {
                name: "Zorin BV",
                role: "Huurder",
                address: {
                    city: "Zoersel",
                    street: "Kerkstraat",
                    zip_code: "2980",
                    number: "20",
                    bus: null
                }
            },
            party2: {
                name: "AG IT-Solutions",
                role: "Verhuurder",
                address: {
                    city: "Zoersel",
                    street: "Kerkstraat",
                    zip_code: "2980",
                    number: "30",
                    bus: "3"
                },
                representative: "Aron Geerts"
            },
            extra_parties: []
        })
    ]
};
export var mockGetInspectionImageUrl = {
    urls: [{ url: "https://my-bucket/image" }]
};
