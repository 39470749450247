import { Apartment } from "@mui/icons-material";
import { IconButton, Stack, TableCell, TableRow, Tooltip, Typography } from "@mui/material";
import { Company } from "inspector-common/lib/model";
import OpenInNewIcon from '@mui/icons-material/OpenInNew';
import { useLanguage } from "inspector-common/lib/language";
import { getDateString } from "inspector-common/lib/utils/utils";
import Delete from "@mui/icons-material/Delete";

interface CompanyListItemProps {
    company: Company
    setCompanySignInID: (companyID: string) => void
    openCompanyDetails: (company: Company) => void
    setDeleteCompanyID: (companyID: string | null) => void
};

const styles = {
    row: {
        cursor: "pointer",
        "&:hover": {
            backgroundColor: "lightgrey"
        }
    }
}

export default function CompanyListItem(props: CompanyListItemProps) {
    const language = useLanguage();

    return <TableRow onClick={() => props.openCompanyDetails(props.company)} sx={styles.row}>
        <TableCell>
            <Stack direction="row" spacing={2} alignItems={"center"}>
                <Apartment color="primary"/>
                <Typography fontWeight="bold">{props.company.name}</Typography>
            </Stack>
        </TableCell>
        <TableCell>{props.company.subscription_type}</TableCell>
        <TableCell>{getDateString(props.company.registration_timestamp)}</TableCell>

        <TableCell>
            <Tooltip title={language.generic.loginToCompany}>
                <IconButton onClick={(e) => {
                    e.stopPropagation();
                    props.setCompanySignInID(props.company.identifier)
                }} >
                    <OpenInNewIcon htmlColor="grey" />
                </IconButton>
            </Tooltip>
            <Tooltip title={language.generic.delete}>
                <IconButton onClick={(e) => {
                    e.stopPropagation();
                    props.setDeleteCompanyID(props.company.identifier)
                }}>
                    <Delete htmlColor="red" />
                </IconButton>
            </Tooltip>
        </TableCell>
 
    </TableRow>

}