import { IconButton, List, Menu, MenuItem } from "@mui/material"
import MoreVertIcon from '@mui/icons-material/MoreVert';
import { Assignment, AssignmentStatus, User } from "inspector-common/lib/model"
import { useState } from "react";
import { useArchiveAssignment, useDeleteAssignment, useUnarchiveAssignment, useUpdateAssignment } from "inspector-common/lib/api/hooks";
import { useLanguage } from "inspector-common/lib/language";
import { useNavigate } from "react-router-dom";
import AssignToUserDialog from "./AssignToUserModel";
import { DeleteDialog } from "../../../../components";

interface OptionsMenuProps {
    users: Array<User>
    assignment: Assignment
    onDelete: () => void
    onAssign: () => void
    onMarkStatus: () => void
    archive: boolean
    onArchive: () => void
};

function OptionsMenu(props: OptionsMenuProps) {
    const language = useLanguage();
    const navigate = useNavigate();
    const [menuOpen, setMenuOpen] = useState<boolean>(false);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const [assignmentModalOpen, setAssignmentModalOpen] = useState<boolean>(false);
    const [confirmDeletionModalOpen, setConfirmDeletionModalOpen] = useState<boolean>(false);
    const { execute: archive } = useArchiveAssignment();
    const { execute: unarchive } = useUnarchiveAssignment();
    
    const handleClick = (event: React.MouseEvent<HTMLButtonElement>) => {
        setAnchorEl(event.currentTarget);
        setMenuOpen(true);
    };
    const handleClose = () => {
        setAnchorEl(null);
        setMenuOpen(false);
    };

    const {
        error: deleteAssignmentError,
        execute: deleteAssignment
    } = useDeleteAssignment();
    const {
        execute: updateAssignment
    } = useUpdateAssignment();

    const setAssignee = (assignee: string) => {
        return updateAssignment({assignment: {...props.assignment, assignee}})
    };

    const setAssignmentStatus = (status: AssignmentStatus) => {
        return updateAssignment({assignment: {...props.assignment, status}})
    };

    return <>
        <IconButton 
            onClick={handleClick}
            aria-controls={menuOpen ? 'basic-menu' : undefined}
            aria-haspopup="true"
            aria-expanded={menuOpen ? 'true' : undefined}
        ><MoreVertIcon /></IconButton>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={menuOpen}
            onClose={() => setMenuOpen(false)}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
            <List>{
                props.archive ? [] : <>
            
                    <MenuItem onClick={() => {
                        handleClose();
                        navigate(`/assignments/edit/${props.assignment.identifier}`)
                    }}>{language.generic.open}</MenuItem>
                    <MenuItem onClick={() => {setAssignmentModalOpen(true)}}>{language.assignments.assign}</MenuItem>
                    <MenuItem onClick={() => {
                        const targetStatus = props.assignment.status === AssignmentStatus.PENDING ? AssignmentStatus.DONE : AssignmentStatus.PENDING;
                        setAssignmentStatus(targetStatus).then(props.onMarkStatus).then(handleClose);
                    }}>
                        {
                            (
                                props.assignment.status === AssignmentStatus.DONE ? 
                                language.assignments.markPending : language.assignments.markDone
                            )
                        }
                    </MenuItem>
                </>  
            }
            <MenuItem onClick={() => {
                (props.archive ? unarchive({assignment_id: props.assignment.identifier}) : 
                    archive({assignment_id: props.assignment.identifier})).then(() => props.onArchive())
            }}>
                {
                    props.archive ? language.assignments.unarchive : language.assignments.archive
                }
            </MenuItem>
            <MenuItem onClick={() => {
                setConfirmDeletionModalOpen(true);
            }}>{language.generic.delete}</MenuItem>
            </List>
        </Menu>
        <AssignToUserDialog 
            users={props.users}
            open={assignmentModalOpen}
            setOpen={setAssignmentModalOpen}
            onCancel={() => setAssignmentModalOpen(false)}
            onConfirm={async (userId: string) => {
                setAssignee(userId).then(props.onAssign);
                setAssignmentModalOpen(false);
            }}
            assignment={props.assignment}
        />
        <DeleteDialog 
            open={confirmDeletionModalOpen}
            setOpen={setConfirmDeletionModalOpen}
            delete={
                () => {
                    deleteAssignment({identifier: props.assignment.identifier}).then((response) => {
                        handleClose();
                        setConfirmDeletionModalOpen(false);
                        if (response) {
                            props.onDelete();
                        }
                    })

                }
            }
        />
    </>
}

export default OptionsMenu;