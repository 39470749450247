import React, { useState } from "react";
import { Container, Box, Button, Stack } from "@mui/material";
import EmailInput from "../common/EmailInput";
import { useLanguage } from "inspector-common/lib/language";
import { useAuth } from "../../../../auth";
import ErrorMessage from "../change-password/ErrorMessage";

const styles = {
  container: {
    display: "flex",
    width: "100%",
    height: "100vh",
    justifyContent: "center",
    alignItems: "center",
  },
  loginForm: {
    width: "60%",
    display: "flex",
    flexDirection: "column",
    borderRadius: "25px",
    p: 6,
    pb: 8,
    boxShadow: 3,
  },
};

interface RequestRestProps {
  onEmailSent: () => void;
  email: string;
  setEmail: (email: string) => void;
}

export default function RequestResetComponent(props: RequestRestProps) {
  const [error, setError] = useState("");
  const auth = useAuth();
  const language = useLanguage();


  const handlePasswordReset = async () => {
    const response = await auth.resetPassword(props.email);
    if (response.success) {
      props.onEmailSent(); // Notify the parent component
    } else {
      setError(response.message);
    }
  };

  return (
    <Container sx={styles.container} maxWidth="md">
      <Stack sx={styles.loginForm} direction="column" spacing={4}>
        <h2>{language.users.requestPasswordReset}</h2>
        <EmailInput email={props.email} setEmail={props.setEmail} />
        <Button onClick={handlePasswordReset}>{language.users.askReset}</Button>
        <ErrorMessage error={error} />
      </Stack>
    </Container>
  );
}
