import { Button, Stack } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionParty } from "inspector-common/lib/model/inspection/InspectionParty";
import InspectionPartyInput from "./InspectionPartyInput";
import { Add } from "@mui/icons-material";

interface InspectionPartiesInputProps {
    extraParties: InspectionParty[]
    setExtraParties: (parties: InspectionParty[]) => void
    party1: InspectionParty;
    setParty1: (party: InspectionParty) => void
    party2: InspectionParty;
    setParty2: (party: InspectionParty) => void
};

export default function InspectionPartiesInput(props: InspectionPartiesInputProps) {
    const language = useLanguage();

    return <Stack direction="column" spacing={2}>
            <InspectionPartyInput party={props.party1} setParty={props.setParty1} partyIndex={1} rolePlaceholder={language.assignments.attributes.partyRolePlaceholder}/>
            <InspectionPartyInput party={props.party2} setParty={props.setParty2} partyIndex={2} rolePlaceholder={language.assignments.attributes.partyRolePlaceholder2}/>
            {
                props.extraParties.map((party: InspectionParty, index: number) => {
                    return <InspectionPartyInput
                        key={index.toString()}
                        party={party}
                        partyIndex={index + 3}
                        setParty={(party: InspectionParty) => props.setExtraParties([
                            ...props.extraParties.slice(0, index),
                            party,
                            ...props.extraParties.slice(index + 1)
                        ])}
                        deleteParty={() => props.setExtraParties([
                            ...props.extraParties.slice(0, index),
                            ...props.extraParties.slice(index + 1)
                        ])}
                    />
                })
            }
            <Button 
                onClick={() => {props.setExtraParties([
                    ...props.extraParties,
                    {
                        name: "",
                        role: "",
                        info: "",
                        address: {
                            city: "",
                            street: "",
                            zip_code: "",
                            number: "",
                            bus: ""
                        },
                        representative: ""
                    }
                ])}}
                variant="contained"
                startIcon={<Add />}
            >
                {language.assignments.addParty}
            </Button>
        </Stack>
}