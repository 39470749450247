import { Button } from "@mui/material"
import { useNavigate } from "react-router-dom";
import { useLanguage } from "inspector-common/lib/language"

interface CreateAssignmentButtonProps {
    selectedDate: Date
};

function formatDate(date: Date): string {
    return `${date.getFullYear()}-${date.getMonth() + 1}-${date.getDate()}`
}

function CreateAssignmentButton(props: CreateAssignmentButtonProps) {
    const label = useLanguage().assignments.create;
    const navigate = useNavigate();
    return <Button variant="contained" onClick={() => navigate(`/assignments/create?planDate=${formatDate(props.selectedDate)}`)}>
        {label}
    </Button>
};

export default CreateAssignmentButton;