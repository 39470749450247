import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle } from "@mui/material";
import { useState } from "react";
import { CreateTemplateRequest, CreateTemplateResponse } from "inspector-common/lib/api/calls"
import { useLanguage } from "inspector-common/lib/language";
import { TemplateHeader } from "inspector-common/lib/model";
import CopyTemplateSelect from "./CopyTemplateSelect";
import NameTextField from "./NameTextField";

interface CreateTemplateDialogProps {
    open: boolean
    templates: Array<TemplateHeader>
    setOpen: (value: boolean) => void
    create: (request: CreateTemplateRequest) => Promise<CreateTemplateResponse | null>
}

function CreateTemplateDialog(props: CreateTemplateDialogProps) {
    const language = useLanguage();
    const handleClose = () => props.setOpen(false);

    const [name, setName] = useState<string>("");
    const [copyTemplate, setCopyTemplate] = useState<string | null>(null);

    return <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <Box className="add-template-dialog">
            <DialogTitle id="alert-dialog-title">
                {language.templates.add}
            </DialogTitle>
            <DialogContent>
                <NameTextField name={name} setName={setName} />
                <CopyTemplateSelect templates={props.templates} selectedTemplateID={copyTemplate} setTemplateID={setCopyTemplate} />
            </DialogContent>
            <DialogActions>
                <Button onClick={() => {
                    props.create({template: {name: name}, copy_from_template: copyTemplate}); 
                    handleClose();
                }} variant="contained">{language.generic.confirm}</Button>
                <Button onClick={handleClose}>{language.generic.cancel}</Button>
            </DialogActions>
        </Box>
    </Dialog>
};

export default CreateTemplateDialog;