import { Button, Dialog, DialogContent, DialogTitle, Stack, Typography } from "@mui/material"
import { useLanguage } from "inspector-common/lib/language"
import { useCreditBalance } from "../../credits/functions/hooks"

interface ConfirmInspectionCreationDialogProps {
    open: boolean
    onClose: () => void
    confirm: () => void
};

const styles = {
    dialog: {
        maxWidth: "400px"
    }
}
export default function ConfirmInspectionCreationDialog(props: ConfirmInspectionCreationDialogProps) {
    const language = useLanguage();
    const { credits } = useCreditBalance();

    return <Dialog onClose={props.onClose} open={props.open}>
        <DialogTitle>{language.assignments.addDocument}</DialogTitle>
        <DialogContent sx={styles.dialog}>
            <Stack direction="column" spacing={2}>
                {
                    credits >= 0 ? <Typography>{language.credits.buyConfirmation}</Typography> :
                        <Typography color={"red"}>{language.inspection.errors.insufficient_credits}</Typography>
                }
                <Stack direction={"row"} spacing={2} justifyContent={"space-around"}>
                    <Button variant="contained" onClick={props.confirm} disabled={credits <= 0}>{language.generic.confirm}</Button>
                    <Button variant="outlined" onClick={props.onClose}>{language.generic.cancel}</Button>
                </Stack>
            </Stack>
            
        </DialogContent>
    </Dialog>
    
}