import { Add, Cancel } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode } from "inspector-common/lib/model";
import Observation from "inspector-common/lib/model/inspection/Observation";
import ImageGrid from "./ImageGrid";
import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";

interface ObservationProps {
    assignmentId: string
    inspectionId: string
    node: InspectionNode
    updateNode: (node: InspectionNode) => void
};

const styles = {
    observationStack: {
        pl: 6
    },
    aligned: {
        alignItems: "center"
    },
    titleStack: {
        justifyContent: "space-between",
        ml: -6
    }
}
export default function Observations(props: ObservationProps) {
    const language = useLanguage();
        
    function addObservation() {
        props.updateNode({
            ...props.node,
            observations: [
                ...props.node.observations, 
                {images: [], remarks: [], type: ""}
            ]
        })
    };

    function updateObservation(index: number, observation: Observation) {
        props.updateNode({
            ...props.node,
            observations: [
                ...props.node.observations.slice(0, index), 
                observation,
                ...props.node.observations.slice(index + 1)
            ]
        })
    };

    function deleteObservation(index: number) {
        props.updateNode({
            ...props.node,
            observations: [
                ...props.node.observations.slice(0, index), 
                ...props.node.observations.slice(index + 1)
            ]
        })
    };

    return <Stack direction="column" spacing={2} >
        <Typography variant="h6">{language.inspection.observations}</Typography>
        {
            props.node.observations.map((observation: Observation, index: number) => {
                return <Stack direction="column" spacing={2} sx={styles.observationStack} key={index.toString()}>
                    <Stack direction="row" spacing={1} sx={[styles.aligned, styles.titleStack]}>
                        <Typography>{language.inspection.observation}</Typography>
                        <IconButton onClick={() => deleteObservation(index)}>
                            <Cancel fontSize={"small"}/>
                        </IconButton>
                    </Stack>
                    
                    <Typography>{language.inspection.observationType}</Typography>
                    <TextField
                        value={observation.type}
                        onChange={(event) => updateObservation(index, {...observation, type: event.target.value})}
                    />
                    <Typography>{language.inspection.remarks}</Typography>
                    {
                        observation.remarks.map((remark: string, remarkIndex: number) => {
                            return <Stack direction="row" spacing={1} sx={styles.aligned} key={remarkIndex.toString()}>
                                <IconButton onClick={() => {updateObservation(index, {...observation, remarks: [
                                    ...observation.remarks.slice(0, remarkIndex),
                                    ...observation.remarks.slice(remarkIndex + 1)
                                ]})}}>
                                    <Cancel />
                                </IconButton>
                                <TextField
                                    fullWidth
                                    value={remark}
                                    onChange={(event) => {
                                        updateObservation(index, {
                                            ...observation,
                                            remarks: [
                                                ...observation.remarks.slice(0, remarkIndex),
                                                event.target.value,
                                                ...observation.remarks.slice(remarkIndex + 1)
                                            ]
                                        })
                                    }}
                                />
                            </Stack>
                        })
                    }
                    <Box>
                        <Button
                            onClick={() => updateObservation(index, {...observation, remarks: [...observation.remarks, ""]})}
                            startIcon={<Add/>}
                        >
                            {language.inspection.addRemark}
                        </Button>
                    </Box>

                    <Typography>{language.inspection.observationImages}</Typography>
                    <ImageGrid
                        assignmentId={props.assignmentId}
                        inspectionId={props.inspectionId}
                        images={observation.images}
                        setImages={(images: InspectionImage[]) => updateObservation(index, {...observation, images: images})}
                    />
                </Stack>
            })

        }
        <Box>
            <Button onClick={addObservation} startIcon={<Add />}>
                <Typography>{language.inspection.addObservation}</Typography>
            </Button>
        </Box>
    </Stack>
}