import { useState } from "react";
import { Spinner } from "../../../components";
import { useLanguage } from "inspector-common/lib/language";
import { Box, Container, Typography } from "@mui/material";

interface RedirecPageProps {}

const styles = {
    box: {
        m: 8,
        p: 2,
        boxShadow: 12
    }
}

export default function RedirectPage(props: RedirecPageProps) {
    const language = useLanguage();
    const [message, setMessage] = useState<string | null>(null);

    // Check for redirect after successful payment
    const query = new URLSearchParams(window.location.search);

    if (message === null && query.get("success")) {
        setMessage(language.credits.successfulTransaction);
    }

    if (message === null && query.get("canceled")) {
        setMessage(language.credits.failedTransaction);
    }

    if (message === null) {
        return <Spinner />
    }
    
    return <Container>
        <Box sx={styles.box}><Typography>{message}</Typography></Box>
    </Container>
}