import { IconButton, List, ListItem, Tooltip } from "@mui/material";
import { DashboardPanel } from "../types"
import DashboardMenuItem from "./DashboardMenuItem";
import { SettingsOutlined } from "@mui/icons-material";
import { useLanguage } from "inspector-common/lib/language";
import { useAuth } from "../../../auth";
import { Role } from "inspector-common/lib/model";
import { useIsAppAdmin } from "../../../auth/auth";
import { useNavigate } from "react-router-dom";

interface DashboardMenuItemsProps {
    items: Array<DashboardPanel>
};

const styles = {
    settingsIcon: {
        position: "fixed",
        bottom: "20px",
        left: "20px"
    }
}

function DashboardMenuItems(props: DashboardMenuItemsProps) {
    const isAppAdmin = useIsAppAdmin();
    const navigate = useNavigate();
    const language = useLanguage()
    const menuItems = props.items.map((item: DashboardPanel) => (
        <ListItem key={item.name}>
            <DashboardMenuItem item={item} />
        </ListItem>
    ));
    return <>
        <List>
            {menuItems}
        </List>
        <Tooltip title={language.generic.manageApp}>
            <IconButton 
                onClick={() => navigate("/admin")} 
                sx={styles.settingsIcon} 
            >
                {
                    isAppAdmin ? <SettingsOutlined htmlColor="white"/> : null
                }
            </IconButton>
        </Tooltip>
    </>
};

export default DashboardMenuItems;