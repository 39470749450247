import { GetAssignmentsAPICall, CreateAssignmentAPICall, GetAssignmentAPICall, GetTemplatesAPICall, GetUsersAPICall, GetTemplateAPICall, SynchronizationCall, GetInspectionsAPICall, GetInspectionAPICall, UpdateInspectionAPICall, GetInspectionImageURLAPICall, GetCompanyAPICall, UpdateCompanyAPICall, GetCompanyLogoAPICall, GetCompanyTermsAPICall, UpdateCompanyTermsAPICall, DeleteCompanyTermsAPICall, CreateCompanyTermsAPICall, ListCompanyTermsAPICall, GetInspectionImageDownloadURLAPICall, ListCompaniesAPICall, SwitchCompanyAPICall, GetCreditBalanceAPICall, GetTransactionsAPICall } from "../calls";
import { UpdateAssignmentAPICall } from "../calls/assignments/updateAssignment";
import { GetDocumentDownloadDetailsAPICall } from "../calls/documents";
import { mockGetInspectionImageUrl, mockGetInspections } from "../mock/inspections";
import { mockCreateAssignment, mockGetAssignment, mockGetAssignments, mockGetDocumentDownloadDetails, mockGetTemplates, mockUpdateAssignment } from "./mocks";
import { mockGetUsers } from "./mocks";
import { mockGetCompanyLogoUrlResponse, mockGetCompanyResponse, mockUpdateCompanyResponse } from "./mocks/company";
import { mockCompanyTerms } from "./mocks/companyTerms";
import { mockGetCreditBalance, mockGetTransactions } from "./mocks/credits";
import { mockGetTemplate } from "./mocks/templates";
function mock(response) {
    return new Promise(function (resolve, reject) {
        setTimeout(function () {
            resolve(response);
        }, 2000);
    });
}
;
var mockClient = {
    token: "dummy-token",
    getAuthHeaders: function () { return Promise.resolve({}); },
    makeAPICall: function (call) {
        if (call instanceof GetAssignmentsAPICall) {
            return mock(mockGetAssignments);
        }
        else if (call instanceof GetUsersAPICall) {
            return mock(mockGetUsers);
        }
        else if (call instanceof GetTemplatesAPICall) {
            return mock(mockGetTemplates);
        }
        else if (call instanceof GetTemplateAPICall) {
            return mock(mockGetTemplate);
        }
        else if (call instanceof CreateAssignmentAPICall) {
            return mock(mockCreateAssignment);
        }
        else if (call instanceof GetAssignmentAPICall) {
            return mock(mockGetAssignment);
        }
        else if (call instanceof UpdateAssignmentAPICall) {
            return mock(mockUpdateAssignment);
        }
        else if (call instanceof SynchronizationCall) {
            return mock(mockGetAssignments);
        }
        else if (call instanceof GetInspectionsAPICall) {
            return mock(mockGetInspections);
        }
        else if (call instanceof GetInspectionAPICall) {
            return mock({ inspection: mockGetInspections.inspections[0] });
        }
        else if (call instanceof UpdateInspectionAPICall) {
            return mock({ inspection: mockGetInspections.inspections[0] });
        }
        else if (call instanceof GetInspectionImageURLAPICall) {
            return mock(mockGetInspectionImageUrl);
        }
        else if (call instanceof GetDocumentDownloadDetailsAPICall) {
            return mock(mockGetDocumentDownloadDetails);
        }
        else if (call instanceof GetCompanyAPICall) {
            return mock(mockGetCompanyResponse);
        }
        else if (call instanceof UpdateCompanyAPICall) {
            return mock(mockUpdateCompanyResponse);
        }
        else if (call instanceof GetCompanyLogoAPICall) {
            return mock(mockGetCompanyLogoUrlResponse);
        }
        else if (call instanceof GetCompanyTermsAPICall) {
            return mock(mockCompanyTerms);
        }
        else if (call instanceof UpdateCompanyTermsAPICall) {
            return mock(mockCompanyTerms);
        }
        else if (call instanceof DeleteCompanyTermsAPICall) {
            return mock(mockCompanyTerms);
        }
        else if (call instanceof CreateCompanyTermsAPICall) {
            return mock(mockCompanyTerms);
        }
        else if (call instanceof ListCompanyTermsAPICall) {
            return mock({ terms: [mockCompanyTerms.terms] });
        }
        else if (call instanceof GetInspectionImageDownloadURLAPICall) {
            return mock({ urls: ["https://upload.wikimedia.org/wikipedia/commons/thumb/3/3f/Placeholder_view_vector.svg/681px-Placeholder_view_vector.svg.png?20220519031949"] });
        }
        else if (call instanceof ListCompaniesAPICall) {
            return mock({ companies: [mockGetCompanyResponse.company] });
        }
        else if (call instanceof SwitchCompanyAPICall) {
            return mock({ user: mockGetUsers.users[0] });
        }
        else if (call instanceof GetCreditBalanceAPICall) {
            return mock(mockGetCreditBalance);
        }
        else if (call instanceof GetTransactionsAPICall) {
            return mock(mockGetTransactions);
        }
        return mock({});
    },
    setToken: function (token) { }
};
export default mockClient;
