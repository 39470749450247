import { useMemo, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useCreateAssignment, useSendAssignmentNotification } from "inspector-common/lib/api/hooks";
import {
    AssignmentNameInput,
    AddressInput,
    PlanDateInput,
    AssignmentOverviewContainer, 
    CreateAssignmentTitle, 
    CreateAssignmentButton,
    AssigneeInput
} from "../components";
import { CreateAssignmentResponse } from "inspector-common/lib/api/calls";
import { createAssignment as createAssignmentObject } from "inspector-common/lib/model/assignment/create";
import { TextFieldContainer } from "../../../components";
import { Address } from "inspector-common/lib/model/common/Address";
import { useLanguage } from "inspector-common/lib/language";
import { TextField } from "@mui/material";
import ReferenceInput from "../components/inputs/ReferenceInput";

interface CreateAssignmentPageProps {};

const defaultAssignmentValues = createAssignmentObject({});

function usePlanDate() {
    // used when coming from calendar view
    const { search } = useLocation();
    const query: any = useMemo(() => new URLSearchParams(search), [search]);

    try {
        const planDateString = query.get("planDate")
        return planDateString !== null ? new Date(planDateString) : defaultAssignmentValues.plan_date;
    } catch {
        return new Date()
    }
}

function CreateAssignmentPage(props: CreateAssignmentPageProps) {
    const givenPlanDate = usePlanDate();

    const [name, setName] = useState<string>(defaultAssignmentValues.name);
    const [address, setAddress] = useState<Address>(defaultAssignmentValues.address);
    const [plan_date, setPlanDate] = useState<Date>(givenPlanDate);
    const [assignee, setAssignee] = useState<string | null>(defaultAssignmentValues.assignee);
    const [notifyAssignee, setNotifyAssignee] = useState<boolean>(false);
    const [reference, setReference] = useState<string>("");

    const navigate = useNavigate();
    const {
        isLoading,
        execute: createAssignment
    } = useCreateAssignment();


    const {
        isLoading: isSending,
        execute: sendAssignentNotification
    } = useSendAssignmentNotification();

    return <AssignmentOverviewContainer>
        <CreateAssignmentTitle />
        <AssignmentNameInput name={name} setName={setName} />
        <TextFieldContainer>
            <AddressInput address={address} setAddress={setAddress} />
        </TextFieldContainer>
        <TextFieldContainer>
            <PlanDateInput planDate={plan_date} setPlanDate={setPlanDate} />
        </TextFieldContainer>
        <AssigneeInput
            assignee={assignee}
            setAssignee={setAssignee}
            notifyAssignee={notifyAssignee}
            setNotifyAssignee={setNotifyAssignee}
            allowNotify={true}
        />
        <ReferenceInput reference={reference} setReference={setReference} />

        <CreateAssignmentButton loading={isLoading || isSending} onClick={() => {
            createAssignment({
                assignment: {
                    name, 
                    address, 
                    plan_date, 
                    assignee,
                    reference
                }
            }).then((response: CreateAssignmentResponse | null) => {
                if (response?.assignment.identifier) {
                    if (assignee && notifyAssignee) {
                        sendAssignentNotification({assignee: assignee, assignment_id: response.assignment.identifier})
                    }
                    navigate("/assignments")
                }
            })
        }}/>
    </AssignmentOverviewContainer>
};

export default CreateAssignmentPage;