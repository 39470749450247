import { mockAssignment, mockAssignmentWithAssignee } from "../../mock";
export var mockGetAssignments = {
    assignments: [mockAssignment, mockAssignmentWithAssignee],
    continuation_token: ""
};
export var mockCreateAssignment = {
    assignment: mockAssignment
};
export var mockGetAssignment = {
    assignment: mockAssignmentWithAssignee
};
export var mockUpdateAssignment = {
    assignment: mockAssignment
};
