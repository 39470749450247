import { Box, ListItem, Stack, TableCell, TableRow } from "@mui/material";
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import { Role, User } from "inspector-common/lib/model";
import AdminEditable from "./AdminEditable";
import { useState } from "react";
import { useLanguage } from "inspector-common/lib/language";
import RoleSelect from "./RoleSelect";
import { useDeleteUser, useUpdateUser } from "inspector-common/lib/api/hooks";
import UpdateUserIconButton from "./UpdateUserIconButton";
import DeleteUserIconButton from "./DeleteUserIconButton";
import { useIsAdmin } from "../../../../auth/auth";

interface UserTableRowProps {
    user: User
    refresh: () => void
};

function UserTableRow(props: UserTableRowProps) {
    const language = useLanguage();
    const isAdmin = useIsAdmin();
    const [first_name, setFirstName] = useState(props.user.first_name);
    const [last_name, setLastName] = useState(props.user.last_name);
    const [role, setRole] = useState(props.user.role);
    const [email, setEmail] = useState(props.user.email);

    const {
        execute: updateUser,
        isLoading: isUpdating,
    } = useUpdateUser();
    const {
        execute: deleteUser,
        isLoading: isDeleting,
    } = useDeleteUser();
    const isLoading = isUpdating || isDeleting;
    
    return <TableRow>
        <TableCell><AccountCircleIcon /></TableCell>
        <TableCell>
            <AdminEditable 
              value={first_name}
              setValue={setFirstName}
              label={language.users.firstName}
              isAdmin={isAdmin}
            />
        </TableCell>
        <TableCell>
            <AdminEditable 
              value={last_name}
              setValue={setLastName}
              label={language.users.lastName}
              isAdmin={isAdmin}
            />
        </TableCell>
        <TableCell>
            <AdminEditable 
              value={email}
              setValue={setEmail}
              label={language.users.email}
              isAdmin={isAdmin}
            />
        </TableCell>
        <TableCell>
            <RoleSelect role={role} setRole={setRole} isAdmin={isAdmin} />
        </TableCell>
        <TableCell>
            {
                isAdmin ? <Stack direction="row">
                    <UpdateUserIconButton isLoading={isLoading} onClick={() => {
                        updateUser({
                            user: {
                                identifier: props.user.identifier, 
                                first_name, 
                                last_name, 
                                email, 
                                role,
                            }
                        })
                    }} />
                    <DeleteUserIconButton isLoading={isLoading} onDelete={() => {
                        deleteUser({identifier: props.user.identifier}).then(() => props.refresh())
                    }
                    } />
                </Stack> : <></>
            }
        </TableCell>
        
    </TableRow>
};

export default UserTableRow;