import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode } from "inspector-common/lib/model";
import { TemplateRoomInfoContainer } from "../../container";
import { TemplateRoomEditorTitle } from "../../title";
import TemplateRoomInfoEvaluationsTitle from "./TemplateRoomInfoEvaluationsTitle";
import TemplateRoomInfoTitle from "./TemplateRoomInfoTitle";
import TemplateRoomAttribute from "./TemplateRoomAttribute";

interface TemplateRoomInfoProps {
    node: InspectionNode
    identifier: Array<number>
    updateNode: (identifier: Array<number>, node: InspectionNode) => void
};

function TemplateRoomInfo(props: TemplateRoomInfoProps) {
    const update = (node: InspectionNode) => {
        props.updateNode(props.identifier, {...node})
    };
    const language = useLanguage();

    return <TemplateRoomInfoContainer>
        <TemplateRoomEditorTitle label={language.templates.editRoom} />
        <TemplateRoomInfoTitle node={props.node} updateNode={update} name={props.node.name} />
        <TemplateRoomInfoEvaluationsTitle />
        <TemplateRoomAttribute node={props.node} updateNode={update}  />
    </TemplateRoomInfoContainer>
};

export default TemplateRoomInfo;