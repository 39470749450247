import { InspectionNode } from "inspector-common/lib/model";
import { addChildNode, deleteChildNode, getChildNode, updateChildNode } from "inspector-common/lib/utils/inspection";

function getDateString(date?: Date): string {
    if (!date) {
        return ""
    }
    if (typeof date === "string") {
        return new Date(Date.parse(date)).toLocaleDateString("en-GB")
    }
    return date.toLocaleDateString("en-GB")
};

export {
    getDateString
}

