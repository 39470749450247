import { Box, List } from "@mui/material";
import { InspectionNode } from "inspector-common/lib/model";
import { CreateTemplateNode } from "../../create";
import TemplateRoomListItem from "./TemplateRoomListItem";
import { useDrop } from "react-dnd";
import { canDropF, getMoveIndex } from "../../../functions";

interface TemplateRoomListProps {
    parent: InspectionNode
    addNode: (parentIdentifier: Array<number>, index: number, newNode: InspectionNode) => void
    removeNode: (identifier: Array<number>) => void
    updateNode: (identifier: Array<number>, node: InspectionNode) => void
    setSelectedNode: (node: InspectionNode, identifier: Array<number>) => void
    moveNode: (identifier: Array<number>, newIdentifier: Array<number>) => void
};

function TemplateRoomList(props: TemplateRoomListProps) {
    const [{isOver, canDrop}, drop] = useDrop(() => ({
        accept: "node",
        drop: (item: any, monitor) => {
            if (!monitor.didDrop() && monitor.isOver({shallow: true}) && monitor.canDrop()) { 
                const index = getMoveIndex(props.parent, [], monitor);
                props.moveNode(item.identifier, [index]);
            }
        },
        canDrop: (item, monitor) => canDropF(item, monitor, []),
        collect: (monitor) => ({
            isOver: monitor.isOver({shallow: true}),
            canDrop: monitor.canDrop()
        }),
        end: () => {}
    }), [props.moveNode])

    const isActive = isOver && canDrop;
    return <Box className="template-room-list" ref={drop} sx={isActive ? {backgroundColor: "#CCEEFF"} : undefined}>
        <List>
            <CreateTemplateNode create={(node: InspectionNode) => {
                props.addNode([], 0, node)
            }} parent={props.parent} />
            {
                props.parent.children.map((node: InspectionNode, index: number) => {
                    return <TemplateRoomListItem 
                        key={index.toString() + node.name} 
                        node={node}
                        parent={props.parent}
                        parentIdentifier={[]}
                        identifier={[index]}
                        addNode={props.addNode}
                        removeNode={props.removeNode}
                        updateNode={props.updateNode}
                        setSelectedNode={props.setSelectedNode}
                        moveNode={props.moveNode}
                    />
                })
            }
        </List>
    </Box>
}

export default TemplateRoomList;