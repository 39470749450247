import "./CalendarPage.css";
import { Box } from "@mui/material";
import { useState } from "react";
import { Assignment } from "inspector-common/lib/model";
import { AssignmentListTitle, AssignmentsForDay, Calendar, CalendarPageContainer, CreateAssignmentButton } from "../components";
import { getDateString } from "inspector-common/lib/utils";
import { useAssignmentListState } from "../../../state/assignments";

interface CalendarPageProps {};

function getAssignmentsByDay(assignments: Array<Assignment> | undefined | null) {
    var assignmentsMap = new Map<string, Array<Assignment>>();
    for (var assignment of (assignments || [])) {
        const dateString = getDateString(assignment.plan_date);
        const current = assignmentsMap.get(dateString) || [];
        assignmentsMap.set(dateString, current.concat(assignment));
    };
    console.log(assignmentsMap)
    return assignmentsMap;
};

function CalendarPage(props: CalendarPageProps) {
    const [selectedDate, setSelectedDate] = useState<Date>(new Date());
    const { assignments } = useAssignmentListState();
    
    var assignmentsForDay: Array<Assignment>;
    var assignmentsByDay = getAssignmentsByDay(assignments);
    if (selectedDate) {
        assignmentsForDay = assignmentsByDay.get(getDateString(selectedDate)) || [];
    } else {
        assignmentsForDay = []
    }

    return <CalendarPageContainer>
        <Box className="calendar-box">
            <Calendar 
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                assignments={assignmentsByDay}
            />
        </Box>
        <Box className="calendar-assignments-for-day">
            <AssignmentListTitle selectedDate={selectedDate}/>
            <AssignmentsForDay assignments={assignmentsForDay}/>
            <CreateAssignmentButton selectedDate={selectedDate} /> 
        </Box>
    </CalendarPageContainer>
};

export default CalendarPage;