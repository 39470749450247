import { Box, useTheme } from "@mui/material";
import { SetStateAction } from "react";
import { Dispatch } from "react";
import { Logo } from "../../../components";
import { DashboardPanel } from "../types";
import DashboardMenuItems from "./DashboardMenuItems";

interface DashboardDrawerProps {
    open: boolean
    items: Array<DashboardPanel>
    setWidth: Dispatch<SetStateAction<number>>
};

function DashboardDrawer(props: DashboardDrawerProps) {
    const theme = useTheme();

    return <Box
        width={props.open ? undefined : 0}
        className="dashboard-drawer"
        sx={{
            backgroundColor: theme.palette.primary.dark,
            alignContent: "flex-start",
            justifyItems: "flex-start",
            pl: 3,
            pr: 3
        }}
        display="flex"
        flexDirection={"column"}
    >
        <Box display="block" sx={{
            marginY: 4,
            paddingX: 4,
            paddingTop: 1,
            backgroundColor: "white", 
            borderRadius: "10px"
        }}><Logo height={75} width={85}/></Box>
        <DashboardMenuItems items={props.items} />
    </Box>
};

export default DashboardDrawer;