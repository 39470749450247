import { Box, Button, Container, Stack, TextField, Typography } from "@mui/material";
import { useUpdateCompany } from "inspector-common/lib/api/hooks";
import { useLanguage } from "inspector-common/lib/language";
import { Spinner } from "../../../components";
import LogoUpload from "../components/LogoUpload";
import { AddressInput } from "../../assignments/components";
import { useCompany } from "../hooks/company";
import { Address } from "inspector-common/lib/model/common/Address";

interface EditCompanyPageProps {};

const styles = {
    stack: {
        width: "100%",
    }
}
function EditCompanyPage(props: EditCompanyPageProps) {
    const language = useLanguage();
    const { execute: updateCompany, isLoading } = useUpdateCompany();

    const { company, setCompany } = useCompany();


    if (!company || isLoading) {
        return <Spinner />
    }

    return <Container>
        <Stack sx={styles.stack} direction="column" spacing={5}>
            <LogoUpload />
            <Typography variant="h3">{company.name}</Typography>
            <TextField 
                label={language.company.name} 
                value={company.name} 
                onChange={(event: any) => setCompany({...company, name: event.target.value})}
            />
            <AddressInput
                address={company.address}
                setAddress={(address: Address) => setCompany({...company, address})}
            />
            <TextField 
                label={language.company.phone} 
                value={company.phone} 
                onChange={(event: any) => setCompany({...company, phone: event.target.value})}
            />
            <TextField 
                label={language.company.vat} 
                value={company.vat} 
                onChange={(event: any) => setCompany({...company, vat: event.target.value})}
            />
            <TextField 
                label={language.company.web} 
                value={company.web ?? ""} 
                onChange={(event: any) => setCompany({...company, web: event.target.value})}
            />
            <Box><Button 
                variant="contained"
                disabled={isLoading}
                onClick={() => {
                    updateCompany({company})
                }}
            >
                {language.generic.save}
            </Button></Box>
        </Stack>
    </Container>
}

export default EditCompanyPage;