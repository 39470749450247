import { TextField, Typography } from "@mui/material"

interface AdminEditableProps {
    isAdmin: boolean
    label: string
    value: string
    setValue: (value: string) => void
};

function AdminEditable(props: AdminEditableProps) {
    return props.isAdmin ? (
        <TextField 
            value={props.value} 
            onChange={(event: any) => {props.setValue(event.target.value)}}
            label={props.label} 
            fullWidth
        />
    ) : <Typography>{props.value}</Typography>
}

export default AdminEditable