import { Dispatch, SetStateAction } from "react"
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { DesktopDatePicker } from '@mui/x-date-pickers/DesktopDatePicker';
import { StandardTextFieldProps, TextField, TextFieldProps } from "@mui/material";

interface DatePickerProps {
    label: string
    value: Date | null;
    onChange: (newValue: Date | null ) => void;
};

function DatePicker(props: DatePickerProps) {
    return <LocalizationProvider dateAdapter={AdapterDateFns}>
    <DesktopDatePicker
        label={props.label}
        inputFormat="dd/MM/yyyy"
        value={props.value}
        onChange={props.onChange}
        renderInput={(params: TextFieldProps) => <TextField {...(params as StandardTextFieldProps)} />}
    />
    </LocalizationProvider>
};

export default DatePicker;