import SubscriptionType from "../../../model/company/SubscriptionType";
export var mockGetCompanyResponse = {
    company: {
        identifier: "planbv",
        registration_timestamp: "2024-01-01T00:00:00.000Z",
        name: "Plan² BV",
        email: "info@planbv.be",
        address: {
            city: "Plan BV",
            street: "Kapucijnenstraat",
            zip_code: "2200",
            number: "16",
            bus: ""
        },
        phone: "014 76 13 63",
        vat: "BE0822 023 629",
        subscription_type: SubscriptionType.PROFESSIONAL
    }
};
export var mockUpdateCompanyResponse = mockGetCompanyResponse;
export var mockGetCompanyLogoUrlResponse = {
    url: "https://some-logo",
    upload_url: "https://some-logo"
};
