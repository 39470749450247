import { Add } from "@mui/icons-material";
import { IconButton, MenuItem, Select, Stack, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { DefaultTerms } from "inspector-common/lib/model/company/DefaultTerms";
import { useNavigate } from "react-router-dom";

interface TermsSelectorProps {
    terms: Array<DefaultTerms>
    selectedIdentifier: string | null
    setSelectedIdentifier: (identifier: string) => void
    noAdd?: boolean
};

const styles = {
    picker: {
        width: "100%"
    }
}

export default function TermsSelector(props: TermsSelectorProps) {
    const navigate = useNavigate()
    const language = useLanguage();

    return <Stack direction="column" spacing={1}>

        <Stack direction="row" spacing={1}>
            <Select
                value={props.selectedIdentifier}
                onChange={(event: any) => props.setSelectedIdentifier(event.target.value)}
                style={styles.picker}
            >
                {
                    props.terms.map((terms: DefaultTerms) => (
                        <MenuItem key={terms.identifier} value={terms.identifier}>
                            {terms.name ? terms.name : language.document.terms.noName}
                        </MenuItem>
                    ))
                }

            </Select>
            {props.noAdd ? null : <IconButton onClick={() => navigate("/terms/create")}>
                <Add/>
            </IconButton>}
        </Stack>
    </Stack>
}