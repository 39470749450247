import { Delete } from "@mui/icons-material";
import { IconButton, Stack, TextField, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionParty } from "inspector-common/lib/model/inspection/InspectionParty";
import { useEffect, useState } from "react";
import { DeleteDialog } from "../../../components";
import AddressInput from "../../assignments/components/inputs/AddressInput";
import { Address } from "inspector-common/lib/model/common/Address";

interface InspectionPartyInputProps {
    partyIndex: number
    party: InspectionParty
    setParty: (party: InspectionParty) => void
    deleteParty?: () => void,
    rolePlaceholder?: string
} ;


const styles = {
    title: {},
    nameInput: {},
    roleInput: {},
    infoInput: {},
    representativeInput: {},
    titleStack: {
        justifyContent: "space-between"
    }
};

export default function InspectionPartyInput(props: InspectionPartyInputProps) {
    const language = useLanguage();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [localParty, setLocalParty] = useState(props.party)

    const handleChange = (setter: (value: InspectionParty) => void, original: InspectionParty, attribute: string, newValue: any) => {
        setter({...original, [attribute]: newValue});
    }

    useEffect(() => {
        const timeout = setTimeout(() => {
            props.setParty(localParty)
        }, 1000)

        return () => clearTimeout(timeout)
    }, [localParty])

    return <Stack direction="column" spacing={2}>
        <Stack direction={"row"} sx={styles.titleStack}>
            <Typography sx={styles.title}>{language.assignments.attributes.party + ` ${props.partyIndex}`.toString()}</Typography>
            {
                props.deleteParty !== undefined ? <IconButton onClick={() => setDeleteOpen(true)}>
                    <Delete />
                </IconButton> : null
            }
        </Stack>
        <TextField 
            value={localParty.name}
            label={language.assignments.attributes.partyName}
            onChange={(event: any) => handleChange(setLocalParty, localParty, "name", event.target.value)}/>
        <TextField 
            value={localParty.role}
            label={language.assignments.attributes.partyRole}
            onChange={(event: any) => handleChange(setLocalParty, localParty, "role", event.target.value)}
            placeholder={props.rolePlaceholder ?? language.assignments.attributes.partyRolePlaceholder}/>
        <AddressInput 
            address={localParty.address}
            setAddress={(address: Address) => handleChange(setLocalParty, localParty, "address", address)}/>
        <TextField 
            value={localParty.info}
            label={language.assignments.attributes.partyInfo}
            onChange={(event: any) => handleChange(setLocalParty, localParty, "info", event.target.value)}/>
        <TextField 
            value={localParty.representative}
            label={language.assignments.attributes.representative}
            onChange={(event: any) => handleChange(setLocalParty, localParty, "representative", event.target.value)}/>
        {props.deleteParty !== undefined ? <DeleteDialog open={deleteOpen} setOpen={setDeleteOpen} delete={props.deleteParty} /> : null}
    </Stack>
}