import { useLanguage } from "inspector-common/lib/language";
import { useCreditBalance } from "../functions/hooks";
import { Button, Container, Divider, IconButton, Stack, Typography } from "@mui/material";
import { Add, HorizontalRule } from "@mui/icons-material";
import { useState } from "react";
import { Spinner } from "../../../components";
import { useBuyCredits } from "inspector-common/lib/api/hooks";
import { useNavigate } from "react-router-dom";

interface BuyCreditsPageProps {
    
}


const styles = {
    container: {
        margin: "auto",
        height: "100vh",
        width: "100vw",
    },
    bodyContainer: {
        alignItems: "center",
        justifyContent: "center",
        height: "100%",
        width: "100%"
    },
    formStack: {
        maxWidth: 800,
        minWidth: 600,
        margin: "auto",
        alignItems: "center",
        boxShadow: "0 4px 8px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19)"
    },
    buttonStack: {
        alignItems: "center",
        justifyContent: "center"
    }

}

export default function BuyCreditsPage(props: BuyCreditsPageProps) {
    const language = useLanguage();
    const [amount, setAmount] = useState(5);
    const { credits, isLoading: isLoadingCredits } = useCreditBalance();
    const { execute, isLoading: isBuying} = useBuyCredits();
    const navigate = useNavigate();

    function increment() {
        setAmount((amount) => amount + 1)
    };

    function decrement() {
        if (amount > 0) {
            setAmount(amount -1)
        }
    };
    
    async function buyCredits() {
        return execute({amount}).then((response) => {
            if (response?.redirect_url) {
                window.location.href = response?.redirect_url
            }
        })
    }

    return <Container sx={styles.container} maxWidth={false}>
        <Stack direction="column" spacing={6} >
            <Typography variant="h2">{language.credits.buyCredits}</Typography>
            <Container sx={styles.bodyContainer}>
                <Stack direction={"column"} sx={styles.formStack} spacing={2} p={4}>
                <Typography variant="h4">{language.credits.buyCredits}</Typography>
                <Divider />

                    {
                        isLoadingCredits ? <Spinner /> : <Typography variant="h5">{language.credits.yourBalance}: {credits} {language.credits.credits}</Typography>
                    }
                    <Typography>{language.credits.amount}</Typography>

                    <Stack direction="row" sx={styles.buttonStack} spacing={4}>
                        <IconButton onClick={decrement}>
                            <HorizontalRule />
                        </IconButton>
                        <Typography variant="h5">{amount}</Typography>
                        <IconButton onClick={increment}>
                            <Add />
                        </IconButton>
                    </Stack>

                    <Typography>{language.credits.price}</Typography>
                    <Typography variant="h5">€{25 * amount},-</Typography>

                    {
                        isLoadingCredits ? null : <Typography variant="h5">{language.credits.yourNewBalance}: {credits + amount} {language.credits.credits}</Typography>
                    }
                    <Button onClick={buyCredits} variant="contained" disabled={isBuying}>
                        {language.credits.buyCredits}
                    </Button>
                </Stack>
            </Container>
        </Stack>
    </Container>
}