import DeleteIcon from "@mui/icons-material/Delete";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { DeleteDialog } from "../../../../../../components";
import { useLanguage } from "inspector-common/lib/language";
import buttonStyle from "./style";

interface DeleteProps {
    identifier: Array<number>
    removeNode: (identifier: Array<number>) => void
};


function Delete(props: DeleteProps) {
    const [open, setOpen] = useState<boolean>(false);
    const onClick = (e: any) => {e.stopPropagation(); setOpen(true)};
    const label = useLanguage().generic.delete;

    return <>
        <Tooltip title={label}>
            <IconButton onClick={onClick}>
                <DeleteIcon {...buttonStyle as any}/>
            </IconButton>
        </Tooltip>
        <DeleteDialog open={open} setOpen={setOpen} delete={() => props.removeNode(props.identifier)} />
    </>
};

export default Delete;