import { Backdrop, Box } from "@mui/material";
import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";
import ImageCanvas from "./ImageCanvas";

interface ImageProps {
    assignmentId: string
    inspectionId: string
    shownImage: InspectionImage | null
    setShownImage: (img: InspectionImage | null) => void
    saveImage: (img: InspectionImage) => void
    deleteImage: () => void
};

const styles = {
    backdrop: {
        color: "#fff",
        zIndex: 1,
        justifyContent: "center",
        alignItems: "top"
    },

    imageContainer: {
        display: "flex",
        flexDirection: "row",
        width: "100%", 
        justifyContent: "center",
        position: "relative",
    },
    image: {
        height: "600px",
        width: "100%",
        aspectRatio: "1",
    },
    buttons: {
        position: "absolute", 
        bottom: 0
    }
};

export default function ImageOpen(props: ImageProps) {
    function downloadImage(url?: string) {
        if (url) {
            window.open(url, "_blank")
        }
    };

    return <Backdrop
        sx={styles.backdrop}
        open={props.shownImage !== null}
        onClick={() => {props.setShownImage(null)}}
    >
    
    <Box
        sx={styles.imageContainer}
    >

        {props.shownImage && 
            <ImageCanvas
                assignmentId={props.assignmentId}
                inspectionId={props.inspectionId}
                image={props.shownImage}
                saveImage={(image: InspectionImage) => {props.saveImage(image); props.setShownImage(null)}}
                deleteImage={() => {props.deleteImage();props.setShownImage(null)}}
                downloadImage={() => downloadImage(props.shownImage?.url ?? undefined)} 
            />
        }

    </Box>
</Backdrop>
}