import { Create, Delete } from "@mui/icons-material";
import { Card, CardActions, CardContent, IconButton, ListItem } from "@mui/material";
import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDeleteAssignment } from "inspector-common/lib/api/hooks";
import { DeleteDialog } from "../../../../../components";
import { Assignment } from "inspector-common/lib/model";
import { useAssignmentListState } from "../../../../../state";
import AssignmentListItemAddress from "./AssignmentListItemAddress";
import AssignmentListItemAssignee from "./AssignmentListItemAssignee";
import AssignmentListItemPlanDate from "./AssignmentListItemPlanDate";
import AssignmentListItemTitle from "./AssignmentListItemTitle";

interface AssignmentListItemProps {
    assignment: Assignment;
};

function AssignmentListItem(props: AssignmentListItemProps) {
    const navigate = useNavigate();
    const [deleteOpen, setDeleteOpen] = useState(false);
    const {
        execute
    } = useDeleteAssignment();
    const assignmentListState = useAssignmentListState();

    return <ListItem sx={{paddingLeft: 0}}>
            <Card sx={{display: "flex", flexDirection: "column", width: "100%"}}>
                <CardContent>
                    <AssignmentListItemTitle assignment={props.assignment} />
                    <AssignmentListItemPlanDate assignment={props.assignment} />
                    <AssignmentListItemAssignee assignment={props.assignment} />
                    <AssignmentListItemAddress assignment={props.assignment} />
                </CardContent>
                <CardActions>
                    <IconButton onClick={() => navigate("/assignments/edit/" + props.assignment.identifier)}>
                        <Create />
                    </IconButton>
                    <IconButton onClick={() => setDeleteOpen(true)}>
                        <Delete />
                    </IconButton>
                </CardActions>
            </Card>
            <DeleteDialog 
                open={deleteOpen}
                setOpen={setDeleteOpen}
                delete={() => {
                    execute({identifier: props.assignment.identifier})
                    assignmentListState.refresh()
                }}
            />
        </ListItem>
};

export default AssignmentListItem;