import { Stack, TextField } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Address } from "inspector-common/lib/model/common/Address";

interface AddressInputProps {
    address: Address
    setAddress: (address: Address) => void
};

const styles = {
    half: {
        width: "50%"
    }
}

export default function AddressInput(props: AddressInputProps) {
    const language = useLanguage();

    return <Stack spacing={2} direction="column">
        <Stack direction="row" spacing={1}>
            <TextField 
                variant="outlined"
                value={props.address.zip_code} 
                onChange={(event) => props.setAddress({...props.address, zip_code: event.target.value})} 
                label={language.assignments.attributes.address.zipCode}
            />
            <TextField 
                sx={{flexGrow: 1}}
                variant="outlined"
                value={props.address.city} 
                onChange={(event) => props.setAddress({...props.address, city: event.target.value})} 
                label={language.assignments.attributes.address.city}
            />
        </Stack>
        <TextField 
            variant="outlined"
            value={props.address.street} 
            onChange={(event) => props.setAddress({...props.address, street: event.target.value})} 
            label={language.assignments.attributes.address.street}
        />
        <Stack direction="row" spacing={1}>
            <TextField 
                variant="outlined"
                sx={[styles.half]}
                value={props.address.number} 
                onChange={(event) => props.setAddress({...props.address, number: event.target.value})} 
                label={language.assignments.attributes.address.number}
            />
            <TextField 
                variant="outlined"
                sx={[styles.half]}
                value={props.address.bus ?? ""} 
                onChange={(event) => props.setAddress({...props.address, bus: event.target.value})} 
                label={language.assignments.attributes.address.bus}
            />
        </Stack>
    </Stack>
}