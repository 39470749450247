import { Title } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";

interface CreateAssignmentTitleProps {};


function CreateAssignmentTitle(props: CreateAssignmentTitleProps) {
    return <Title label={useLanguage().panels.createAssignment} />
};

export default CreateAssignmentTitle