import { ArrowDropDown, ArrowRight } from "@mui/icons-material"
import { Button, Dialog, DialogContent, DialogTitle, IconButton, Stack, Typography } from "@mui/material"
import { useLanguage } from "inspector-common/lib/language"
import { Inspection, InspectionNode } from "inspector-common/lib/model"
import { useState } from "react"

interface MoveDialogProps {
    open: boolean
    setOpen: (open: boolean) => void
    moveNode: (newPath: number[]) => void
    inspection: Inspection
    movingPath: number[]
}

const styles = {
    button: {
        minWidth: "300px",
        justifyContent: "flex-start",
        borderRadius: 0,
    }
}
export default function MoveDialog(props: MoveDialogProps) {
    const language = useLanguage();

    return <Dialog open={props.open} onClose={() => props.setOpen(false)}>
        <DialogTitle>
            <Typography>{language.inspection.moveNode}</Typography>
        </DialogTitle>
        <DialogContent>
            <NodeHeaders 
                node={props.inspection.root_node}
                path={[]}
                moveNode={(newPath: number[]) => {props.moveNode(newPath); props.setOpen(false)}}
                movingPath={props.movingPath}
            />
        </DialogContent>
    </Dialog>
}

interface NodeHeadersProps {
    node: InspectionNode
    path: number[]
    moveNode: (newPath: number[]) => void
    movingPath: number[]
}

function NodeHeaders(props: NodeHeadersProps) {
    const [expanded, setExpanded] = useState(props.path.length === 0);

    if (props.path.slice(0, props.movingPath.length).toString() === props.movingPath.toString()) {
        return <></>
    }
    return <Stack direction="column" spacing={0} pl={1}>
        <Stack direction={"row"} alignItems={"center"}>
            <IconButton onClick={() => setExpanded(!expanded)} disabled={props.node.children.length === 0} sx={{p: 0}}>
                { expanded ? <ArrowDropDown /> : <ArrowRight />}
            </IconButton>
            <Button 
                variant="text" 
                onClick={() => props.moveNode(props.path)}
                sx={styles.button}
            >{props.path.length > 0 ? props.node.name : "/"}</Button>
        </Stack>
        {
            expanded ? props.node.children.map((child, index) => {
                return <NodeHeaders 
                    key={props.path.concat(index).toString()}
                    node={child}
                    path={props.path.concat(index)}
                    moveNode={props.moveNode}
                    movingPath={props.movingPath}
                />
            }) : null
        }
    </Stack>
}