import { Title } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";

interface AssignmentsTitleProps {};


function AssignmentsTitle(props: AssignmentsTitleProps) {
    return <Title label={useLanguage().panels.assignments} />
};

export default AssignmentsTitle