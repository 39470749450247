import { Table, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import Transaction from "inspector-common/lib/model/credits/Transaction";
import TransactionListRow from "./TransactionListRow";
import { useLanguage } from "inspector-common/lib/language";

interface TransactionListProps {
    transactions: Transaction[]
};

const styles = {
    tableHead: {
        "& .MuiTableCell-head": {
            color: "white"
        }
    }
}

export default function TransactionList(props: TransactionListProps) {
    const language = useLanguage();

    return <TableContainer>
        <Table>
            <TableHead sx={styles.tableHead}>
                <TableCell></TableCell>
                <TableCell>{language.credits.transactionType.label}</TableCell>
                <TableCell>{language.credits.amount}</TableCell>
                <TableCell>{language.credits.price}</TableCell>
            </TableHead>
            <TableBody>
                {
                    props.transactions.sort((a, b) => a.timestamp < b.timestamp ? 1 : -1).map((t) => <TransactionListRow transaction={t} key={t.identifier}/>)
                }
            </TableBody>
        </Table>
    </TableContainer>
}