class DashboardPanel {
    name: string;
    path: string;
    icon: JSX.Element;
    component: JSX.Element;

    
    constructor(name: string, path: string, icon: JSX.Element, component: JSX.Element) {
        this.name = name;
        this.icon = icon
        this.path = path;
        this.component = component;
    }
};

export default DashboardPanel;