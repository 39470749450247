import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";
import ImageOpen from "./ImageOpen";
import { Grid, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { useState } from "react";
import ImagePreview from "../../assignments/components/image/ImagePreview";
import ImageUpload from "./ImageUpload";

interface ImageGridProps {
    assignmentId: string
    inspectionId: string
    images: InspectionImage[]
    setImages: (images: InspectionImage[]) => void
};

export default function ImageGrid(props: ImageGridProps) {
    const language = useLanguage();
    const [shownImage, setShownImage] = useState<InspectionImage | null>(null);
    return <>
        <Grid container>
            {
                props.images.length === 0 ? <Typography>{language.document.noImages}</Typography> : null
            }
            {
                props.images.map((imageReference: InspectionImage, index: number) => {
                    return <Grid item xs={12} md={6} lg={4} key={index.toString()}>
                        <ImagePreview image={imageReference} openImage={setShownImage}/>
                    </Grid>
                })
            }
        </Grid>
        <ImageUpload
            images={props.images}
            setImages={props.setImages}
            assignmentId={props.assignmentId}
            inspectionId={props.inspectionId}
        />
        <ImageOpen
            shownImage={shownImage}
            setShownImage={setShownImage}
            assignmentId={props.assignmentId}
            inspectionId={props.inspectionId}
            saveImage={(img: InspectionImage) => props.setImages(props.images.map((value: InspectionImage) => {
                if (img.identifier === value.identifier) {
                    return img
                };
                return value;
            }))}
            deleteImage={() => {
                props.setImages(props.images.filter((image: InspectionImage) => image.identifier !== shownImage?.identifier))
            }}
        />
    </>
}