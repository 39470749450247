var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { AlignmentType, BorderStyle, ImageRun, Paragraph, SectionType, TabStopPosition, TabStopType, Table, TableCell, TableLayoutType, TableRow, TextRun, WidthType } from "docx";
import { createSubtitle } from "./subtitle";
export function createSignatures(config) {
    var parties = __spreadArray([config.party1, config.party2], config.extraParties, true);
    var signatures = __spreadArray([config.signatures.party1, config.signatures.party2], config.signatures.extraSignatures, true);
    return {
        properties: {
            type: SectionType.NEXT_PAGE
        },
        children: __spreadArray(__spreadArray([
            createSubtitle(config.labels.signatures)
        ], __spreadArray(__spreadArray([], parties.map(function (party, index) {
            return __assign(__assign({}, party), { signature: signatures[index] });
        }), true), [
            {
                role: config.labels.executor,
                name: config.company.name,
                address1: config.company.address1,
                address2: config.company.address2,
                representative: config.executor,
                signature: config.signatures.executor
            }
        ], false).flatMap(function (row) { return createSignatureRow(row, config.labels.signature); }), true), [
            new Paragraph(""),
            new Paragraph(""),
            new Paragraph(""),
            new Paragraph({
                children: [
                    new TextRun("\t".concat(config.labels.executedOn, ": ").concat(config.executionDate))
                ],
                tabStops: [
                    {
                        position: TabStopPosition.MAX,
                        type: TabStopType.RIGHT
                    }
                ],
                spacing: {
                    before: 120,
                    after: 120
                }
            })
        ], false)
    };
}
function createSignatureRow(party, signatureLabel) {
    return [
        createSubtitle(""),
        new Table({
            rows: [
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [new TextRun({ text: "".concat(party.role), bold: true })]
                                })
                            ],
                            margins: {
                                bottom: 60,
                                top: 60
                            }
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [new TextRun({ text: "".concat(signatureLabel), bold: true })]
                                })
                            ],
                            margins: {
                                bottom: 60,
                                top: 60
                            }
                        })
                    ],
                }),
                new TableRow({
                    children: [
                        new TableCell({
                            children: [
                                new Paragraph({ children: [new TextRun({ text: "".concat(party.name), bold: true })] }),
                                new Paragraph({ children: [new TextRun({ text: party.address1 })] }),
                                new Paragraph({ children: [new TextRun({ text: party.address2 })] }),
                                new Paragraph({ children: [new TextRun({ text: party.representative })] }),
                            ]
                        }),
                        new TableCell({
                            children: [
                                new Paragraph({
                                    children: [party.signature ?
                                            new ImageRun({
                                                data: party.signature,
                                                transformation: {
                                                    width: 60,
                                                    height: 60
                                                }
                                            }) :
                                            new TextRun("")]
                                })
                            ],
                        })
                    ],
                })
            ],
            borders: {
                top: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                bottom: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                left: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                right: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                insideHorizontal: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
                insideVertical: { style: BorderStyle.NONE, color: "ffffff", size: 0, space: 0 },
            },
            width: {
                size: '100%',
                type: WidthType.PERCENTAGE,
            },
            alignment: AlignmentType.LEFT,
            layout: TableLayoutType.FIXED,
            columnWidths: [Math.floor(TabStopPosition.MAX) / 2, Math.floor(TabStopPosition.MAX) / 2]
        })
    ];
}
// const extraParties = (config.extraParties ?? []);
// const splitFactor = 3;
// const tabs = [
//     {
//         type: TabStopType.LEFT,
//         position: 0
//     },
//     {
//         type: TabStopType.LEFT,
//         position: Math.floor(TabStopPosition.MAX / splitFactor)
//     },
//     {
//         type: TabStopType.LEFT,
//         position: Math.floor(TabStopPosition.MAX * 2 / splitFactor)
//     }
// ]
// const parties: ({address1: string, address2: string} & InspectionParty)[] = [config.party1, config.party2];
// const signatures: any[] = [config.signatures.party1, config.signatures.party2, config.signatures.executor]
// const margin = Math.floor(TabStopPosition.MAX / 20);
// const blockWidth = Math.floor(TabStopPosition.MAX / splitFactor) - margin;
// // Create a table for the boxes and whitespace
// const columnWidths = signatures.flatMap((value, index) => {
//     if (index !== signatures.length - 1) {
//         return [blockWidth, margin]
//     } else {
//         return [blockWidth]
//     }
// });
// return {
//     properties: {
//         type: SectionType.NEXT_PAGE
//     },
//     children: [
//         createSubtitle(config.labels.signatures),
//         ...getRowChildren(parties, signatures, true),
//         ...getRowChildren(extraParties, config.signatures.extraSignatures ?? [], false),
//         new Paragraph({
//             children: [
//                 new TextRun(`${config.labels.executedOn}: ${config.executionDate}`)
//             ],
//             spacing: {
//                 before: 120,
//                 after: 120
//             }
//         })
//     ]
// }
// function createSignatureRow(parties: any[], signatures: any[], includeExecutor: boolean) {
//     const row = [];
//     const boxesToDraw = parties.length + (includeExecutor ? 1 : 0)
//     for (let i = 0; i < splitFactor; i++) {
//         row.push(
//             new TableCell({
//                 width: { size: blockWidth, type: WidthType.DXA },
//                 verticalAlign: VerticalAlign.CENTER,
//                 children: [
//                     new Paragraph({
//                         alignment: AlignmentType.CENTER,
//                         children: [
//                             //new TextRun("Image"),
//                             signatures[i] ?
//                                 new ImageRun({
//                                     data: signatures[i],
//                                     transformation: {
//                                         width: 100,
//                                         height: 100
//                                     }
//                                 }) :
//                                 new TextRun("")
//                         ]
//                     })
//                 ],
//                 borders: {
//                     top: {
//                         style: BorderStyle.SINGLE,
//                         color: i < boxesToDraw ? "000000" : "FFFFFF",
//                         size: 1
//                     },
//                     bottom: {
//                         style: BorderStyle.SINGLE,
//                         color: i < boxesToDraw ? "000000" : "FFFFFF",
//                         size: 1
//                     },
//                     left: {
//                         style: BorderStyle.SINGLE,
//                         color: i < boxesToDraw ? "000000" : "FFFFFF",
//                         size: 1
//                     },
//                     right: {
//                         style: BorderStyle.SINGLE,
//                         color: i < boxesToDraw ? "000000" : "FFFFFF",
//                         size: 1
//                     }
//                 },
//             })
//         );
//         if (i !== splitFactor - 1) {
//             row.push(
//                 new TableCell({
//                     width: { size: margin, type: WidthType.DXA },
//                     borders: {
//                         top: {
//                             style: BorderStyle.SINGLE,
//                             color: "FFFFFF",
//                             size: 1
//                         },
//                         bottom: {
//                             style: BorderStyle.SINGLE,
//                             color: "FFFFFF",
//                             size: 1
//                         },
//                         left: {
//                             style: BorderStyle.SINGLE,
//                             color: i >= boxesToDraw ? "FFFFFF" : "000000",
//                             size: 1
//                         },
//                         right: {
//                             style: BorderStyle.SINGLE,
//                             color: i >= boxesToDraw - 1 ? "FFFFFF" : "000000",
//                             size: 1
//                         }
//                     },
//                     children: [
//                         new Paragraph("")
//                     ]
//                 })
//             );
//         }
//     }
//     return row;
// }
// function getRowChildren(
//     parties: ({address1: string, address2: string} & InspectionParty)[],
//     signatures: any[],
//     includeExecutor: boolean
// ) {
//     const row = createSignatureRow(parties, signatures, includeExecutor);
//     return [
//         new Paragraph({
//             children: [
//                 ...parties.flatMap((party) => [
//                     new TextRun({text: party.role, bold: true}),
//                     new TextRun({
//                         children: [
//                             new Tab(),
//                         ]
//                     }),
//                 ]),
//                 new TextRun({text: includeExecutor ? config.labels.executor : "", bold: true}),
//             ],
//             spacing: {
//                 after: 120
//             },
//             tabStops: tabs
//         }),
//         new Paragraph({
//             children: [
//                 ...parties.flatMap((party) => [
//                     new TextRun({
//                         children: [
//                             party.name,
//                             new Tab(),
//                         ]
//                     }), 
//                 ]),
//                 new TextRun(includeExecutor ? config.labels.executor : "")
//             ],
//             tabStops: tabs
//         }),
//         new Paragraph({
//             children: [
//                 ...parties.flatMap((party) => [
//                     new TextRun({
//                         children: [
//                             party.address1,
//                             new Tab(),
//                         ]
//                     }), 
//                 ]),
//                 new TextRun(includeExecutor ? config.company.address1 : "")
//             ],
//             tabStops: tabs
//         }),
//         new Paragraph({
//             children: [
//                 ...parties.flatMap((party) => [
//                     new TextRun({
//                         children: [
//                             party.address2,
//                             new Tab(),
//                         ]
//                     }), 
//                 ]),
//                 new TextRun(includeExecutor ? config.company.address2 : "")
//             ],
//             tabStops: tabs
//         }),
//         new Paragraph({
//             children: [
//                 ...parties.flatMap((party) => [
//                     new TextRun({
//                         children: [
//                             party.representative ?? "",
//                             new Tab(),
//                         ]
//                     }), 
//                 ]),
//                 new TextRun(includeExecutor ? config.labels.executor : "")
//             ],
//             tabStops: tabs
//         }),
//         new Paragraph({
//             children: [
//                 ...parties.flatMap(() => [
//                     new TextRun({
//                         children: [
//                             config.labels.signature,
//                             new Tab(),
//                         ]
//                     }), 
//                 ]),
//                 new TextRun(includeExecutor ? config.labels.signature : "")
//             ],
//             tabStops: tabs,
//             spacing: {
//                 before: 360,
//                 after: 120
//             }
//         }),
//         new Table({
//             rows: [new TableRow({children: row, height: {value: convertMillimetersToTwip(40), rule: HeightRule.EXACT}})],
//             width: {
//                 size: '100%',
//                 type: WidthType.PERCENTAGE,
//             },
//             alignment: AlignmentType.LEFT,
//             layout: TableLayoutType.FIXED,
//             columnWidths: columnWidths
//         }),
//     ]
// }
