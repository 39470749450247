import { DatePicker } from "../../../../components";
import { TextFieldContainer } from "../../../../components/textfield";
import { useLanguage } from "inspector-common/lib/language";

interface ExecutionDateInputProps {
    executionDate: Date | null
    setExecutionDate: (value: Date | null) => void
};

function ExecutionDateInput(props: ExecutionDateInputProps) {
    const label = useLanguage().assignments.attributes.executionDate
    
    return <TextFieldContainer className="date-picker-container">
        <DatePicker 
            label={label} 
            value={props.executionDate} 
            onChange={(newValue: Date | null) => props.setExecutionDate(newValue)} 
        />
    </TextFieldContainer>};

export default ExecutionDateInput;