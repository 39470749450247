import { Person } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Assignment } from "inspector-common/lib/model";
import { useUserName } from "../../../../../state/users";

interface AssignmentListItemAssigneeProps {
    assignment: Assignment
};

function AssignmentListItemAssignee(props: AssignmentListItemAssigneeProps) {
    const language = useLanguage();
    const userName = useUserName(props.assignment.assignee);

    return <Box sx={{display: "flex", flexDirection: "row", marginY: 1}}>
        <Person sx={{mr: 2}}/>
        <Typography>{language.assignments.assignee + ": " + userName}</Typography>
    </Box>
};

export default AssignmentListItemAssignee;