import { ContainerProps, Container } from "@mui/material";

interface TemplateEditorContainerProps extends ContainerProps {};

const defaults: ContainerProps = {
    sx: {
        display: "flex",
        flexDirection: "column",
        maxWidth: null
    }
}
function TemplateEditorContainer(props: TemplateEditorContainerProps) {
    return <Container {...{...defaults, ...props}} />
};

export default TemplateEditorContainer;