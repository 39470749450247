import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { DocumentLanguage } from "inspector-common/lib/model";

interface DocumentLanguageSelectProps {
    language: DocumentLanguage
    setLanguage: (language: DocumentLanguage) => void
};

export default function DocumentLanguageSelect(props: DocumentLanguageSelectProps) {
    const language = useLanguage();

    return <FormControl>
        <InputLabel>{language.document.language}</InputLabel>
        <Select 
            value={props.language}
            onChange={(event) => props.setLanguage(event.target.value as DocumentLanguage)}
            label={language.document.language}
        >
            {
                Object.values(DocumentLanguage).map((l: DocumentLanguage) => {
                    return <MenuItem key={l} value={l}>{l}</MenuItem>
                })
            }
        </Select>
    </FormControl>
}