import { Container, ContainerProps } from "@mui/material";

interface SignInPageContainerProps extends ContainerProps {};

const defaultProps = {
    sx: {
        margin: "auto"
    },
    height: "100vh",
    width: "100vw",
    display: "flex",
};

function SignInPageContainer(props: SignInPageContainerProps) {
    return <Container {...{...props, ...defaultProps}} />
}

export default SignInPageContainer;