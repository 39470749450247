var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { HeadingLevel, Paragraph, SectionType, TextRun } from "docx";
import { createSubtitle } from "./subtitle";
export function createClosure(config) {
    var children = __spreadArray(__spreadArray(__spreadArray(__spreadArray(__spreadArray([
        new Paragraph({
            heading: HeadingLevel.HEADING_1,
            children: [
                new TextRun(config.labels.signaturesHeader)
            ]
        }),
        new Paragraph({
            children: [
                new TextRun({ text: config.signatureHeader, italics: true })
            ],
            spacing: {
                before: 240,
                after: 240
            }
        })
    ], config.signatureItems.map(function (item) {
        return new Paragraph({
            children: [
                new TextRun({ text: item, italics: true })
            ],
            bullet: { level: 0 },
            spacing: {
                before: 120,
                after: 120
            }
        });
    }), true), [
        createSubtitle(config.labels.clauses)
    ], false), config.clauses.map(function (clause) {
        return new Paragraph({
            children: [
                new TextRun({ text: clause, italics: true })
            ],
            bullet: { level: 0 },
            spacing: {
                before: 120,
                after: 120
            }
        });
    }), true), (config.remarks.length > 0 ? [createSubtitle(config.labels.remarks)] : []), true), config.remarks.map(function (remark) {
        return new Paragraph({
            children: [
                new TextRun({ text: remark, italics: true })
            ],
            bullet: { level: 0 },
            spacing: {
                before: 120,
                after: 120
            }
        });
    }), true);
    return {
        properties: {
            type: SectionType.NEXT_PAGE
        },
        children: children
    };
}
