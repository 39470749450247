import { newInspectionNode, RootNode } from "../../../model/inspection/InspectionNode";
var mockGetTemplates = {
    templates: [
        {
            identifier: "T1",
            name: "Rijhuis",
        },
        {
            identifier: "T2",
            name: "Duplex appartement",
        },
        {
            identifier: "T3",
            name: "Pand met handelsmogelijkheid",
        }
    ]
};
var mockGetTemplate = {
    template: {
        identifier: "t1",
        name: "Rijhuis",
        root: RootNode([
            newInspectionNode("Interieur", [
                newInspectionNode("Gelijkvloers", [
                    newInspectionNode("Keuken", [], [], [], [{ type: "Schade", remarks: ["Schade aan werkblad"], images: [] }]),
                    newInspectionNode("Woonkamer", []),
                    newInspectionNode("Garage", []),
                    newInspectionNode("Berging", []),
                    newInspectionNode("Toilet", [])
                ]),
                newInspectionNode("Niveau 1", [
                    newInspectionNode("Slaapkamer 1", []),
                    newInspectionNode("Slaapkamer 2", []),
                    newInspectionNode("Slaapkamer 3", []),
                    newInspectionNode("Badkamer", []),
                    newInspectionNode("Toilet", [])
                ])
            ]),
            newInspectionNode("Exterieur", [
                newInspectionNode("Garage", []),
                newInspectionNode("Tuin", [])
            ])
        ])
    }
};
export { mockGetTemplates, mockGetTemplate };
