import { Box } from "@mui/material";
import { useAuth } from "../../../../auth"

interface UserInfoProps {}

function UserInfo(props: UserInfoProps) {
    const { config } = useAuth()
    return <Box sx={{mt: 3, textAlign: "center"}} >
        {config.username}
    </Box>
};

export default UserInfo;