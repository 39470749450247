import { Save } from "@mui/icons-material"
import { IconButton, Stack, Tooltip } from "@mui/material"
import { useLanguage } from "inspector-common/lib/language"
import VisibilityIcon from '@mui/icons-material/Visibility';
import themeColors from "../../../theme/themeColors";

interface FloatingButtonProps {
    saving: boolean
    openGeneration: () => void
    saveInspection: () => void
};

const styles = {
    floating: {
        position: "fixed",
        bottom: "20px",
        right: "20px"
    },
    save: {
        backgroundColor: themeColors.primary.main,
        color: "white",
        "&:hover": {
            color: themeColors.primary.dark
        }
    },
    preview: {
        backgroundColor: "#CCCCCC",
        color: "#222222"
    },
    button: {
        p: 2
    }
}

export default function FloatingButtons(props: FloatingButtonProps) {
    const language = useLanguage();

    return <Stack direction="column" spacing={2} sx={styles.floating}>
        <Tooltip title={language.generic.save}>
            <IconButton onClick={props.saveInspection} sx={[styles.save, styles.button]} disabled={props.saving}>
                <Save />
            </IconButton>
        </Tooltip>

        <Tooltip title={language.document.generate}>

            <IconButton onClick={props.openGeneration} sx={[styles.preview, styles.button]}>
                <VisibilityIcon />
            </IconButton>
        </Tooltip>

    </Stack>
}