import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Assignment, Inspection } from "inspector-common/lib/model";
import InspectionCostingSystem from "inspector-common/lib/model/inspection/InspectionCostingSystem";

interface InspectionCostPickerProps {
    assignment: Assignment
    inspection: Inspection
    setInspection: (inspection: Inspection) => void
};

export default function InspectionCostPicker(props: InspectionCostPickerProps) {
    const language = useLanguage();

    return <FormControl>
        <InputLabel>{language.inspection.costingSystem}</InputLabel>
        <Select 
            label={language.inspection.costingSystem}
            value={props.inspection.costing_system}
            onChange={(event) => {props.setInspection({...props.inspection, costing_system: event.target.value as InspectionCostingSystem})}}
        >
            {
                Object.values(InspectionCostingSystem).map((value: InspectionCostingSystem) => {
                    var label = "";
                    switch (value) {
                        case (InspectionCostingSystem.PARTY1):
                            label = props.inspection.party1.name ? props.inspection.party1.name : language.assignments.attributes.party + "1";  
                            break;
                        case (InspectionCostingSystem.PARTY2):
                            label = props.inspection.party2.name ? props.inspection.party2.name : language.assignments.attributes.party + "2";  
                            break;
                        default:
                            label = language.inspection.splitCosts
                    }
                    return <MenuItem value={value} key={value}>{label}</MenuItem>
                })
            }
            
        </Select>
    </FormControl>
}