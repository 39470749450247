import { IconButton } from "@mui/material"
import RefreshIcon from '@mui/icons-material/Refresh';
import theme from "../../theme/theme";
import { useState } from "react";

interface RefreshButtonProps {
    loading: boolean
    onRefresh: () => void
}

function RefreshButton(props: RefreshButtonProps) {
    return <IconButton 
        onClick={props.onRefresh}
        sx={{marginLeft: 3, marginRight: 3}}
    >
        <RefreshIcon sx={{animation: props.loading ? "spin" : undefined}}/>
    </IconButton>
};

export default RefreshButton;