import { Typography } from "@mui/material";

interface TitleProps {
    label: string
    variant?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6" | "subtitle1" | "subtitle2" | "body1" | "body2" | "caption" | "button" | "overline" | "inherit" | undefined
};

function Title(props: TitleProps) {
    return <Typography variant={props.variant || "h3"} >{props.label}</Typography>
};

export default Title;