import { Check } from "@mui/icons-material";
import { CircularProgress, Stack, Typography } from "@mui/material";
import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";
import { useEffect, useState } from "react"

interface SingleImageUploadProps {
    assignmentId: string
    inspectionId: string
    identifier: string
    imgUrl: string | null
    name: string
    file: File
    promise: Promise<InspectionImage> | undefined
};


const styles = {
    stack: {
        alignItems: "center"
    }
};


export default function SingleImageUpload(props: SingleImageUploadProps) {
    const [loading, setLoading] = useState<boolean>(true);

    useEffect(() => {
        console.log("Use effect")
        if (props.promise) {
            props.promise.then(() => setLoading(false));
        }
    }, [props.promise])
    return <Stack direction="row" spacing={2} sx={styles.stack}>
        <Typography>{props.file.name}</Typography>
        {
            loading ? <CircularProgress /> : <Check color="success"/>
        }
    </Stack>
}