import { Place } from "@mui/icons-material";
import { Box, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Assignment } from "inspector-common/lib/model";
import { formatAddress } from "inspector-common/lib/utils/utils";

interface AssignmentListItemAddressProps {
    assignment: Assignment
};

function AssignmentListItemAddress(props: AssignmentListItemAddressProps) {
    const language = useLanguage();

    return <Box sx={{display: "flex", flexDirection: "row", marginY: 1}}>
        <Place sx={{mr: 2}}/>
        <Typography>{language.assignments.attributes.address.address + ": " + formatAddress(props.assignment.address)}</Typography>
    </Box>
};

export default AssignmentListItemAddress;