import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Stack, TextField } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode, newInspectionNode, Template } from "inspector-common/lib/model";
import { useState } from "react";
import TemplateSelect from "../../assignments/components/inputs/InspectionsInput/TemplateSelect";
import { AssignmentNameInput } from "../../assignments/components";
import RoomSelect from "./RoomSelect";

interface AddInspectionNodeDialogProps {
    templates: Template[]
    node: InspectionNode
    updateNode: (node: InspectionNode) => void
    open: boolean
    setOpen: (open: boolean) => void
}

export default function AddInspectionNodeDialog(props: AddInspectionNodeDialogProps) {
    const language = useLanguage();
    const handleClose = () => props.setOpen(false);
    const [template, setTemplate] = useState<Template | null>(null);
    const [selectedRoom, setSelectedRoom] = useState<InspectionNode | null>(null);
    const [name, setName] = useState<string>("");
    
    function addNode() {
        let newNode = newInspectionNode(name, []);

        if (template && selectedRoom) {
            newNode = {...selectedRoom, name}
        }
        props.updateNode({...props.node, children: [...props.node.children, newNode]})
    };

    return <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {language.inspection.addRoom}
        </DialogTitle>
        <DialogContent>
            <Stack direction="column" spacing={4} alignItems="stretch">
                <TextField 
                    label={language.generic.name} 
                    value={name} 
                    onChange={(event: any) => setName(event.target.value)}
                />
                <TemplateSelect templates={props.templates} template={template} setTemplate={setTemplate} />
                {
                    template ? <>
                        <RoomSelect template={template} selectedRoom={selectedRoom} setSelectedRoom={setSelectedRoom} />
                    </> : null
                }
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                addNode(); 
                handleClose();
            }} variant="contained">{language.generic.confirm}</Button>
            <Button onClick={handleClose}>{language.generic.cancel}</Button>
        </DialogActions>
    </Dialog>
}