import { Stack, TextField, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Assignment, DocumentLanguage, Inspection } from "inspector-common/lib/model";
import InspectionTypeSelect from "./InspectionTypeSelect";
import InspectionCostPicker from "./InspectionCostPicker";
import InspectionPartiesInput from "./InspectionPartiesInput";
import { InspectionParty } from "inspector-common/lib/model/inspection/InspectionParty";
import { useEffect, useState } from "react";
import DocumentLanguageSelect from "./DocumentLanguageSelect";
import { AddressInput, PlanDateInput } from "../../assignments/components";

interface InspectionInfoProps {
    assignment: Assignment
    inspection: Inspection
    setInspection: (inspection: Inspection) => void
};

const styles = {
    picker: {}
}

export default function InspectionInfo(props: InspectionInfoProps) {
    const language = useLanguage();
    const [name, setName] = useState(props.inspection.name)
    const [objectType, setObjectType] = useState(props.inspection.object_type)

    useEffect(() => {
        const timeout = setTimeout(() => props.setInspection({
            ...props.inspection, 
            name: name,
            object_type: objectType
        }), 1000)
        return () => clearTimeout(timeout)
    }, [name, objectType])

    return <Stack direction="column" spacing={2}>
        <Typography variant="h5">{props.inspection.name}</Typography>
        <TextField 
            value={name}
            placeholder={language.generic.name}
            label={language.generic.name}
            onChange={(event: any) => setName(event.target.value)} 
        />
        <AddressInput address={props.inspection.address!} setAddress={(address) => props.setInspection({...props.inspection, address})} />
        <PlanDateInput planDate={props.inspection.start_date ?? new Date()} setPlanDate={(startDate) => props.setInspection({...props.inspection, start_date: startDate})} />

        <DocumentLanguageSelect language={props.inspection.language} setLanguage={(language: DocumentLanguage) => props.setInspection({...props.inspection, language})}/>


        <TextField 
            value={objectType}
            placeholder={language.inspection.objectType}
            label={language.inspection.objectType}
            onChange={(event: any) => {
                setObjectType(event.target.value);
            }} 
        />

        <InspectionTypeSelect inspection={props.inspection} setInspection={props.setInspection} />
        <InspectionCostPicker inspection={props.inspection} setInspection={props.setInspection} assignment={props.assignment} />
        <InspectionPartiesInput
            party1={props.inspection.party1}
            party2={props.inspection.party2}
            extraParties={props.inspection.extra_parties}
            setParty1={(party: InspectionParty) => props.setInspection({...props.inspection, party1: party})}
            setParty2={(party: InspectionParty) => props.setInspection({...props.inspection, party2: party})}
            setExtraParties={(extraParties: InspectionParty[]) => props.setInspection({...props.inspection, extra_parties: extraParties})}
        />
        </Stack>
}