import { TextField, IconButton, InputAdornment } from "@mui/material";
import { useState } from "react";
import Visibility from "@mui/icons-material/Visibility";
import VisibilityOff from "@mui/icons-material/VisibilityOff";
import { useLanguage } from "inspector-common/lib/language";

interface PasswordInputProps {
  password: string;
  setPassword: (value: string) => void;
  label?: string;
}

const styles = {
  textField: {
    mb: 2,
  },
};

export default function PasswordInput(props: PasswordInputProps) {
  const [visible, setVisible] = useState<boolean>(false);

  const language = useLanguage();

  return (
    <TextField
      label={props.label ?? language.users.password}
      placeholder={props.label ?? language.users.password}
      value={props.password}
      onChange={(event: any) => props.setPassword(event.target.value)}
      type={visible ? "text" : "password"}
      sx={styles.textField}
      InputProps={{
        endAdornment: (
          <InputAdornment position="end">
            <IconButton
              aria-label="toggle password visibility"
              onClick={() => setVisible(!visible)}
              edge="end"
            >
              {visible ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        ),
      }}
    />
  );
}
