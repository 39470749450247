import AddIcon from "@mui/icons-material/Add";
import { IconButton, Tooltip } from "@mui/material";
import { useState } from "react";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode } from "inspector-common/lib/model";
import CreateTemplateNodeDialog from "../../../create/CreateTemplateNodeDialog";
import buttonStyle from "./style";

interface AddProps {
    parent: InspectionNode
    parentIdentifier: Array<number>
    addNode: (parentIdentifier: Array<number>, index: number, newNode: InspectionNode) => void
};


function Add(props: AddProps) {
    const [open, setOpen] = useState<boolean>(false);
    const onClick = (e: any) => {e.stopPropagation(); setOpen(true)};
    const create = (node: InspectionNode) => props.addNode(props.parentIdentifier, 0, node)
    const label = useLanguage().templates.add;
    return <>
        <Tooltip title={label}>
            <IconButton onClick={onClick}>
                <AddIcon {...buttonStyle as any}/>
            </IconButton>
        </Tooltip>
        <CreateTemplateNodeDialog 
            parent={props.parent} 
            create={create} 
            open={open}
            setOpen={setOpen}
        />
    </>
};

export default Add;