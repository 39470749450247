import { Box } from "@mui/material";
import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";
import { useEffect, useRef, useState } from "react";
import { drawImage } from "../../../inspection/functions/draw";

interface ImagePreviewProps {
    image?: InspectionImage
    openImage?: (url: InspectionImage | null) => void
};

const PREVIEW_WIDTH = 600;

const styles = {
    imageBox: {
        margin: 30,
    }
}

export default function ImagePreview(props: ImagePreviewProps) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const isRotated = [90, 270, -90, -270].includes(props.image?.rotation ?? 0);

    useEffect(() => {
        
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            context?.clearRect(0, 0, isRotated ? height : width, isRotated ? width : height);
            const element: HTMLImageElement = document.getElementById(props.image?.identifier ?? "") as any;
            const draw = () => drawImage(canvas, element, width, height, props.image?.paths ?? [], props.image?.rotation ?? 0)

            element.onload = draw
            draw();

        }
    }, [props.image?.url, props.image?.paths, canvasRef.current, props?.image?.rotation])

    return <Box onClick={() => props.openImage === undefined ? () => {} : props.openImage(props.image ?? null)}>
    <canvas
        ref={canvasRef}
        height={height}
        width={width}
        style={styles.imageBox}
        id={`canvas-${props.image?.identifier}`}
    >
        <img 
            id={props.image?.identifier}
            alt={"LOCI Expert " + (props.image?.identifier ?? "")}
            crossOrigin="anonymous"
            src={props.image?.url}
            style={styles.imageBox}
            onLoad={(event) => {
                const img: HTMLImageElement = document.getElementById(props.image?.identifier ?? "") as HTMLImageElement;
                const factor = Math.max(img.height / PREVIEW_WIDTH, img.width / PREVIEW_WIDTH)
                
                setWidth(img.width / factor);
                setHeight(img.height / factor);
            }}
        />
    </canvas>
</Box>
}
