import { Container, ContainerProps } from "@mui/material";

interface CalendarPageContainerProps extends ContainerProps {};

const defaults: ContainerProps = {
    sx: {
        display: "flex",
        flexDirection: "row"
    }
};

function CalendarPageContainer(props: CalendarPageContainerProps) {
    return <Container {...{...defaults, ...props}} />
};

export default CalendarPageContainer;