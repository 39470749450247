import { Box, IconButton, Stack, Tooltip } from '@mui/material';
import { IPath, InspectionImage } from 'inspector-common/lib/model/inspection/InspectionImage';
import { useCallback, useEffect, useRef, useState } from 'react';
import UndoIcon from '@mui/icons-material/Undo';
import themeColors from '../../../theme/themeColors';
import { useLanguage } from 'inspector-common/lib/language';
import { Delete, Download, Save, RotateLeft, RotateRight } from '@mui/icons-material';
import { DeleteDialog } from '../../../components';
import { PEN_COLOR, drawImage as baseDrawImage } from '../functions/draw';

interface ImageCanvasProps {
    assignmentId: string
    inspectionId: string
    image: InspectionImage;
    saveImage: (image: InspectionImage) => void
    deleteImage: (image: InspectionImage) => void
    downloadImage: () => void
}

type Coordinate = {
    x: number;
    y: number;
};

const styles = {
    iconButtons: {
        position: "absolute",
        bottom: 5,
        ml: "5px",
    },
    iconButton: {
        backgroundColor: themeColors.primary.main,
        color: "white",
        ':hover': {
            backgroundColor: "white",
            color: themeColors.primary.main,
        }
    },
    deleteButton: {
        color: themeColors.error.main,
        backgroundColor: "white",
        ':hover': {
            color: "white",
            backgroundColor: themeColors.error.main,
        }
    }
}

const CANVAS_WIDTH = 600;

export default function ImageCanvas(props: ImageCanvasProps) {
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const [isPainting, setIsPainting] = useState(false);
    const [deleteOpen, setDeleteOpen] = useState(false);
    const [mousePosition, setMousePosition] = useState<Coordinate | undefined>(undefined);
    const language = useLanguage();
    const [width, setWidth] = useState<number>(0);
    const [height, setHeight] = useState<number>(0);
    const [paths, setPaths] = useState<Array<IPath>>(props.image.paths ?? []);
    const [rotation, setRotation] = useState<number>(props.image.rotation ?? 0);


    function drawImage() {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        const image: HTMLImageElement = document.getElementById(props.image.identifier) as any;

        const factor = Math.max(image.width / CANVAS_WIDTH, image.height / CANVAS_WIDTH)
        baseDrawImage(canvas, image, image.width / factor, image.height / factor, paths, rotation)

        setWidth(image.width / factor)
        setHeight(image.height  / factor)

    }

    const startPaint = useCallback((event: MouseEvent) => {
        const coordinates = getCoordinates(event);
        if (coordinates) {
            setIsPainting(true);
        }
    }, []);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('mousedown', startPaint);
        return () => {
            canvas.removeEventListener('mousedown', startPaint);
        };
    }, [startPaint]);

    const paint = useCallback(
        (event: MouseEvent) => {
            const newPaths = [...paths];

            if (isPainting) {
                const newMousePosition = getCoordinates(event);
                let isExisting = !!mousePosition;
                if (isExisting && newMousePosition) {
                    const index = newPaths.length - 1;
                    if (index === 0) {
                        isExisting = false;
                    } 
                    const lastLine = newPaths[index]?.segments?.at(-1);
                    if (!lastLine) {
                        isExisting = false;
                    } else {
                        const oldX = lastLine ? +lastLine.split(" ")[1] : 0;
                        const oldY = lastLine ? +lastLine.split(" ")[2] : 0;
    
                        const newX = newMousePosition.x / width;
                        const newY = newMousePosition.y / height;
                        if (Math.pow(newY - oldY, 2) + Math.pow(newX - oldX, 2) > 0.002) {
                            newPaths[index].segments.push(`L ${newX.toFixed(2)} ${newY.toFixed(2)}`)
                            setPaths(newPaths);
                        } 
                    }
                   

                } 
                if (!isExisting && newMousePosition) {
                    const newX = newMousePosition.x / width;
                    const newY = newMousePosition.y / height;
                    newPaths.push({color: PEN_COLOR, segments: [`M ${newX.toFixed(2)} ${newY.toFixed(2)}`]})
                    setPaths(newPaths)
                }
            }
        },
        [isPainting, mousePosition]
    );

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('mousemove', paint);
        return () => {
            canvas.removeEventListener('mousemove', paint);
        };
    }, [paint]);

    const exitPaint = useCallback(() => {
        setIsPainting(false);
        setMousePosition(undefined);
    }, [mousePosition, isPainting]);

    useEffect(() => {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        canvas.addEventListener('mouseup', exitPaint);
        canvas.addEventListener('mouseleave', exitPaint);
        return () => {
            canvas.removeEventListener('mouseup', exitPaint);
            canvas.removeEventListener('mouseleave', exitPaint);
        };
    }, [exitPaint]);

    useEffect(() => {drawImage()}, [canvasRef.current, paths, rotation])

    const getCoordinates = (event: MouseEvent): Coordinate | undefined => {
        if (!canvasRef.current) {
            return;
        }

        const canvas: HTMLCanvasElement = canvasRef.current;
        return { x: event.clientX - canvas.offsetLeft, y: event.clientY - canvas.getBoundingClientRect().top};
    };

    function clearDrawing() {
        if (!canvasRef.current) {
            return;
        }
        const canvas: HTMLCanvasElement = canvasRef.current;
        const context = canvas.getContext('2d');
        if (context) {
            setPaths(paths.slice(0, -1))
        }
    };

    function rotateImage(angle: number) {
        setRotation((rotation + angle) % 360)
    };

    function saveImage() {
        if (!canvasRef.current) {
            return;
        }

        props.saveImage({...props.image, paths: paths, rotation})
    }


    return <Stack direction="column"
        justifyContent={"center"}
        width={canvasRef?.current?.width ?? width} 
        height={canvasRef?.current?.height ?? height} 
        onClick={(e) => {e.stopPropagation();}}
        >
        <canvas
            ref={canvasRef}
            height={height}
            width={width}
            
            onMouseMove={(e: any) => {
                if (isPainting) {
                    setMousePosition(getCoordinates(e));
                }
            }}
        />
        <Stack direction="row" spacing={1} sx={styles.iconButtons}>
            <Tooltip title={language.generic.undo}>
                <IconButton onClick={() => clearDrawing()} sx={styles.iconButton} >
                    <UndoIcon />
                </IconButton>
            </Tooltip>

            <Tooltip title={language.generic.download}>
                <IconButton onClick={() => props.downloadImage()} sx={styles.iconButton} >
                    <Download />
                </IconButton>
            </Tooltip>

            <Tooltip title={language.inspection.rotateRight}>
                <IconButton onClick={() => {
                    rotateImage(90);
                }} sx={styles.iconButton} >
                    <RotateRight />
                </IconButton>
            </Tooltip>
            <Tooltip title={language.inspection.rotateLeft}>
                <IconButton onClick={() => {
                    rotateImage(-90);
                }} sx={styles.iconButton} >
                    <RotateLeft />
                </IconButton>
            </Tooltip>
            <Tooltip title={language.generic.save}>
                <IconButton onClick={() => {
                    saveImage()
                }} sx={styles.iconButton} >
                    <Save />
                </IconButton>
            </Tooltip>
            <Tooltip title={language.generic.delete}>
                <IconButton onClick={() => setDeleteOpen(true)} sx={styles.deleteButton} >
                    <Delete />
                </IconButton>
            </Tooltip>

            <DeleteDialog
                open={deleteOpen}
                setOpen={setDeleteOpen}
                delete={() => {props.deleteImage(props.image)}} 
            />
        </Stack>
    </Stack>
};

