import "./UserTable.css";
import { Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Role, User } from "inspector-common/lib/model";
import UserTableRow from "./UserTableRow";

interface UserTableProps {
    users: Array<User>
    refresh: () => {}
};

const styles = {
    headerCell: {
        color: "white"
    }
}

function UserTable(props: UserTableProps) {
    const language = useLanguage();

    return <Table className="user-table">
        <TableHead sx={{backgroundColor: "primary.dark"}}>
            <TableRow>
                <TableCell></TableCell>
                <TableCell style={styles.headerCell}>{language.users.firstName}</TableCell>
                <TableCell style={styles.headerCell}>{language.users.lastName}</TableCell>
                <TableCell style={styles.headerCell}>{language.users.email}</TableCell>
                <TableCell style={styles.headerCell}>{language.users.role}</TableCell>
                <TableCell ></TableCell>
            </TableRow>
        </TableHead>
        <TableBody>
            {
                props.users.filter((user: User) => {
                    return user.role !== Role.APP_ADMIN
                }).map((user: User) => <UserTableRow key={user.identifier} user={user} refresh={props.refresh}/>)
            }
        </TableBody>
    </Table>
};

export default UserTable;