import { Button } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Link } from "react-router-dom";

interface ForgotPasswordButtonProps {};

export default function ForgotPasswordButton(props: ForgotPasswordButtonProps) {
    const language = useLanguage();

    return <Link to="/reset-password"><Button variant="text">
        {language.users.forgotPassword}    
    </Button></Link>
}