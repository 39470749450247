import { Stack } from "@mui/material"
import EditableTextFieldArray from "./EditableTextFieldArray"
import { InspectionTerms } from "inspector-common/lib/model/inspection/InspectionTerms"
import { useLanguage } from "inspector-common/lib/language"
import { Assignment, Company, Inspection } from "inspector-common/lib/model"

interface TermsEditorProps {
    terms: InspectionTerms
    setTerms: (terms: InspectionTerms) => void
    inspection?: Inspection
    assignment?: Assignment
    company?: Company
    executor?: string
};

export default function TermsEditor(props: TermsEditorProps) {
    const language = useLanguage();

    return <Stack direction="column" spacing={4}>
        <EditableTextFieldArray 
            title={language.document.terms.general}
            values={props.terms.general}
            setValues={(values: string[]) => {props.setTerms({...props.terms, general: values})}}
            editable={true}
            minRows={10}
            maxRows={30}
            includeRender={true}
            inspection={props.inspection}
            assignment={props.assignment}
            company={props.company}
            executor={props.executor}
        />
        <EditableTextFieldArray 
            title={language.document.terms.clauses}
            values={props.terms.clauses}
            setValues={(values: string[]) => {props.setTerms({...props.terms, clauses: values})}}
            editable={true}
            includeRender={true}
            inspection={props.inspection}
            assignment={props.assignment}
            company={props.company}
            executor={props.executor}
        />
        <EditableTextFieldArray 
            title={language.document.terms.clauseCostParty}
            values={[props.terms.clause_cost_party ?? ""]}
            setValues={(values: string[]) => {props.setTerms({...props.terms, clause_cost_party: values[0]})}}
            includeRender={true}
            inspection={props.inspection}
            assignment={props.assignment}
            company={props.company}
            executor={props.executor}
        />
        <EditableTextFieldArray 
            title={language.document.terms.clauseCostSplit}
            values={[props.terms.clause_cost_split ?? ""]}
            setValues={(values: string[]) => {props.setTerms({...props.terms, clause_cost_split: values[0]})}}
            includeRender={true}
            inspection={props.inspection}
            assignment={props.assignment}
            company={props.company}
            executor={props.executor}
        />
        <EditableTextFieldArray 
            title={language.document.terms.remarks}
            values={props.terms.remarks}
            setValues={(values: string[]) => {props.setTerms({...props.terms, remarks: values})}}
            editable={true}
            includeRender={true}
            inspection={props.inspection}
            assignment={props.assignment}
            company={props.company}
            executor={props.executor}
        />
        <EditableTextFieldArray 
            title={language.document.terms.signatureTextHeader}
            values={[props.terms.signature_text_header ?? ""]}
            setValues={(values: string[]) => {props.setTerms({...props.terms, signature_text_header: values[0]})}}
            includeRender={true}
            inspection={props.inspection}
            assignment={props.assignment}
            company={props.company}
            executor={props.executor}

        />
        <EditableTextFieldArray 
            title={language.document.terms.signatureTextItems}
            values={props.terms.signature_text_items}
            setValues={(values: string[]) => {props.setTerms({...props.terms, signature_text_items: values})}}
            editable={true}
            includeRender={true}
            inspection={props.inspection}
            assignment={props.assignment}
            company={props.company}
            executor={props.executor}

        />

    </Stack>
}