import { Box, Typography, TypographyProps } from "@mui/material";

interface LogoProps {
    height: number
    width: number
    text?: boolean
    fat?: boolean
    black?: boolean
    small?: boolean
}



function Logo(props: LogoProps) {
    let logo = "logo.png";
    if (props.black) {
        logo = "logo-black.png";
    } else if (props.small) {
        logo = "logo-square.png";
    }
    return <img alt="Plan2 Logo"
        src={`${window.location.origin}/${logo}`} 
        style={{ 
            height: props.height,
        }}
    />
}

export default Logo;