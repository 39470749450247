import { Box } from "@mui/material";
import { Title } from "../../../../components";

interface EditAssignmentTitleProps {
    name: string
};


function EditAssignmentTitle(props: EditAssignmentTitleProps) {
    return <Box sx={{pl: "24px"}}><Title label={props.name}/></Box>
};

export default EditAssignmentTitle