import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SetStateAction, useEffect, useState } from "react";
import { useLanguage } from "inspector-common/lib/language";
import { Template } from "inspector-common/lib/model";

interface TemplateSelectProps {
    templates: Template[]
    template: Template | null
    setTemplate: (template: SetStateAction<Template | null>) => void
}

function TemplateSelect(props: TemplateSelectProps) {
    const language = useLanguage();

    const [templateId, setTemplateId] = useState<string | null>(null);

    useEffect(() => {
        if (templateId) {
            props.setTemplate(props.templates.find((t) => t.identifier === templateId) ?? null);
        } else {
            props.setTemplate(null)
        }
    }, [templateId])

    return <FormControl fullWidth>
        <InputLabel id="template-select">{language.document.template}</InputLabel>
        <Select
            labelId="template-select"
            value={templateId ?? ""}
            label={language.document.template}
            onChange={(event: any) => {
                if (event.target.value) {
                    setTemplateId(event.target.value)
                } else {
                    setTemplateId(null)
                }
            }}
        >
            <MenuItem value={""}>
                {language.document.nullTemplate}
            </MenuItem>
            {(props.templates ?? []).map((template: Template) => (
                <MenuItem key={template.identifier} value={template.identifier}>
                    {template.name}
                </MenuItem>
            ))}

        </Select>
    </FormControl>
}

export default TemplateSelect;