import ArrowDownward from "@mui/icons-material/ArrowDownward";
import { IconButton, Tooltip } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode } from "inspector-common/lib/model";
import { moveChildNodeDown } from "inspector-common/lib/utils/inspection";
import buttonStyle from "./style";

interface MoveDownProps {
    identifier: Array<number>
    parent: InspectionNode
    parentIdentifier: Array<number>
    node: InspectionNode
    updateNode: (identifier: Array<number>, node: InspectionNode) => void
};


function MoveDown(props: MoveDownProps) {
    const onClick = (e: any) => {
        e.stopPropagation();
        const nbSiblings = props.parent.children.length;
        const index = (props.identifier.at(-1) as number)

        if (index === nbSiblings - 1) {
            return;
        }
        const newParent = moveChildNodeDown(props.parent, [index]);
        props.updateNode(props.parentIdentifier, newParent);

    };
    const label = useLanguage().templates.moveDown;
    
    return <Tooltip title={label}>
        <IconButton onClick={onClick}>
            <ArrowDownward {...buttonStyle as any}/>
        </IconButton>
    </Tooltip>
};

export default MoveDown;