import { Add, Delete, ExpandMore, Visibility, VisibilityOff } from "@mui/icons-material"
import { Box, IconButton, Stack, TextField, Typography } from "@mui/material"
import { useLanguage } from "inspector-common/lib/language"
import { Assignment, Company, Inspection } from "inspector-common/lib/model"
import { renderTermsItem } from "inspector-common/lib/utils/inspection"
import { useState } from "react"

export interface EditableTextFieldArrayProps {
    title: string
    minRows?: number
    maxRows?: number
    values: string[]
    setValues: (values: string[]) => void
    editable?: boolean
    includeRender?: boolean
    inspection?: Inspection
    assignment?: Assignment
    company?: Company
    executor?: string
};

const styles = {
    addParagraphStack: {
        alignItems: "center"
    }
}

interface SingleFieldProps {
    index: number,
    section: string,
    values: string[]
    setValues: (values: string[]) => void
    editable?: boolean
}

function SingleField(props: SingleFieldProps) {
    const [expanded, setExpanded] = useState(true);

    return <Stack direction="row" key={props.index} alignItems={"center"}>
        <TextField 
            fullWidth
            multiline
            value={props.section} 
            onChange={(event: any) => props.setValues([
                ...props.values.slice(0, props.index),
                event.target.value,
                ...props.values.slice(props.index + 1),
            ])}
            // minRows={expanded ? props.minRows ?? 2 : 1} 
            maxRows={expanded ? undefined : 1} 
        />
        {props.editable ? <Box><IconButton onClick={() => {
            props.setValues([
                ...props.values.slice(0, props.index),
                ...props.values.slice(props.index + 1)
            ])}}
        ><Delete/></IconButton></Box> : null}
        {expanded ? 
            <Box><IconButton onClick={() => {setExpanded(!expanded)}}><VisibilityOff/></IconButton></Box> : 
            <Box><IconButton onClick={() => {setExpanded(!expanded)}}><Visibility/></IconButton></Box>
        }
    </Stack>
}

export default function EditableTextFieldArray(props: EditableTextFieldArrayProps) {
    const language = useLanguage();

    return <Stack direction="column" spacing={1}>
        <Typography variant="h6">{props.title}</Typography>
        {
            props.values.map((section: string, index: number) =>
                <SingleField
                    key={index.toString()}
                    index={index}
                    section={section}
                    values={props.values}
                    setValues={props.setValues}
                    editable={props.editable}
                />
                
            )
        }
        {props.editable ? <Stack direction="row" spacing={2} sx={styles.addParagraphStack}>
            <IconButton onClick={() => {
                props.setValues([...props.values, ""])
            }}>
                <Add/>
            </IconButton>
            <Typography fontWeight={"bold"}>{language.document.terms.addParagraph}</Typography>
        </Stack> : null}
        {props.includeRender && !!props.inspection && !!props.assignment && !!props.company && (props.executor !== undefined) && (props.executor !== null) ? 
            <>
                {
                    props.values.map((value, index) => 
                        <Typography sx={{whiteSpace: "pre-wrap", fontStyle: "italic"}} key={index.toString()}>
                            {
                                renderTermsItem(
                                    value, 
                                    props.inspection as Inspection,
                                    props.assignment as Assignment,
                                    props.company as Company,
                                    props.executor as string
                                )
                            }
                        </Typography>
                    )
                }
            </> : null}
    </Stack>
}