import { Add, Cancel } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode, InspectionNodeAttribute } from "inspector-common/lib/model";

interface InspectionEvaluationProps {
    node: InspectionNode
    updateNode: (node: InspectionNode) => void
};

const styles = {
    addAttributeStack: {
        alignItems: "center",
        cursor: "pointer"
    },
    attributeStack: {
        alignItems: "center"
    }
}

export default function InspectionEvaluation(props: InspectionEvaluationProps) {
    const language = useLanguage();

    function updateAttribute(index: number, attribute: InspectionNodeAttribute) {
        props.updateNode({
            ...props.node,
            attributes: [
                ...props.node.attributes.slice(0, index),
                attribute,
                ...props.node.attributes.slice(index + 1)
            ]
        })
    };

    function addAttribute() {
        props.updateNode({
            ...props.node,
            attributes: [
                ...props.node.attributes,
                {name: "", value: ""},
            ]
        })
    };

    function deleteAttribute(index: number) {
        props.updateNode({
            ...props.node,
            attributes: [
                ...props.node.attributes.slice(0, index),
                ...props.node.attributes.slice(index + 1)
            ]
        })
    };

    return <Stack direction="column" spacing={1}>
        <Typography variant="h6">{language.templates.evaluation}</Typography>
        {
            props.node.attributes.map((attribute: InspectionNodeAttribute, index: number) => {
                return <Stack direction="row" spacing={1} key={index.toString()} sx={styles.attributeStack}>
                    <TextField 
                        label={language.inspection.attributeName}
                        placeholder={language.inspection.attributeName}
                        value={attribute.name}
                        onChange={(event) => updateAttribute(index, {name: event.target.value, value: attribute.value})}
                    />
                    <TextField 
                        label={language.inspection.attributeValue}
                        placeholder={language.inspection.attributeValue}
                        value={attribute.value}
                        onChange={(event) => updateAttribute(index, {name: attribute.name, value: event.target.value})}
                    />
                    <IconButton onClick={() => deleteAttribute(index)}><Cancel/></IconButton>
                </Stack>
            })
        }
        <Box>
            <Button onClick={addAttribute} startIcon={<Add />}>
                <Typography>{language.inspection.addAttribute}</Typography>
            </Button>
        </Box>
    </Stack>
}