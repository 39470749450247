import { MoreVert } from "@mui/icons-material";
import { IconButton, Link, Menu, MenuItem, Stack, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Inspection } from "inspector-common/lib/model";
import { useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import ConfirmInspectionCreationDialog from "../../../../inspection/components/ConfirmInspectionCreationDialog";
import { useUpdateInspection } from "inspector-common/lib/api/hooks";
import { v4 as uuidv4 } from "uuid";
import {
    copyInspectionNode,
    copyInspectionNodeStructure,
    copyInspectionNodeStructureWithAttributes
} from "inspector-common/lib/utils/inspection";
import { getDateString } from "inspector-common/lib/utils";

interface InspectionListItemProps {
    assignmentId: string
    inspection: Inspection
    allInspections: Inspection[]
};

const styles = {
    box: {
        alignItems: "center",
        "& .MuiLink-root": {
            textDecoration: "none",
            "&:hover": {
                textDecoration: "underline",
                cursor: "pointer"
            }
        },

    }
}

export default function InspectionListItem(props: InspectionListItemProps) {
    const navigate = useNavigate();
    const language = useLanguage();
    const [menuOpen, setMenuOpen] = useState(false);
    const ref = useRef(null);
    const [copiedInspection, setCopiedInspection] = useState<Inspection | null>(null);
    const { execute: createInspection } = useUpdateInspection();

    const getNewName = () => {
        const existingNames = props.allInspections.map((inspection: Inspection) => inspection.name);
        const baseName = props.inspection.name + " " + language.inspection.copySuffix;
        var newName = baseName;
        var index = 1;
        while (existingNames.includes(newName)) {
            newName = baseName + " " + index.toString();
            index++;
        }
        return newName;
    }

    const imageCopy = {
        assignmentId: props.assignmentId,
        inspectionId: props.inspection.identifier,
        date: getDateString(props.inspection.start_date ?? undefined)
    }

    function copyInspection(): Inspection {
        return {
            ...props.inspection,
            identifier: uuidv4(),
            root_node: copyInspectionNode(props.inspection.root_node, props.inspection.root_node.name, imageCopy),
            name: getNewName(),
            start_date: new Date()
        };
    };

    const copyInspectionStructure = () => {
        return {
            ...props.inspection,
            identifier: uuidv4(),
            root_node: copyInspectionNodeStructure(props.inspection.root_node),
            name: getNewName(),
            start_date: new Date()
        };
    };

    const copyInspectionStructureWithAttributes = () => {
        return {
            ...props.inspection,
            identifier: uuidv4(),
            root_node: copyInspectionNodeStructureWithAttributes(props.inspection.root_node),
            name: getNewName(),
            start_date: new Date()
        };
    };


    function handleCopyInspection() {
        setCopiedInspection(copyInspection());
        setMenuOpen(false); 
    };

    function handleCopyInspectionStructure() {
        setCopiedInspection(copyInspectionStructure())
        setMenuOpen(false); 
    };


    function handleCopyInspectionStructureWithAttributes() {
        setCopiedInspection(copyInspectionStructureWithAttributes())
        setMenuOpen(false); 
    };

    return <Stack direction="row" spacing={4} sx={styles.box}>
        <IconButton onClick={() => {setMenuOpen(true)}} ref={ref}>
            <MoreVert color="primary"/>
        </IconButton>
        <Link onClick={() => navigate("/assignments/edit/" + props.inspection.assignment_id + "/inspections/" + props.inspection.identifier)}>
            <Typography sx={props.inspection.name ? undefined : {fontStyle: "italic"}}>{props.inspection.name ? props.inspection.name : language.generic.noName}</Typography>
        </Link>
        {
            ref.current && <Menu anchorEl={ref.current} open={menuOpen} onClose={() => setMenuOpen(false)} closeAfterTransition>
                <MenuItem onClick={() => {
                    handleCopyInspection(); 
                }}>{language.inspection.copy}</MenuItem>
                <MenuItem onClick={() => {
                    handleCopyInspectionStructure(); 
                }}>{language.inspection.copyStructure}</MenuItem>
                <MenuItem onClick={() => {
                    handleCopyInspectionStructureWithAttributes(); 
                }}>{language.inspection.copyStructureWithAttributes}</MenuItem>
            </Menu>
        }
        <ConfirmInspectionCreationDialog
            open={copiedInspection !== null}
            onClose={() => setCopiedInspection(null)}
            confirm={() => {
                if (copiedInspection !== null) {
                    createInspection({
                        assignment_id: props.assignmentId,
                        inspection: copiedInspection
                    }).then(() => navigate(
                        "/assignments/edit/" + props.assignmentId + "/inspections/" + copiedInspection.identifier
                    ))
                }}
             } />
    </Stack>
}