import { Divider } from "@mui/material";
import { useEffect, useState } from "react";
import { useGetTemplate } from "inspector-common/lib/api/hooks/templates/useGetTemplate";
import { Spinner } from "../../../../components";
import { Template, TemplateHeader } from "inspector-common/lib/model";
import { TemplateEditorContainer } from "../container";
import { TemplateEditorTitle } from "../title";
import { TemplateRoomEditor } from "./TemplateRoomEditor";

interface TemplateEditorProps {
    template: TemplateHeader | null
    refresh: () => void
};

function TemplateEditor(props: TemplateEditorProps) {
    const {
        isLoading,
        execute,
    } = useGetTemplate();
    const [template, setTemplate] = useState<null | Template>(null)

    useEffect(() => {
        if (props.template) {
            execute({identifier: props.template.identifier}).then((response) => {
                if (response?.template) {
                    setTemplate(response.template)
                }
            });
        }
    }, [execute, props.template?.identifier])


    if (!props.template) {
        return <></>
    };

    return <TemplateEditorContainer>
        {
            isLoading ? <Spinner /> : (
                template ? <>
                    <TemplateEditorTitle template={template} setTemplate={setTemplate} refresh={props.refresh}/>
                    <Divider />
                    <TemplateRoomEditor template={template} setTemplate={setTemplate}/>
                </> : <></>
            )
        }
        
    </TemplateEditorContainer>
};

export default TemplateEditor;