import { useTheme } from "@mui/material";
import { ListItemButton } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";

interface CreateTemplateButtonProps {
    onClick: () => void
};

function CreateTemplateButton(props: CreateTemplateButtonProps) {
    const label = useLanguage().templates.add;
    const theme = useTheme();

    return <ListItemButton 
        onClick={props.onClick}
        sx={{
            backgroundColor: theme.palette.grey[300]
        }}
    >
        {label}
    </ListItemButton>
};

export default CreateTemplateButton