export function createAssignment(assignmentInputs) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q;
    var defaultAddress = {
        city: (_b = (_a = assignmentInputs.address) === null || _a === void 0 ? void 0 : _a.city) !== null && _b !== void 0 ? _b : "",
        zip_code: (_d = (_c = assignmentInputs.address) === null || _c === void 0 ? void 0 : _c.zip_code) !== null && _d !== void 0 ? _d : "",
        street: (_f = (_e = assignmentInputs.address) === null || _e === void 0 ? void 0 : _e.street) !== null && _f !== void 0 ? _f : "",
        number: (_h = (_g = assignmentInputs.address) === null || _g === void 0 ? void 0 : _g.number) !== null && _h !== void 0 ? _h : "",
        bus: (_k = (_j = assignmentInputs.address) === null || _j === void 0 ? void 0 : _j.bus) !== null && _k !== void 0 ? _k : null,
    };
    return {
        name: (_l = assignmentInputs.name) !== null && _l !== void 0 ? _l : "",
        address: (_m = assignmentInputs.address) !== null && _m !== void 0 ? _m : defaultAddress,
        plan_date: (_o = assignmentInputs.plan_date) !== null && _o !== void 0 ? _o : new Date(),
        assignee: (_p = assignmentInputs.assignee) !== null && _p !== void 0 ? _p : null,
        info: (_q = assignmentInputs.info) !== null && _q !== void 0 ? _q : ""
    };
}
