import { useGetTemplates } from "inspector-common/lib/api/hooks";
import { useGetTemplate } from "inspector-common/lib/api/hooks/templates/useGetTemplate";
import { InspectionNode, Template } from "inspector-common/lib/model";
import { useEffect, useState } from "react";

export function canDropF(item: {identifier: number[]}, monitor: any, identifier: number[]) {
    return item.identifier.toString() !== identifier.toString() && 
    !(identifier.slice(0, item.identifier.length).toString() === item.identifier.toString()) &&
    monitor.isOver({shallow: true})
}

export function getMoveIndex(node: InspectionNode, identifier: number[], monitor: any) {
    let index = 0;
    node.children.forEach((child, childIndex) => {
        const childID = identifier.concat(childIndex);
        const childY = document.getElementById(`template-node-${childID.toString()}`);
        const offset = monitor.getClientOffset();
        const childOffset = childY?.getBoundingClientRect();

        if (offset && childOffset) {
            if (offset.y > childOffset.top) {
                index++;
            }
        }
    });
    return index;
}


export function useTemplates() {
    const [templates, setTemplates] = useState<Template[]>([]);
    const { execute, isLoading } = useGetTemplates();
    const { execute: getTemplate } = useGetTemplate();

    useEffect(() => {
        execute({}).then((response) => {
            if (response?.templates) {
                Promise.all(
                    response.templates.map((templateHeader) => {
                        return getTemplate({identifier: templateHeader.identifier}).then((response) => {
                            return response?.template ?? null
                        })
                    })
                ).then((value) => value.filter((t) => t !== null) as Template[]).then((ts) => setTemplates(ts))
            }
        })
    }, [])

    return {
        templates,
        isLoading
    }
}