import { Box } from "@mui/material";
import { Title } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";

interface ProfilePageTitleProps {}


function ProfilePageTitle(props: ProfilePageTitleProps) {
    const label = useLanguage().panels.profile
    return <Box sx={{textAlign: "center", mt: 4}} ><Title label={label}/></Box>
};

export default ProfilePageTitle;