import { Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { getDateString } from "../../../../utils";

interface AssignmentListTitleProps {
    selectedDate: Date
};

function AssignmentListTitle(props: AssignmentListTitleProps) {
    const label = useLanguage().panels.assignments
    return <Typography variant="h5" >{`${label}: ${getDateString(props.selectedDate)}`}</Typography>
};

export default AssignmentListTitle;