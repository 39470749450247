import { Header, Paragraph, TabStopPosition, TabStopType, TextRun } from "docx";
import { createSubtitle } from "./subtitle";
export function createHeader(config) {
    return {
        default: new Header({
            children: [
                new Paragraph({
                    children: [
                        new TextRun("".concat(config.name)),
                    ],
                }),
                new Paragraph({
                    children: [
                        new TextRun("".concat(config.address1, " - ").concat(config.address2, "\t").concat(config.readableDate)),
                    ],
                    tabStops: [
                        {
                            type: TabStopType.RIGHT,
                            position: TabStopPosition.MAX,
                        }
                    ]
                }),
                createSubtitle("")
            ],
        }),
        first: new Header({
            children: []
        })
    };
}
