import { BorderStyle, Paragraph, TextRun } from "docx";
export function createSubtitle(title) {
    return new Paragraph({
        children: [
            new TextRun({
                text: title,
                size: 16,
            })
        ],
        border: {
            bottom: {
                size: 1,
                color: "auto",
                style: BorderStyle.SINGLE,
                space: 1
            }
        },
        spacing: {
            before: 240,
            after: 240
        },
        keepNext: true
    });
}
