import saveAs from "file-saver";
import { useGetTemplate } from "inspector-common/lib/api/hooks/templates/useGetTemplate";
import { Inspection, Template, TemplateHeader, newInspectionNode } from "inspector-common/lib/model"
import { useEffect, useState } from "react";

export function getDocumentName(inspection: Inspection, extension: string) {
    return inspection.name + extension
}

export async function downloadDocument(pdfLink: string, documentName: string) {
    return fetch(pdfLink).then((response) => {
        return response.blob().then((blob) => saveAs(blob, documentName))
    })
}

export async function uploadImage(uploadUrl: string, blob: Blob) {
    // Mock on local
    if (window.location.href.includes("localhost:3000")) {
        return Promise.resolve()
    }
    if (uploadUrl) {
        return fetch(uploadUrl, {
            method: "PUT",
            body: blob,
            headers: {"Content-Type": "application/octet-stream"}
        })
    }
};

export function useTemplate(templates: TemplateHeader[], templateId: string | null): Template {
    const { execute: getTemplate } = useGetTemplate();
    const defaultNode = {name: "", root: newInspectionNode("", []), identifier: null} as any

    const [template, setTemplate] = useState<Template>(defaultNode);

    useEffect(() => {
        if (!templates.length || templateId === null) {
            return;
        } else if (templateId === template.identifier) {
            return;
        } else {
            for (let i = 0; i++; i < templates.length) {
                const template = templates[i];
                if (template.identifier === templateId) {
                    getTemplate({identifier: templateId}).then((response) => {
                        if (response?.template) {
                            setTemplate(response?.template);
                        }
                    })
                }
            }
        }
    }, [templates.length, templateId])

    return template;
}