import { useGetCreditBalance, useGetTransactions } from "inspector-common/lib/api/hooks";
import CreditBalance from "inspector-common/lib/model/credits/CreditBalance";
import Transaction from "inspector-common/lib/model/credits/Transaction";
import { useEffect, useState } from "react";

export function useCreditBalance() {
    const [creditBalance, setCreditBalance] = useState<null | CreditBalance>(null);
    const { execute, isLoading } = useGetCreditBalance();

    useEffect(() => {
        execute({}).then((response) => {
            if (response?.balance) {
                setCreditBalance(response.balance)
            }
        })
    }, [])

    return {
        credits: creditBalance?.amount ?? -1,
        isLoading
    }
}

export function useTransactions() {
    const [transactions, setTransactions] = useState<Transaction[]>([]);
    const { execute, isLoading } = useGetTransactions();

    useEffect(() => {
        execute({}).then((response) => {
            if (response?.transactions) {
                setTransactions(response.transactions)
            }
        })
    }, [])

    return {
        transactions,
        isLoading
    }
}