import { Container } from "@mui/material";

interface InspectionPageContainerProps {
    children: any
};

export default function InspectionPageContainer(props: InspectionPageContainerProps) {
    return <Container>
       {props.children} 
    </Container>
}