import { ListItem, ListItemButton } from "@mui/material";
import { TemplateHeader } from "inspector-common/lib/model";

interface TemplateListItemProps {
    template: TemplateHeader
    selectedTemplate: TemplateHeader | null
    setSelectedTemplate: (value: TemplateHeader | null) => void
};

function TemplateListItem(props: TemplateListItemProps) {
    const onClick = () => {
        if (props.template.identifier !== props.selectedTemplate?.identifier) {
            props.setSelectedTemplate(props.template);
        } else {
            props.setSelectedTemplate(null);
        }
    };
    
    return <ListItemButton onClick={onClick} >
        {props.template.name}
    </ListItemButton>
};

export default TemplateListItem