import { useEffect } from "react";
import { atom, RecoilState, selector, useRecoilState } from "recoil";
import { GetUsersResponse } from "inspector-common/lib/api/calls";
import { useGetUsers } from "inspector-common/lib/api/hooks";
import { useLanguage } from "inspector-common/lib/language";
import { User } from "inspector-common/lib/model";
import client from "inspector-common/lib/api/client";
import { useAuth } from "../auth";

export interface UserListState {
    initiallyLoaded: boolean
    isLoading: boolean
    users: Array<User>
    error?: Error | null
    refresh: () => any
};

const defaultUserListState: UserListState = {
    initiallyLoaded: false,
    isLoading: false,
    users: [],
    refresh: () => {}
};

export const userListState: RecoilState<UserListState> = atom({
    key: "userListState",
    default: defaultUserListState
});

export const selectUserListState = selector({
    key: "selectUserList",
    get: ({ get }) => {
        return get(userListState);
    }
});

export const useUserListState = () => {
    const {
        execute,
    } = useGetUsers();
    const auth = useAuth();

    const [state, setState] = useRecoilState(userListState);
    useEffect(() => {
        if (! state.initiallyLoaded && !state.isLoading && client.token) {
            setState({...state, isLoading: true})
            execute({}).then((response: GetUsersResponse | null) => {
                if (response) {
                    setState({
                        initiallyLoaded: true,
                        isLoading: false,
                        users: response.users ?? [],
                        refresh: () => setState({...state, initiallyLoaded: false})
                    })
                } else {
                    setState({
                        initiallyLoaded: true,
                        isLoading: false,
                        users: [],
                        refresh: () => setState({...state, initiallyLoaded: false})
                    })
                }
            })
        }
    }, [execute, state.initiallyLoaded, auth.config.idToken])
    
    return state
}

export const useUserList = () => {
    return useUserListState().users;
};

export const useUserName = (userId: string | undefined | null): string => {
    const language = useLanguage();
    const userList = useUserList();

    for (var user of userList) {
        if (user.identifier === userId) {
            return `${user.first_name} ${user.last_name}`;
        }
    }
    return language.assignments.unassigned;
};