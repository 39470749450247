import { Button, Tooltip } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save"
import { User } from "inspector-common/lib/model";
import { useLanguage } from "inspector-common/lib/language";
import { useUpdateUser } from "inspector-common/lib/api/hooks";
interface UpdateUserIconButtonProps {
    isLoading: boolean
    onClick: () => void
};

function UpdateUserIconButton(props: UpdateUserIconButtonProps) {
    const language = useLanguage();

    return <>
        <Tooltip title={language.generic.save}>
            <Button onClick={async (event: any) => {event.stopPropagation(); await props.onClick();}} 
                variant="outlined"
                sx={{ padding: 1, minWidth: 0, borderRadius: "24px", marginLeft: 1}}
                color="primary"
                disabled={props.isLoading}
            >
                <SaveIcon fontSize="small" />
            </Button> 
        </Tooltip>
    </>
};

export default UpdateUserIconButton;