import { Button, Checkbox, Dialog, DialogActions, DialogContent, DialogTitle, List, ListItem, ListItemButton, Stack, Typography } from "@mui/material";
import { Dispatch, SetStateAction, useState } from "react";
import { useLanguage } from "inspector-common/lib/language";
import { Assignment, User } from "inspector-common/lib/model";
import { useSendAssignmentNotification } from "inspector-common/lib/api/hooks";

interface AssignToUserDialogProps {
    assignment: Assignment
    users: Array<User>;
    onConfirm: (userId: string) => Promise<void>;
    onCancel: () => void;
    open: boolean;
    setOpen: Dispatch<SetStateAction<boolean>>
};

const styles = {
    notificationStack: {
        alignItems: "center"
    }
};

function AssignToUserDialog(props: AssignToUserDialogProps) {
    const language = useLanguage();
    const {
        execute: sendNotification
    } = useSendAssignmentNotification();
    const [notify, setNotify] = useState<boolean>(false);
    
    const handleConfirm = (user: string) => {
        props.onConfirm(user).then(() => {
            if (notify) {
                sendNotification({assignment_id: props.assignment.identifier, assignee: user})
            }
        })
    };

    return <Dialog
        open={props.open}
        onClose={props.onCancel}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {language.assignments.assign}
        </DialogTitle>
        <DialogContent>
            <List>
                {props.users.map((user: User) => <ListItem key={user.identifier} >
                    <ListItemButton onClick={() => handleConfirm(user.identifier)}>
                        {`${user.first_name} ${user.last_name}`}
                    </ListItemButton>
                </ListItem>)}
            </List>
            <Stack direction="row" sx={styles.notificationStack}>
                <Checkbox checked={notify} onChange={() => setNotify(!notify)}/>
                <Typography>{language.assignments.assignmentMessage}</Typography>
            </Stack>
        </DialogContent>
        <DialogActions>
            <Button onClick={props.onCancel}>{language.generic.cancel}</Button>
        </DialogActions>
    </Dialog>
};

export default AssignToUserDialog;