import { Box, Button, Dialog, Stack, TextField, Typography } from "@mui/material"
import { useUpdateCompany } from "inspector-common/lib/api/hooks";
import { useLanguage } from "inspector-common/lib/language"
import { Company } from "inspector-common/lib/model"
import { AddressInput } from "../../assignments/components";
import { Address } from "inspector-common/lib/model/common/Address";

interface CompanyDetailsProps {
    company: Company | null
    setCompany: (company: Company | null) => void
};

const styles = {
    stack: {
        p: 4
    }
}

export default function CompanyDetails(props: CompanyDetailsProps) {
    const language = useLanguage();
    const { execute: updateCompany, isLoading } = useUpdateCompany()

    if (!props.company) {
        return <></>
    } 
    const company = props.company as Company

    return <Dialog open={props.company !== null} onClose={() => props.setCompany(null)}>
        <Stack direction="column" spacing={5} sx={styles.stack}>
            <Typography variant="h3">{props.company.name}</Typography>
            <TextField 
                label={language.company.name} 
                value={props.company.name} 
                onChange={(event: any) => props.setCompany({...company, name: event.target.value})}
            />
            <AddressInput
                address={props.company.address}
                setAddress={(address: Address) => props.setCompany({...company, address})}
            />
            <TextField 
                label={language.company.phone} 
                value={props.company.phone} 
                onChange={(event: any) => props.setCompany({...company, phone: event.target.value})}
            />
            <TextField 
                label={language.company.vat} 
                value={props.company.vat} 
                onChange={(event: any) => props.setCompany({...company, vat: event.target.value})}
            />
            <TextField 
                label={language.company.web} 
                value={props.company.web ?? ""} 
                onChange={(event: any) => props.setCompany({...company, web: event.target.value})}
            />
            <Box><Button 
                variant="contained"
                disabled={isLoading}
                onClick={() => {
                    updateCompany({company: company})
                }}
            >
                {language.generic.save}
            </Button></Box>
        </Stack>
    </Dialog>
}
     
    