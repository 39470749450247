import Language from "inspector-common/lib/language/language";
import { Assignment, Company, DocumentLanguage, Inspection } from "inspector-common/lib/model";
import { generateRenderConfig, getAllInspectionImagesRecursive } from "inspector-common/lib/report/renderConfig";
import generateWordDocument from "inspector-common/lib/report/generate";
import { getCompanyLogo } from "inspector-common/lib/api/hooks/company/getCompanyLogo";
import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";
import { getInspectionImageDownloadURL } from "inspector-common/lib/api/hooks/inspections/useGetInspectionImageDownloadURL";
import { drawImage } from "./draw";
import { imagePlaceholder } from "./imagePlaceholder";

const IMAGE_WIDTH = 400;

interface ImageData {
    data: string
    width: number
    height: number
}

export async function generateReport(
    company: Company,
    assignment: Assignment,
    inspection: Inspection,
    language: Language,
    executor: string,
    documentLanguage: DocumentLanguage,
    setAllSuccess: (allSuccess: boolean) => void,
    onImagesFound?: (imags: InspectionImage[]) => void,
    onRenderConfigGenerated?: () => void
) {
    let images = getAllInspectionImagesRecursive(inspection.root_node);
    if (onImagesFound) {
        onImagesFound(images);
    }
    return getImageMap(images, true).then(async (result) => {
        const [imageMap, allSuccess] = result;
        setAllSuccess(allSuccess)
        return getLogoBase64().then((logoBase64: string) => {
            return getSignaturesBase64(assignment.identifier, inspection).then((signatures) => {
                const config = generateRenderConfig(
                    company,
                    assignment,
                    inspection,
                    language,
                    signatures,
                    executor,
                    documentLanguage,
                    imageMap,
                    logoBase64
                );
                if (onRenderConfigGenerated) {
                    onRenderConfigGenerated()
                }
                return generateWordDocument(config).then((f) => {
                    return f
                });

            })


        })
    })
}

async function getImageMap(images: InspectionImage[], compressed?: boolean): Promise<[Map<string, ImageData>, boolean]> {
    
    return Promise.allSettled(images.map((image: InspectionImage) => {
        return getInspectionImageBase64(image, compressed)
    })).then((results) => {
        var map = new Map<string, ImageData>();
        let allSuccess = true
        results.forEach((result: PromiseSettledResult<ImageData | null>, index: number) => {
            if (result.status === "fulfilled" && result.value !== null) {
                map.set(images[index].identifier, result.value);
            } else {
                allSuccess = false;
                map.set(images[index].identifier, {data: imagePlaceholder, width: 180, height: 180});
            }
        });
        return [map, allSuccess];
    });
};

async function getLogoBase64(): Promise<string> {
    return getCompanyLogo().then((response) => {
        if (response.url) {
            return getImageBase64(response.url)
        } else {
            return ""
        }
    })
}

async function getImageBase64(url: string): Promise<string> {
    return fetch(url).then((value) => {
        if (value.ok) {
            return value.blob().then((blob: Blob) => {
                return new Promise((resolve, reject) => {
                    var reader  = new FileReader();
                    reader.addEventListener("load", function () {
                        resolve(reader.result as any);
                    }, false);
                
                    reader.readAsDataURL(blob);
                  })
            })
        } else {
            return ""
        }

    })
};

async function loadImage(image: InspectionImage): Promise<ImageData> {
    return new Promise<ImageData>((resolve, reject) => {

        let img: HTMLImageElement = document.createElement("img");
        img.crossOrigin = "anonymous";
        img.onerror = () => reject();
        img.src = image.url?.replace("inspection_images", "compressed_images") ?? "";
        img.addEventListener("load", () => {
            let myCanvas = document.createElement("canvas");
            const height = Math.floor(img.height / img.width * IMAGE_WIDTH)

            let w = IMAGE_WIDTH;
            let h = height
            if (image.rotation === 90 || image.rotation === 270) {
                w = height;
                h = IMAGE_WIDTH
            }
            myCanvas.width = w;
            myCanvas.height = h;

            drawImage(myCanvas, img, w, h, image.paths ?? [], image.rotation ?? 0);

            return resolve({data: myCanvas.toDataURL(), width: w, height: h})
        })
    })
}

async function getInspectionImageBase64(image: InspectionImage, compressed?: boolean): Promise<ImageData> {
    return loadImage(image);
};

async function getSignaturesBase64(assignmentId: string, inspection: Inspection) {
    const signatureIds = [
            inspection.signature_party_1,
            inspection.signature_party_2,
            inspection.signature_executor,
            ...(inspection.extra_signatures ?? [])
        ].filter((signature) => !!signature) as string[];

    return getInspectionImageDownloadURL({
        image_ids: signatureIds,
        assignment_id: assignmentId,
        inspection_id: inspection.identifier
    }).then((response) => {
        if (response?.urls) {
            return Promise.all(response.urls.map((url: string) => getImageBase64(url))).then((base64s) => {
                return {
                    party1: inspection.signature_party_1 ? base64s.at(signatureIds.indexOf(inspection.signature_party_1)) : undefined,
                    party2: inspection.signature_party_2 ? base64s.at(signatureIds.indexOf(inspection.signature_party_2)) : undefined,
                    executor: inspection.signature_executor ? base64s.at(signatureIds.indexOf(inspection.signature_executor)) : undefined,
                    extraSignatures: inspection.extra_signatures.map((identifier: string | null) => {
                        if (identifier) {
                            return base64s.at(signatureIds.indexOf(identifier))
                        }
                        return null;
                    })
                }
            })
        }
    })
}