import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { TextFieldContainer } from "../../../../components"
import { useLanguage } from "inspector-common/lib/language"
import { TemplateHeader } from "inspector-common/lib/model"

interface CopyTemplateSelectProps {
    templates: Array<TemplateHeader>
    selectedTemplateID: string | null
    setTemplateID: (value: string | null) => void
};

function CopyTemplateSelect(props: CopyTemplateSelectProps) {
    const language = useLanguage();
    const onChange = (event: any) => props.setTemplateID(event.target.value);

    return <TextFieldContainer>
        <FormControl fullWidth>
            <InputLabel id="copy-template-select">{language.templates.copyFrom}</InputLabel>
            <Select
                labelId="copy-template-select"
                value={props.selectedTemplateID ?? ""}
                label={language.templates.copyFrom}
                onChange={onChange}
            >
                {(props.templates).map((template: TemplateHeader) => <MenuItem key={template.identifier} value={template.identifier}>{template.name}</MenuItem>)}

            </Select>
        </FormControl>
    </TextFieldContainer>
};

export default CopyTemplateSelect;