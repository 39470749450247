import "./Assignments.css";
import { Box } from "@mui/system";
import { AssignmentOverviewContainer, AssignmentTable, NewAssignmentButton } from "../components";
import { AssignmentsTitle } from "../components/title";
import { RefreshButton } from "../../../components";
import { useUserList } from "../../../state/users";
import { useAssignmentListState } from "../../../state/assignments";
import { useEffect, useState } from "react";

interface AssignmentOverviewProps {};

function AssignmentOverview(props: AssignmentOverviewProps) {
    const [archive, setArchive] = useState<boolean>(false);
    const {isLoading, assignments, refresh } = useAssignmentListState(archive);    
    const users = useUserList();

    useEffect(() => refresh(), [archive])

    return <AssignmentOverviewContainer>
        <AssignmentsTitle />
        <Box className="create-button-container">
            <NewAssignmentButton />
            <RefreshButton loading={isLoading} onRefresh={refresh} />
        </Box>
        <AssignmentTable 
            archive={archive}
            setArchive={setArchive}
            loadingAssignments={isLoading}
            assignments={assignments ?? []} 
            users={users ?? []}
            refresh={refresh}
        />
    </AssignmentOverviewContainer>
};

export default AssignmentOverview;