import { Button, Dialog, DialogContent, Stack, Typography } from "@mui/material";
import { useListCompanyTerms } from "inspector-common/lib/api/hooks";
import { Inspection } from "inspector-common/lib/model";
import { DefaultTerms } from "inspector-common/lib/model/company/DefaultTerms";
import { useEffect, useState } from "react";
import TermsSelector from "../../terms/components/TermsSelector";
import { useLanguage } from "inspector-common/lib/language";

interface GenerateTermsDialogProps {
    open: boolean
    setOpen: (open: boolean) => void
    inspection: Inspection
    setInspection: (inspection: Inspection) => void
};

const styles = {
    dialog: {
        p: 4
    }
};

export default function GenerateTermsDialog(props: GenerateTermsDialogProps) {
    const language = useLanguage();
    const [termsIdentifier, setTermsIdentifier] = useState<string | null>(null);
    const [termsList, setTermsList] = useState<DefaultTerms[]>([]);
    const  { execute: getTermsList } = useListCompanyTerms();

    useEffect(() => {
        getTermsList({}).then((response) => {
            if (response?.terms) {
                setTermsList(response.terms);
                setTermsIdentifier(response.terms.at(0)?.identifier ?? null)
            }
        })
    }, []);

    return <Dialog open={props.open} onClose={() => props.setOpen(false)}>
        <DialogContent sx={styles.dialog}>
            <Stack direction="column" spacing={2} alignItems="center">
                <Typography variant="h4">{language.document.terms.general}</Typography>
                <TermsSelector terms={termsList} selectedIdentifier={termsIdentifier} setSelectedIdentifier={setTermsIdentifier} noAdd/>
                <Button onClick={() => {
                    const selectedTerms = termsList.filter((terms) => terms.identifier === termsIdentifier)[0];
                    if (selectedTerms) {
                        props.setInspection({
                            ...props.inspection,
                            terms: selectedTerms.terms
                        })
                    }
                }}>
                    {language.document.terms.regenerate}
                </Button>
            </Stack>
        </DialogContent>
    </Dialog>
}