import { useLanguage } from "inspector-common/lib/language"
import { TextField, TextFieldContainer } from "../../../../components"

interface ReferenceInputProps {
    reference: string
    setReference: (reference: string) => void
}

export default function ReferenceInput(props: ReferenceInputProps) {
    const language = useLanguage();

    return <TextFieldContainer>
        <TextField
            label={language.document.reference}
            value={props.reference}
            onChange={(event) => {
                props.setReference(event.target.value)
            }}
        />
    </TextFieldContainer>
}