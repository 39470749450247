import { DocumentLanguage } from "../documents";
import InspectionCostingSystem from "./InspectionCostingSystem";
import { newInspectionNode } from "./InspectionNode";
import { InspectionType } from "./InspectionType";
import { v4 as uuidv4 } from "uuid";
;
export var defaultTerms = {
    general: [],
    remarks: [],
    clause_cost_party: "",
    clause_cost_split: "",
    clauses: [],
    signature_text_header: "",
    signature_text_items: []
};
export function createInspection(inspectionInput, assignment) {
    var _a, _b, _c, _d, _e, _f, _g, _h, _j, _k, _l, _m, _o, _p, _q, _r, _s, _t, _u, _v, _w;
    var defaultAddress = {
        city: "",
        zip_code: "",
        street: "",
        number: "",
        bus: null,
    };
    return {
        assignment_id: inspectionInput.assignment_id,
        identifier: (_a = inspectionInput.identifier) !== null && _a !== void 0 ? _a : uuidv4(),
        inspection_type: (_b = inspectionInput.inspection_type) !== null && _b !== void 0 ? _b : InspectionType.AFTER_RENT,
        language: (_c = inspectionInput.language) !== null && _c !== void 0 ? _c : DocumentLanguage.NL,
        name: (_d = inspectionInput.name) !== null && _d !== void 0 ? _d : "",
        root_node: (_g = (_e = inspectionInput.root_node) !== null && _e !== void 0 ? _e : (_f = inspectionInput.template) === null || _f === void 0 ? void 0 : _f.root) !== null && _g !== void 0 ? _g : newInspectionNode("", []),
        costing_system: (_h = inspectionInput.costing_system) !== null && _h !== void 0 ? _h : InspectionCostingSystem.SPLIT,
        object_type: (_l = (_j = inspectionInput.object_type) !== null && _j !== void 0 ? _j : (_k = inspectionInput.template) === null || _k === void 0 ? void 0 : _k.name) !== null && _l !== void 0 ? _l : "",
        start_date: (_m = inspectionInput.start_date) !== null && _m !== void 0 ? _m : null,
        terms: (_o = inspectionInput.terms) !== null && _o !== void 0 ? _o : defaultTerms,
        remarks: (_p = inspectionInput.remarks) !== null && _p !== void 0 ? _p : [],
        party1: (_q = inspectionInput.party1) !== null && _q !== void 0 ? _q : {
            name: "",
            role: "",
            address: defaultAddress,
            representative: ""
        },
        party2: (_r = inspectionInput.party2) !== null && _r !== void 0 ? _r : {
            name: "",
            role: "",
            address: defaultAddress,
            representative: ""
        },
        extra_parties: (_s = inspectionInput.extra_parties) !== null && _s !== void 0 ? _s : [],
        signature_executor: (_t = inspectionInput.signature_executor) !== null && _t !== void 0 ? _t : null,
        signature_party_1: (_u = inspectionInput.signature_party_1) !== null && _u !== void 0 ? _u : null,
        signature_party_2: (_v = inspectionInput.signature_party_2) !== null && _v !== void 0 ? _v : null,
        extra_signatures: (_w = inspectionInput.extra_signatures) !== null && _w !== void 0 ? _w : []
    };
}
