import { Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle } from "@mui/material"
import { useLanguage } from "inspector-common/lib/language";

interface DeleteDialogProps {
    open: boolean
    setOpen: (value: boolean) => void
    delete: () => void
};

function DeleteDialog(props: DeleteDialogProps) {
    const language = useLanguage();
    const handleClose = () => props.setOpen(false);

    return <Dialog
        open={props.open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
    >
        <DialogTitle id="alert-dialog-title">
            {language.generic.delete}
        </DialogTitle>
        <DialogContent>
            <DialogContentText id="alert-dialog-description">
                {language.generic.confirmDeletion}
            </DialogContentText>
        </DialogContent>
        <DialogActions>
            <Button onClick={() => {
                props.delete(); 
                handleClose();
            }} variant="contained">{language.generic.confirm}</Button>
            <Button onClick={handleClose}>{language.generic.cancel}</Button>
        </DialogActions>
    </Dialog>
}

export default DeleteDialog;