import { Box } from "@mui/material";
import { IPath } from "inspector-common/lib/model/inspection/InspectionImage";
import { useEffect, useRef, useState } from "react";
import { drawImage } from "../../inspection/functions/draw";

interface ImageFromReportProps {
    url: string
    imageId: string
    rotation: number
    paths?: IPath[]
};

export default function ImageFromReport(props: ImageFromReportProps) {
    const [width, setWidth] = useState(0);
    const [height, setHeight] = useState(0);
    const canvasRef = useRef<HTMLCanvasElement>(null);
    const isRotated = [90, 270, -90, -270].includes(props.rotation ?? 0);

    useEffect(() => {
        
        if (canvasRef.current) {
            const canvas = canvasRef.current;
            const context = canvas.getContext("2d");
            context?.clearRect(0, 0, isRotated ? height : width, isRotated ? width : height);
            const element: HTMLImageElement = document.getElementById(props.imageId ?? "") as any;
            const draw = () => drawImage(canvas, element, width, height, props.paths ?? [], props.rotation ?? 0)

            element.onload = draw
            draw();

        }
    }, [props.url, props.paths, canvasRef.current, props.rotation])

    return <Box>
    <canvas
        ref={canvasRef}
        height={height}
        width={width}
        id={`canvas-${props.imageId}`}
    >
        <img 
            id={props.imageId}
            alt={"LOCI Expert " + (props.imageId ?? "")}
            crossOrigin="anonymous"
            src={props.url}
            onLoad={(event) => {
                const img: HTMLImageElement = document.getElementById(props.imageId ?? "") as HTMLImageElement;
                
                setWidth(img.width);
                setHeight(img.height );
            }}
        />
    </canvas>
</Box>
}
