import { Title } from "../../../components";
import { useLanguage } from "inspector-common/lib/language"

interface UserTitleProps {};

function UserTitle(props: UserTitleProps) {
    const label = useLanguage().panels.users;
    return <Title label={label} />
}

export default UserTitle;