import { useLanguage } from "inspector-common/lib/language"
import { TextField, TextFieldContainer } from "../../../../components"

interface InfoInputProps {
    info: string
    setInfo: (info: string) => void
}

export default function InfoInput(props: InfoInputProps) {
    const language = useLanguage();

    return <TextFieldContainer>
        <TextField
            label={language.assignments.info}
            value={props.info}
            onChange={(event) => {
                props.setInfo(event.target.value)
            }}
        />
    </TextFieldContainer>
}