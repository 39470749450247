import { FormControl, InputLabel, MenuItem, Select } from "@mui/material";
import { SetStateAction, useEffect, useMemo, useState } from "react";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode, Template } from "inspector-common/lib/model";

interface RoomSelectProps {
    template: Template | null
    selectedRoom: InspectionNode | null
    setSelectedRoom: (node: InspectionNode | null) => void
}

function flatten(node: InspectionNode): InspectionNode[] {
    const childNodes = node.children.map(flatten).reduce((acc, value) => acc.concat(value), []);
    if (node.is_root) {
        return [...childNodes]
    } else {
        return [
            node,
            ...childNodes
         ]
    }
}
function RoomSelect(props: RoomSelectProps) {
    const language = useLanguage();
    const rooms = useMemo(() => {
        if (props.template) {
            return flatten((props.template as Template).root)
        } else {
            return []
        }
    }, [props.template?.identifier])
    const [nodeIndex, setNodeIndex] = useState<number | null>(null);

    useEffect(() => {
        console.log(nodeIndex)
        if (nodeIndex !== null) {
            props.setSelectedRoom(rooms[nodeIndex] ?? null)
        } else {
            props.setSelectedRoom(null)
        }
    }, [nodeIndex])

    return <FormControl fullWidth>
        <InputLabel id="template-select">{language.document.template}</InputLabel>
        <Select
            labelId="template-select"
            value={nodeIndex ?? ""}
            label={language.document.template}
            onChange={(event: any) => {
                if (event.target.value !== "") {
                    setNodeIndex(event.target.value);
                } else {
                    setNodeIndex(null)
                }
            }}
        >
            <MenuItem value={""}>
                {language.document.nullTemplate}
            </MenuItem>
            {(rooms ?? []).map((node: InspectionNode, index) => (
                <MenuItem key={index} value={index}>
                    {node.name}
                </MenuItem>
            ))}

        </Select>
    </FormControl>
}

export default RoomSelect;