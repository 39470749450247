import { DatePicker } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";

interface PlanDateInputProps {
    planDate: Date
    setPlanDate: (value: Date) => void
};

function PlanDateInput(props: PlanDateInputProps) {
    const label = useLanguage().assignments.attributes.planDate
    
    return <DatePicker 
        label={label} 
        value={props.planDate} 
        onChange={(newValue: Date | null) => props.setPlanDate(newValue ?? props.planDate)} 
    />
};

export default PlanDateInput;