import { Button } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { useState } from "react";

interface ChangePasswordButtonProps {
    changePassword: () => Promise<void>
};

export default function LoginButton(props: ChangePasswordButtonProps) {
    const language = useLanguage();
    const [disabled, setDisabled] = useState(false);

    return <Button 
        onClick={() => {
            setDisabled(true);
            props.changePassword().then(() => setDisabled(false))
        }} 
        variant="contained"
        disabled={disabled}>
            {language.generic.confirm}
    </Button>
}