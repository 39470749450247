import { Box, List } from "@mui/material";
import { Spinner } from "../../../../components";
import { Assignment } from "inspector-common/lib/model";
import { useAssignmentListState } from "../../../../state";
import AssignmentListItem from "./AssignmentListItem";

interface AssignmentsForDayProps {
    assignments: Array<Assignment>
};

function AssignmentsForDay(props: AssignmentsForDayProps) {
    const { isLoading } = useAssignmentListState();

    return <Box sx={{display: "flex", flexDirection: "column"}}>
        {isLoading ? <Spinner /> : <List >
            {
                props.assignments.map((assignment: Assignment) => (
                    <AssignmentListItem key={assignment.identifier} assignment={assignment} />
                ))
            }
        </List>}
    </Box>
};

export default AssignmentsForDay;