import { Box, Button, Container, IconButton, Stack, TextField, Typography } from "@mui/material";
import { DeleteDialog, Spinner } from "../../../components";
import { useLanguage } from "inspector-common/lib/language";
import { useEffect, useState } from "react";
import { DefaultTerms } from "inspector-common/lib/model/company/DefaultTerms";
import { useDeleteCompanyTerms, useGetCompanyTerms, useListCompanyTerms, useUpdateCompanyTerms } from "inspector-common/lib/api/hooks";
import { InspectionTerms } from "inspector-common/lib/model/inspection/InspectionTerms";
import TermsSelector from "../components/TermsSelector";
import { GetCompanyTermsResponse, ListCompanyTermsResponse } from "inspector-common/lib/api/calls";
import TermsEditor from "../components/TermsEditor";
import NoTermsPlaceholder from "../components/NoTermsPlaceholder";
import { Delete } from "@mui/icons-material";

interface EditTermsPageProps {

};

export default function EditTermsPage(props: EditTermsPageProps) {
    const language = useLanguage();

    const [termsList, setTermsList] = useState<Array<DefaultTerms>>([]);
    const [termsIdentifier, setTermsIdentifier] = useState<string | null>(null);
    const [defaultTerms, setDefaultTerms] = useState<DefaultTerms | null>(null);
    const { execute: listTerms, isLoading: isLoadingList } = useListCompanyTerms();
    const { execute: getTerms, isLoading } = useGetCompanyTerms();
    const { execute: deleteTerms, isLoading: isDeleting } = useDeleteCompanyTerms();
    const { execute: updateTerms, isLoading: isUpdating } = useUpdateCompanyTerms();
    const [deleteOpen, setDeleteOpen] = useState(false);

    useEffect(() => {
        listTerms({}).then((response: ListCompanyTermsResponse | null) => {
            if (response?.terms) {
                setTermsList(response.terms);
                if (response.terms.length > 0) {
                    setTermsIdentifier(response.terms[0].identifier)
                }
            }
        })
    }, [])

    useEffect(() => {
        if (termsIdentifier) {
            getTerms({identifier: termsIdentifier}).then((response: GetCompanyTermsResponse | null) => {
                if (response?.terms) {
                    setDefaultTerms(response?.terms)
                }
            })
        } else {
            setDefaultTerms(null);
        }
    }, [termsIdentifier])

    if (isLoadingList || isLoading || isUpdating || isDeleting) {
        return <Container><Spinner /></Container>
    };

    if (!termsList.length) {
        return <Container>
            <NoTermsPlaceholder />
        </Container>
    }

    if (!defaultTerms) {
        return <Container>
            <NoTermsPlaceholder />
        </Container>
    }

    const terms = defaultTerms.terms;
    const setTerms = (terms: InspectionTerms) => {
        setDefaultTerms({...defaultTerms, terms})
    };

    return <Container>
        <Stack direction={"column"} spacing={4}>
            <Typography variant="h4">{language.generic.edit}</Typography>

            <TermsSelector terms={termsList} selectedIdentifier={termsIdentifier} setSelectedIdentifier={setTermsIdentifier}/>
            <Typography variant="h6">{language.document.terms.name}</Typography>
            <Stack direction="row" spacing={2} width="100%">

                <TextField 
                    value={defaultTerms.name} 
                    sx={{display: "flex", width: "100%"}}
                    onChange={(event: any) => {setDefaultTerms({...defaultTerms, name: event.target.value})}}
                    label={language.document.terms.name}
                />
                <IconButton onClick={() => setDeleteOpen(true)}>
                    <Delete/>
                </IconButton>
                <DeleteDialog 
                    delete={() => {
                        if (termsIdentifier) {
                            deleteTerms({identifier: termsIdentifier}).finally(() => setTermsIdentifier(null))
                        }
                    }}
                    open={deleteOpen}
                    setOpen={setDeleteOpen}
                />
            </Stack>
            <TermsEditor terms={terms} setTerms={setTerms} />
            <Box><Button variant="contained" onClick={() => updateTerms({
                terms: defaultTerms
            })}>{language.generic.save}</Button></Box>
        </Stack>
    </Container>
}
