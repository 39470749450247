import { Box, BoxProps, Button } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { useState } from "react";

interface LoginButtonProps {
    onClick: () => Promise<void>
};

const boxProps: BoxProps = {
    sx: {
        paddingLeft: "24px",
        paddingRight: "24px",
        paddingTop: "36px",
        paddingBottom: "36px"
    },
    display: "flex",
    flexDirection: "row",
    alignItems: "center"
}

function LoginButton(props: LoginButtonProps) {
    const label = useLanguage().users.login;
    const [loading, setLoading] = useState(false);

    return <Box {...boxProps}>
        <Button variant="contained" fullWidth disabled={loading} onClick={
            () => {
                setLoading(true);
                props.onClick().finally(() =>setLoading(false))
            }
        }>{label}</Button>
    </Box>
};

export default LoginButton;