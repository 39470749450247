import { ContainerProps, Container } from "@mui/material";

interface TemplateOverviewContainerProps extends ContainerProps {};

const defaults: ContainerProps = {
    sx: {
        display: "flex",
        flexDirection: "column",
        maxWidth: null
    }
}

function TemplateOverviewContainer(props: TemplateOverviewContainerProps) {
    return <Container {...{...defaults, ...props}} />
};

export default TemplateOverviewContainer;