import TransactionType from "../../../model/credits/TransactionType";
export var mockGetCreditBalance = {
    balance: {
        amount: 5,
        last_updated: new Date().toISOString()
    }
};
export var mockGetTransactions = {
    transactions: [
        {
            identifier: "T1",
            user_id: "U1",
            credits_bought: 4,
            price: 0,
            timestamp: "2024-01-15T01:02:03.094442",
            transaction_type: TransactionType.REGISTRATION,
            assignment_id: null,
            inspection_id: null
        },
        {
            identifier: "T2",
            user_id: "U1",
            credits_bought: 0,
            price: 0,
            timestamp: "2024-01-15T01:42:03.094442",
            transaction_type: TransactionType.USE,
            assignment_id: "A1",
            inspection_id: "I1"
        },
        {
            identifier: "T3",
            user_id: "U1",
            credits_bought: 2,
            price: 50,
            timestamp: "2024-01-16T08:22:03.094442",
            transaction_type: TransactionType.PURCHASE,
            assignment_id: null,
            inspection_id: null
        }
    ]
};
