import { TextField, TextFieldContainer } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";

interface NameTextFieldProps {
    name: string
    setName: (value: string) => void
};

function NameTextField(props: NameTextFieldProps) {
    const language = useLanguage();
    const labelNL = language.generic.nameNL;
    const labelFR = language.generic.nameFR;

    return <>
        <TextFieldContainer>
            <TextField value={props.name} onChange={(event: any) => props.setName(event.target.value)} label={labelNL}/>
        </TextFieldContainer>
    </>
};

export default NameTextField;