import { TableCell, TableRow } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import Transaction from "inspector-common/lib/model/credits/Transaction";
import { getTimestampString } from "inspector-common/lib/utils/utils";
import TransactionAmount from "./TransactionAmount";
import TransactionPrice from "./TransactionPrice";

interface TransactionListRowProps {
    transaction: Transaction
};

export default function TransactionListRow(props: TransactionListRowProps) {
    const language = useLanguage();
    return <TableRow>
        <TableCell>{getTimestampString(props.transaction.timestamp)}</TableCell>
        <TableCell>{language.credits.transactionType[props.transaction.transaction_type]}</TableCell>
        <TableCell><TransactionAmount transaction={props.transaction} /></TableCell>
        <TableCell><TransactionPrice transaction={props.transaction} /></TableCell>
    </TableRow>
}