import { FormControl, InputLabel, MenuItem, Select } from "@mui/material"
import { TextFieldContainer } from "../../../../components"
import { useLanguage } from "inspector-common/lib/language"
import { InspectionNode } from "inspector-common/lib/model"

interface CopyTemplateNodeSelectProps {
    parent: InspectionNode
    selectedCopyNodeIndex: number | null
    setSelectedCopyNodeIndex: (value: number | null) => void
};

function CopyTemplateNodeSelect(props: CopyTemplateNodeSelectProps) {
    const language = useLanguage();
    const onChange = (event: any) => props.setSelectedCopyNodeIndex(
        event.target.value === -1 ? null : event.target.value
    );

    return <TextFieldContainer>
        <FormControl fullWidth>
            <InputLabel id="copy-template-select">{language.templates.copyFrom}</InputLabel>
            <Select
                labelId="copy-template-select"
                value={props.selectedCopyNodeIndex ?? ""}
                label={language.templates.copyFrom}
                onChange={onChange}
            >
                <MenuItem value={-1}>{language.templates.noCopy}</MenuItem>
                {(props.parent.children).map((node: InspectionNode, index: number) => <MenuItem key={index.toString()} value={index}>{node.name}</MenuItem>)}
            </Select>
        </FormControl>
    </TextFieldContainer>
};

export default CopyTemplateNodeSelect;