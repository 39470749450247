import { useEffect } from "react";
import { atom, RecoilState, selector, useRecoilState } from "recoil";
import { GetAssignmentsResponse } from "inspector-common/lib/api/calls";
import { useGetAssignments } from "inspector-common/lib/api/hooks";
import { Assignment } from "inspector-common/lib/model";
import client from "inspector-common/lib/api/client";
import { useAuth } from "../auth";

export interface AssignmentListState {
    initiallyLoaded: boolean
    isLoading: boolean
    assignments: Array<Assignment>
    error?: Error | null
    refresh: () => any
};

const defaultAssignmentListState: AssignmentListState = {
    initiallyLoaded: false,
    isLoading: true,
    assignments: [],
    refresh: () => {}
};

export const assignmentListState: RecoilState<AssignmentListState> = atom({
    key: "assignmentListState",
    default: defaultAssignmentListState
});

export const selectAssignmentListState = selector({
    key: "selectAssignmentList",
    get: ({ get }) => {
        return get(assignmentListState);
    }
});

export const useAssignmentListState = (archived?: boolean) => {
    const auth = useAuth();
    const {
        execute
    } = useGetAssignments();

    const [state, setState] = useRecoilState(assignmentListState);

    useEffect(() => {
        if (client.token) {
            if (! state.initiallyLoaded) {
                setState({...state, isLoading: true})
                execute({archived: archived ?? false}).then((response: GetAssignmentsResponse | null) => {
                    if (response) {
                        setState({
                            initiallyLoaded: true,
                            isLoading: false,
                            assignments: response.assignments ?? [],
                            refresh: () => setState({...state, initiallyLoaded: false})
                        })
                    } else {
                        setState({
                            initiallyLoaded: true,
                            isLoading: false,
                            assignments: [],
                            refresh: () => setState({...state, initiallyLoaded: false, isLoading: true})
                        })
                    }
                })
            }
        }
    
    }, [execute, state.initiallyLoaded, auth.config.idToken, archived])
    
    return state
}

export const useAssignmentList = () => {
    return useAssignmentListState().assignments;
};

export const useAssignment = (assignmentId: string | undefined | null): Assignment | null => {
    const assignmentList = useAssignmentList();
    
    for (var assignment of assignmentList) {
        if (assignment.identifier === assignmentId) {
            return assignment;
        }
    }
    return null;
};