var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import { AssignmentStatus } from "../../model";
import { createAssignment } from "../../model/assignment/create";
var today = new Date();
var tomorrow = new Date();
tomorrow.setDate(today.getDate() + 1);
export var mockAssignment = {
    identifier: "1",
    status: AssignmentStatus.PENDING,
    creation_date: today,
    address: {
        city: "Zoersel",
        street: "Kerkstraat",
        zip_code: "2980",
        number: "22",
        bus: "3"
    },
    reference: "KERK223",
    name: "Beschrijving Kerkstraat 22",
    plan_date: tomorrow,
    assignee: null,
};
export var mockAssignmentWithAssignee = __assign({ identifier: "2", status: AssignmentStatus.DONE, creation_date: today }, createAssignment({
    address: {
        city: "Zoersel",
        zip_code: "2980",
        street: "Monnikenheide",
        number: "6",
        bus: null
    },
    reference: "KERK223",
    name: "Monnikenheide 6",
    plan_date: today,
    assignee: "df0dc18d-0c29-40cc-8a33-b58936b20f77",
}));
