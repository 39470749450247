import "./CreateButton.css";
import { ButtonProps, Button } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { TextFieldContainer } from "../../../../components";

interface CreateAssignmentButtonProps extends ButtonProps {
    loading: boolean
};

function CreateAssignmentButton(props: CreateAssignmentButtonProps) {
    const label = useLanguage().assignments.create;
    const {loading, ...buttonProps} = props;
    return <TextFieldContainer>
            <Button 
            className="create-assignment-button" 
            variant="outlined" 
            disabled={loading}
            {...buttonProps} >
                {label}
            </Button>
        </TextFieldContainer>;
};

export default CreateAssignmentButton;