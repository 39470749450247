import { FormControl, MenuItem, Select } from "@mui/material"
import { useLanguage } from "inspector-common/lib/language"
import { Role } from "inspector-common/lib/model"

interface RoleSelectProps {
    isAdmin: boolean
    role: Role
    setRole: (value: Role) => void
};

function RoleSelect(props: RoleSelectProps) {
    const language = useLanguage();
    const selectableRoles = props.role === Role.APP_ADMIN ? Object.values(Role) : [Role.ADMIN, Role.USER, Role.ON_HOLD];
    return props.isAdmin ? <>
        <FormControl fullWidth>
            <Select
                value={props.role}
                id="role"
                label=""
                onChange={(event: any) => props.setRole(event.target.value)}
                fullWidth
            >
                {
                    selectableRoles.map((role: Role) => <MenuItem key={role} value={role}>
                        {language.users.roles[role]}
                    </MenuItem>)
                }
            </Select>
        </FormControl>
    </> : <>{language.users.roles[props.role]}</>
};

export default RoleSelect;
