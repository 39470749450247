import { Dialog, DialogContent, DialogTitle, Link, Stack, Typography } from "@mui/material";
import { Assignment, Inspection } from "inspector-common/lib/model";
import { useEffect, useState } from "react";
import { generateReport } from "../functions/generateReport";
import { useAuth } from "../../../auth";
import { useCompany } from "../../company/hooks/company";
import { useLanguage } from "inspector-common/lib/language";
import { Spinner } from "../../../components";
import { Document, Packer } from "docx";
import { useConvertToPDF, useGetDocumentUploadDetails } from "inspector-common/lib/api/hooks";
import { GetDocumentUploadDetailsResponse } from "inspector-common/lib/api/calls";
import { saveAs } from "file-saver";
import { useUserName } from "../../../state";
import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";

interface DocumentGenerationDialogProps {
    assignment: Assignment,
    inspection: Inspection,
    open: boolean,
    setOpen: (open: boolean) => void
};

const styles = {
    dialog: {

    },
    stack: {

    },
    loadMessage: {
        textAlign: "center"
    }
};

export default function DocumentGenerationDialog(props: DocumentGenerationDialogProps) {
    const language = useLanguage();

    const [loading, setLoading] = useState<boolean>(true);
    const [docxLink, setDocxLink] = useState<string | null>(null);
    const [pdfLink, setPdfLink] = useState<string | null>(null);
    const [currentStep, setCurrentStep] = useState<string>(language.document.downloadingImages);
    const [allSuccess, setAllSuccess] = useState<boolean>(true);

    const { execute: getDocumentUploadDetails } = useGetDocumentUploadDetails();
    const { convert } = useConvertToPDF();

    const auth = useAuth();
    const { company } = useCompany()
    const executor = auth.config.userFullName


    async function refresh() {
        setDocxLink(null);
        setPdfLink(null);
        if (props.open && company) {
            setLoading(true);
            return generateReport(
                company,
                props.assignment, 
                props.inspection,
                language,
                executor,
                props.inspection.language,
                setAllSuccess,
                (images: InspectionImage[]) => setCurrentStep(language.document.downloadingImages + ` (${images.length})`),
                () => setCurrentStep(language.document.generatingDocx)
            ).then((result: Document) => {
                return Packer.toBlob(result).then((blob) => {
                    const blobUrl = URL.createObjectURL(blob);
                    setDocxLink(blobUrl);
                    setCurrentStep(language.document.generatingPdf)
                    return {report: result, blob: blob};
                })
            })

        }
    };


    useEffect(
        () => {
            if (props.open && !!company) {
                refresh().then((result?: {report: Document, blob: Blob}) => {
                    if (result) {
                        return getDocumentUploadDetails({
                            assignment_id: props.assignment.identifier,
                            inspection_id: props.inspection.identifier
                        }).then((response: GetDocumentUploadDetailsResponse | null) => {
                            if (response?.docx_url) {
                                return fetch(response?.docx_url ?? "", {
                                    method: "PUT",
                                    body: result.blob,
                                    headers: {
                                        "Content-Type": "application/octet-stream"
                                    }
                                }).then(() => {
                                    return convert(props.inspection).then((pdf_link) => {
                                        if (pdf_link) {
                                            setPdfLink(pdf_link)
                                        }
                                    })
                                })
                            }
                        })
                    }
                }).finally(() => setLoading(false))
            }
            
        }, 
        [props.open, !!company]
    );

    return <Dialog onClose={() => props.setOpen(false)} open={props.open}>
        <DialogTitle>{language.document.generate}</DialogTitle>
        <DialogContent sx={styles.dialog}>
            <Stack direction="column" spacing={2}>
                {
                    loading ? <>
                        <Spinner />
                        <Typography sx={styles.loadMessage}>{language.document.generating}</Typography>
                        <Typography sx={styles.loadMessage}>{currentStep}</Typography>
                        
                    </> : <></>
                }
                {
                    !allSuccess ? <Typography sx={[styles.loadMessage, {color: "red"}]}>{language.inspection.errors.imagesNotLoaded}</Typography>
                    : <></>
                }
                {
                    docxLink ? <Link href={docxLink ?? ""} download={props.inspection.name + ".docx"}>
                        {language.document.downloadDocx}
                    </Link> : <></>
                }
                {   pdfLink ? 
                        <Link 
                            sx={{cursor: "pointer"}}
                            onClick={() => {
                                fetch(pdfLink ?? "").then((response) => {
                                    response.blob().then((blob) => saveAs(blob, props.inspection.name + ".pdf"))
                                })
                            }}
                        >
                            {language.document.downloadPDF}
                        </Link> : <></>
                }
            </Stack>
        </DialogContent>
    </Dialog>
}