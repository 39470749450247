import "./CreateTemplate.css";
import { useState } from "react";
import { CreateTemplateRequest, CreateTemplateResponse } from "inspector-common/lib/api/calls";
import { CreateTemplateButton } from "../buttons";
import CreateTemplateDialog from "./CreateTemplateDialog";
import { TemplateHeader } from "inspector-common/lib/model";

interface CreateTemplateProps {
    create: (request: CreateTemplateRequest) => Promise<CreateTemplateResponse | null>
    templates: Array<TemplateHeader>
};

function CreateTemplate(props: CreateTemplateProps) {
    const [dialogOpen, setDialogOpen] = useState<boolean>(false);

    return <>
        <CreateTemplateButton onClick={() => setDialogOpen(true)}/>
        <CreateTemplateDialog 
            open={dialogOpen}
            setOpen={setDialogOpen}
            create={props.create}
            templates={props.templates}
        />
    </>
}

export default CreateTemplate;