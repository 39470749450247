function formatDate(date) {
    return String(date.getDate()).padStart(2, "0") + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + String(date.getFullYear());
}
export function getDateString(date) {
    if (!date) {
        return "";
    }
    else if (typeof date === "string") {
        if (date.length === 10) {
            return date.replaceAll("-", "/");
        }
        return formatDate(new Date(Date.parse(date)));
    }
    else {
        return formatDate(date);
    }
}
;
export function formatTimestamp(date) {
    return String(date.getDate()).padStart(2, "0") + "/" + String(date.getMonth() + 1).padStart(2, "0") + "/" + String(date.getFullYear()) +
        " " + String(date.getHours()).padStart(2, "0") + ":" + String(date.getMinutes()).padStart(2, "0") + ":" + String(date.getSeconds()).padStart(2, "0");
}
export function getTimestampString(date) {
    if (!date) {
        return "";
    }
    else if (typeof date === "string") {
        return formatTimestamp(new Date(Date.parse(date)));
    }
    else {
        return formatTimestamp(date);
    }
}
;
export function formatAddress(address) {
    return "".concat(formatAddressLine1(address), ", ").concat(formatAddressLine2(address));
}
;
export function formatAddressLine1(address) {
    return "".concat(address.street, " ").concat(address.number).concat(address.bus ? "/" + address.bus : "");
}
;
export function formatAddressLine2(address) {
    return "".concat(address.zip_code, " ").concat(address.city);
}
