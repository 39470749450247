import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";
import { useLanguage } from "inspector-common/lib/language";
import { DashboardPanel } from "../types";
import themeColors from "../../../theme/themeColors";

interface DashboardMenuItemProps {
    item: DashboardPanel;
};

function DashboardMenuItem(props: DashboardMenuItemProps) {
    const navigate = useNavigate();
    const goToPage = () => navigate(props.item.path);

    return <Button 
            onClick={goToPage} 
            className="dashboard-menu-item"
            startIcon={props.item.icon}
            variant="contained"
            fullWidth
            sx={{
                justifyContent: "flex-start",
                backgroundColor: themeColors.primary.dark
            }}
        >
            {(useLanguage().panels as any)[props.item.name]}
        </ Button>
};

export default DashboardMenuItem;