import { InputAdornment } from "@mui/material";
import { useState } from "react";
import { TextField, TextFieldContainer } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

interface LoginPasswordProps {
    password: string
    setPassword: (value: string) => void
    label: string
};


function LoginPassword(props: LoginPasswordProps) {
    const [show, setShow] = useState(false);

    return <TextFieldContainer>
        <TextField 
            value={props.password}
            onChange={(event: any) => props.setPassword(event.target.value)}
            type={show ? undefined : "password"}
            label={props.label}
            InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {show ? <VisibilityIcon 
                        onClick={() => {setShow(false)}} 
                        cursor="pointer"
                    /> : <VisibilityOffIcon 
                        onClick={() => {setShow(true)}} 
                        cursor="pointer"
                    />}
                  </InputAdornment>
                ),
              }}
        />
    </TextFieldContainer>
};

export default LoginPassword;