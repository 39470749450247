import "./SignInContainer.css";
import { Box, BoxProps } from "@mui/material";

interface SignInContainerProps extends BoxProps {};

const defaultProps = {
    sx: {
        maxWidth: 800,
        minWidth: 600,
        margin: "auto"
    },
    display: "block",
};

function SignInContainer(props: SignInContainerProps) {
    return <Box className="sign-in-container" {...{...props, ...defaultProps}} />
}

export default SignInContainer;