var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Paragraph, SectionType, TextRun } from "docx";
import { createSubtitle } from "./subtitle";
export function createIntroPage(config) {
    return {
        properties: {
            type: SectionType.NEXT_PAGE
        },
        children: __spreadArray([
            createSubtitle(config.labels.intro)
        ], config.intro.flatMap(function (p) { return p.split("\n").map(function (t) { return new Paragraph({
            children: [new TextRun({ text: t, italics: true })],
        }); }); }), true)
    };
}
