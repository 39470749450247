import "./InspectionInput.css";
import { Spinner, TextFieldContainer } from "../../../../../components";
import { Inspection } from "inspector-common/lib/model";
import InspectionListItem from "./InspectionListItem";
import { Button, Stack, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Add } from "@mui/icons-material";
import { useNavigate } from "react-router-dom";

interface InspectionInputProps {
    assignmentId: string
    inspections: Array<Inspection>
    setInspections: (inspections: Array<Inspection>) => void
    isLoading: boolean
}

function InspectionInput(props: InspectionInputProps) {
    const language = useLanguage();
    const navigate = useNavigate();
    
    return <TextFieldContainer>
        <Stack direction="column" spacing={2}>
        <Typography variant="h5">{language.assignments.attributes.requiredDocuments}</Typography>
        {
            props.isLoading ? <Spinner /> : (
                <>
                    {
                        props.inspections.length === 0 ? <Typography>{language.document.notAvailable}</Typography> : null
                    }
                    {
                        props.inspections.map((inspection: Inspection, index: number) => {
                            return <InspectionListItem 
                                key={inspection.identifier} 
                                inspection={inspection} 
                                assignmentId={props.assignmentId}
                                allInspections={props.inspections}
                            />
                        })
                    }
                </>
            )
        }
        
        <Button 
            variant="text" 
            onClick={() => {

                navigate("/assignments/edit/" + props.assignmentId + "/newInspection/")
            }}
            startIcon={<Add />}
        >
            <Typography>{language.assignments.addDocument}</Typography>
        </Button>
        </Stack>
    </TextFieldContainer>
};

export default InspectionInput;