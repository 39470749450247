import { FormControl, InputLabel, MenuItem, Select, Stack, TextField } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Inspection } from "inspector-common/lib/model";
import { InspectionType } from "inspector-common/lib/model/inspection/InspectionType";

interface InspectionTypeSelectProps {
    inspection: Inspection
    setInspection: (inspection: Inspection) => void
};

const styles = {
    picker: {
        width: "30%"
    },
    textField: {
        width: "70%"
    },
    stack: {
        alignItems: "center"
    }
}

export default function InspectionTypeSelect(props: InspectionTypeSelectProps) {
    const language = useLanguage();
    
    return <Stack direction="row" sx={styles.stack} spacing={2}>
        <FormControl sx={styles.picker}>
        <InputLabel>{language.inspection.inspectionType.label}</InputLabel>

            <Select
                value={Object.values(InspectionType).includes(props.inspection.inspection_type as any) ? props.inspection.inspection_type : language.inspection.inspectionType.OTHER}
                label={language.inspection.inspectionType.label}
                onChange={(event: any) => {
                    props.setInspection({
                        ...props.inspection,
                        inspection_type: event.target.value === language.inspection.inspectionType.OTHER ? "" : event.target.value
                    });

                }}
            >
                {
                    Object.values(InspectionType).map((value: InspectionType) => {
                        return <MenuItem 
                            key={language.inspection.inspectionType[value as InspectionType]}
                            value={value} 
                        >{language.inspection.inspectionType[value as InspectionType]}</MenuItem>
                    })
                }
                <MenuItem value={language.inspection.inspectionType.OTHER}>
                    {language.inspection.inspectionType.OTHER}
                </MenuItem>
            </Select>
        </FormControl>
        {
            Object.values(InspectionType).includes(props.inspection.inspection_type as InspectionType) ? <></> : <>
                <TextField 
                    placeholder={language.inspection.inspectionType.label}
                    label={language.inspection.inspectionType.label}
                    variant="outlined" 
                    sx={styles.textField}
                    value={props.inspection.inspection_type} 
                    onChange={(event: any) => {
                        props.setInspection({...props.inspection, inspection_type: event?.target.value});
                    }}
                />
            </>
        }
    </Stack>
}