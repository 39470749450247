import { Add, ArrowDownward, ArrowUpward, CopyAll, Delete, ExpandLess, ExpandMore, MoveDownOutlined } from "@mui/icons-material";
import { IconButton, Stack, TextField, Tooltip, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode, Template } from "inspector-common/lib/model";
import { DeleteDialog } from "../../../components";
import { useState } from "react";
import AddInspectionNodeDialog from "./AddInspectionNodeDialog";

interface RoomHeaderProps {
    path: number[]
    node: InspectionNode
    updateThisNode: (node: InspectionNode) => void
    deleteThisNode: () => void
    templates: Template[]
    collapsed: boolean
    setCollapsed: (value: boolean) => void
    moveNodeUp: () => void
    moveNodeDown: () => void
    moveNode: () => void
    addNode: (node: InspectionNode) => void
};

const styles = {
    titleStack: {
        alignItems: "center"
    },
    mainStack: {
        alignItems: "center",
        justifyContent: "space-between"
    }
}

export default function RoomHeader(props: RoomHeaderProps) {
    const language = useLanguage();
    const number = props.path.map((n: number) => n + 1).join(".")

    const [deleteOpen, setDeleteOpen] = useState(false);
    const [addOpen, setAddOpen] = useState(false);

    const titleStyle = {
        fontSize: [24, 24, 20, 16][props.path.length] ?? 16
    };

    return <Stack direction="row" sx={styles.mainStack}>
        <Stack direction="row" spacing={1} sx={styles.titleStack}>
            <IconButton onClick={() => props.setCollapsed(!props.collapsed)} >
                {
                    props.collapsed ? <ExpandLess sx={{color: "black"}}/> : <ExpandMore sx={{color: "black"}}/>
                }
            </IconButton>
            <Typography sx={titleStyle}>{number}</Typography>
            <TextField 
                InputProps={{
                    sx: titleStyle as any
                }}
                variant="standard"
                value={props.node.name}
                onChange={(event: any) => props.updateThisNode({...props.node, name: event.target.value})}
            />
        </Stack>

        <Stack direction={"row"} sx={styles.titleStack}>
            <Tooltip title={language.inspection.addRoom} >
                <IconButton onClick={() => setAddOpen(true)}>
                    <Add />
                </IconButton>
            </Tooltip>
            <Tooltip title={language.inspection.copy} >
                <IconButton onClick={() => props.addNode(props.node)}>
                    <CopyAll />
                </IconButton>
            </Tooltip>
            <Tooltip title={language.inspection.moveNode} >
                <IconButton onClick={() => props.moveNode()}>
                    < MoveDownOutlined/>
                </IconButton>
            </Tooltip>
            <IconButton onClick={() => props.moveNodeUp()}>
                <ArrowUpward />
            </IconButton>
            <IconButton onClick={() => props.moveNodeDown()}>
                <ArrowDownward />
            </IconButton>
            <Tooltip title={language.generic.delete} >
                <IconButton onClick={() => setDeleteOpen(true)}>
                    <Delete />
                </IconButton>
            </Tooltip>
            <DeleteDialog open={deleteOpen} setOpen={setDeleteOpen} delete={props.deleteThisNode} />
            <AddInspectionNodeDialog
                node={props.node}
                updateNode={props.updateThisNode}
                open={addOpen}
                setOpen={setAddOpen}
                templates={props.templates}
            />
        </Stack>
    </Stack>
}