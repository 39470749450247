import { Logo } from "../../../components";
import { LogoutButton, ProfilePageContainer, ProfilePageTitle, UserInfo } from "../components";

interface ProfilePageProps {};

function ProfilePage(props: ProfilePageProps) {
    return <ProfilePageContainer>
        <Logo width={200} height={120}/>
        <ProfilePageTitle />
        <UserInfo />
        <LogoutButton />
    </ProfilePageContainer>
}

export default ProfilePage;