import { IRoute } from './types';
import { Navigate } from 'react-router-dom';
import { DashboardPanel, Dashboard } from '../features/dashboard';
import { AssignmentOverview, CreateAssignmentPage, EditAssignmentPage } from '../features/assignments';
import { TemplateOverviewPage } from '../features/templates';
import { CalendarPage } from '../features/calendar';
import { UserPage } from '../features/users';
import TaskIcon from '@mui/icons-material/Task';
import CalendarMonthIcon from '@mui/icons-material/CalendarMonth';
import SupervisedUserCircleIcon from '@mui/icons-material/SupervisedUserCircle';
import ArticleIcon from '@mui/icons-material/Article';
import PersonIcon from '@mui/icons-material/Person';
import ApartmentIcon from '@mui/icons-material/Apartment';
import SummarizeIcon from '@mui/icons-material/Summarize';
import { useAuth } from '../auth';
import { SignIn } from '../features/auth';
import { ProfilePage } from '../features/profile';
import ChangePassword from '../features/auth/pages/ChangePassword';
import InspectionPage from '../features/inspection/pages/InspectionPage';
import { EditCompanyPage } from '../features/company';
import EditTermsPage from '../features/terms/pages/EditTermsPage';
import CreateTermsPage from '../features/terms/pages/CreateTermsPage';
import NewInspectionPage from '../features/inspection/pages/NewInspectionPage';
import ManageApplication from '../features/admin/pages/ManageApplication';
import CreditPage from '../features/credits/pages/CreditPage';
import PaidOutlinedIcon from '@mui/icons-material/PaidOutlined';
import BuyCreditsPage from '../features/credits/pages/BuyCreditsPage';
import RedirectPage from '../features/credits/pages/RedirectPage';
import ImagePreviewPage from '../features/inspection/pages/ImagePreviewPage';
import ResetPasswordPage from '../features/auth/pages/ResetPasswordPage';

const dashboardPanels: Array<DashboardPanel> = [
    new DashboardPanel("assignments", "/assignments", <TaskIcon />, <AssignmentOverview />),
    new DashboardPanel("templates", "/templates", <ArticleIcon />, <TemplateOverviewPage />),
    new DashboardPanel("users", "/users", <SupervisedUserCircleIcon />, <UserPage />),
    new DashboardPanel("calendar", "/calendar", <CalendarMonthIcon />, <CalendarPage />),
    new DashboardPanel("terms", "/terms", < SummarizeIcon/>, <EditTermsPage />),
    new DashboardPanel("profile", "/profile", <PersonIcon />, <ProfilePage />),
    new DashboardPanel("company", "/company", <ApartmentIcon />, <EditCompanyPage />),
    new DashboardPanel("credits", "/credits", <PaidOutlinedIcon />, <CreditPage />),
]

interface DashboardRouteProps {
    children: DashboardPanel
};

function DashboardRoute(props: DashboardRouteProps) {
    const { config } = useAuth();

    if (config.isAuthenticated === false && !config.isLoading) {
        return <Navigate to="/signin" />
    }
    return Dashboard({...props, items: dashboardPanels});
};

const dashboardRoutes: Array<IRoute> = dashboardPanels.map((panel: DashboardPanel) => {
    return {
        path: panel.path,
        component: <DashboardRoute>{panel}</DashboardRoute>
    };
});

const others: Array<IRoute> = [
    {
        path: "/",
        component: <Navigate to="/assignments"/>
    },
    {
        path: "/assignments/create",
        component: <DashboardRoute>{
            new DashboardPanel("createAssignment", "/assignments/create", <></>, <CreateAssignmentPage />)
        }</DashboardRoute>
    },
    {
        path: "/assignments/edit/:assignmentId/inspections/:inspectionId",
        component: <DashboardRoute>{
            new DashboardPanel("inspection", "/inspections", <></>, <InspectionPage />)
        }</DashboardRoute>
    },
    {
        path: "/assignments/edit/:assignmentId/newInspection",
        component: <DashboardRoute>{
            new DashboardPanel("inspection", "/inspections", <></>, <NewInspectionPage />)
        }</DashboardRoute>
    },
    {
        path: "/assignments/edit/:assignmentId",
        component: <DashboardRoute>{
            new DashboardPanel("editAssignment", "/assignments/edit", <></>, <EditAssignmentPage />)
        }</DashboardRoute>
    },
    {
        path: "/terms/create",
        component: <DashboardRoute>{
            new DashboardPanel("createTerms", "/terms/create", <></>, <CreateTermsPage />)
        }</DashboardRoute>
    },
    {
        path: "/credits/buy",
        component: <DashboardRoute>{
            new DashboardPanel("buyCredits", "/credits/buy", <></>, <BuyCreditsPage />)
        }</DashboardRoute>
    },
    {
        path: "/credits/redirect",
        component: <DashboardRoute>{
            new DashboardPanel("buyCredits", "/credits/redirect", <></>, <RedirectPage />)
        }</DashboardRoute>
    },
    {
        path: "/signin",
        component: <SignIn />
    },
    {
        path: "/change-password",
        component: <ChangePassword />
    },
    {
        path: "/reset-password",
        component: <ResetPasswordPage />,
    },
    {
        path: "/admin",
        component: <DashboardRoute>{
            new DashboardPanel("admin", "/admin", <></>, <ManageApplication />)
        }</DashboardRoute>
    },
    {
        path: "/image/:assignmentId/:inspectionId/:imageId",
        component: <ImagePreviewPage />
    },
    {
        path: "/*",
        component: <Navigate to="/" />
    }
]

export const routeConfigs: Array<IRoute> = dashboardRoutes.concat(others);
