import { Box } from "@mui/material";
import { Title } from "../../../../../components";
import { useLanguage } from "inspector-common/lib/language";

interface TemplateRoomInfoEvaluationsTitleProps {};

function TemplateRoomInfoEvaluationTitle(props: TemplateRoomInfoEvaluationsTitleProps) {
    const language = useLanguage();
    
    return <Box sx={{pt: 3, pl: "24px"}}>
        <Title variant="h6" label={language.templates.requiredEvaluations}/>
    </Box>
};

export default TemplateRoomInfoEvaluationTitle