import { Divider } from "@mui/material";
import { useState } from "react";
import { CreateTemplateRequest } from "inspector-common/lib/api/calls";
import { TemplateHeader } from "inspector-common/lib/model";
import { TemplateTableContainer } from "../container";
import TemplateEditor from "./TemplateEditor";
import TemplateList from "./TemplateList";
import { Spinner } from "../../../../components";

interface TemplateTableProps {
    templates: Array<TemplateHeader>
    refresh: () => void
    createTemplate: (request: CreateTemplateRequest) => Promise<any>
    isLoading: boolean
};

function TemplateTable(props: TemplateTableProps) {

    const [selectedTemplate, setSelectedTemplate] = useState<TemplateHeader | null>(null);

    if (props.isLoading) {
        return <Spinner />
    }
    return <TemplateTableContainer>
        <TemplateList 
            templates={props.templates || []}
            selectedTemplate={selectedTemplate}
            setSelectedTemplate={setSelectedTemplate}
            createTemplate={props.createTemplate}
        />
        <Divider orientation="vertical" flexItem/>
        <TemplateEditor template={selectedTemplate} refresh={props.refresh}/>
    </ TemplateTableContainer>
};

export default TemplateTable;