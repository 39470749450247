import { useState } from "react";
import RequestResetComponent from "../components/reset-password/RequestResetComponent";
import ResetPasswordComponent from "../components/reset-password/ResetPasswordComponent";

const PasswordResetPage = () => {
  const [isCodeSent, setIsCodeSent] = useState(false);
  const [email, setEmail] = useState("");

  const handleEmailSent = () => {
    setIsCodeSent(true);
  };

  return !isCodeSent ? (
        <RequestResetComponent
        onEmailSent={handleEmailSent}
        email={email}
        setEmail={setEmail}
        />
    ) : (
        <ResetPasswordComponent email={email} />
    )
};

export default PasswordResetPage;
