import { GetCompanyResponse } from "inspector-common/lib/api/calls";
import { useGetCompany } from "inspector-common/lib/api/hooks";
import { Company } from "inspector-common/lib/model";
import { useEffect, useState } from "react";

export function useCompany() {
    const [company, setCompany] = useState<Company | null>(null);
    const { execute: getCompany } = useGetCompany();

    useEffect(() => {
        getCompany({}).then((response: GetCompanyResponse | null) => {
            if (response?.company) {
                setCompany(response.company)
            }
        })
    }, [])

    return {
        company, setCompany
    }
}