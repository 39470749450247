import { ContainerProps, Container } from "@mui/material";

interface TemplateRoomInfoContainerProps extends ContainerProps {};

const defaults: ContainerProps = {
    sx: {
        display: "flex",
        flexDirection: "column",
        width: "50%",
        padding: 4
    }
}
function TemplateRoomInfoContainer(props: TemplateRoomInfoContainerProps) {
    return <Container {...{...defaults, ...props}} />
};

export default TemplateRoomInfoContainer;