import "./CreateButton.css";
import { ButtonProps, Button } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { TextFieldContainer } from "../../../../components";

interface CreateAssignmentButtonProps extends ButtonProps {
    loading: boolean
};

function CreateAssignmentButton(props: CreateAssignmentButtonProps) {
    const label = useLanguage().assignments.update;
    const {loading, ...buttonProps} = props;
    return <TextFieldContainer sx={{textAlign: "center"}}>
            <Button 
            className="update-assignment-button" 
            variant="contained" 
            disabled={loading}
            sx={{ maxWidth: "200px", margin: "auto"}}
            {...buttonProps} >
                {label}
            </Button>
        </TextFieldContainer>;
};

export default CreateAssignmentButton;