import { ExpandLess, ExpandMore } from "@mui/icons-material";
import { Box, Collapse, List, ListItem, ListItemButton, Typography } from "@mui/material";
import { InspectionNode } from "inspector-common/lib/model";
import { useState } from "react";
import { Add, MoveUp, MoveDown, Delete } from "./buttons";
import { useDrag, useDrop } from "react-dnd";
import { canDropF, getMoveIndex } from "../../../functions";
import { getChildNode } from "inspector-common/lib/utils/inspection";


interface TemplateRoomListItemProps {
    node: InspectionNode
    parent: InspectionNode
    parentIdentifier: Array<number>
    identifier: Array<number>
    addNode: (parentIdentifier: Array<number>, index: number, newNode: InspectionNode) => void
    removeNode: (identifier: Array<number>) => void
    updateNode: (identifier: Array<number>, node: InspectionNode) => void
    setSelectedNode: (node: InspectionNode, identifier: Array<number>) => void
    moveNode: (identifier: Array<number>, newIdentifier: Array<number>) => void
};

const styles = {
    container: {
        borderRadius: 1,
        borderColor: "grey",
        borderStyle: "solid",
        borderWidth: 1,
        marginY: 2,
        paddingX: 2
    }
}

function TemplateRoomListItem(props: TemplateRoomListItemProps) {
    const [expanded, setExpanded] = useState(false);
    const onClick = (event: any) => {
        event.stopPropagation();

        props.setSelectedNode(props.node, props.identifier);
        if (props.node.children.length > 0) {
            setExpanded(!expanded);
        }
    }
    const [{ isDragging }, drag] = useDrag(() => ({
        type: "node",
        item: {identifier: props.identifier},
        collect: (monitor) => ({
            isDragging: monitor.isDragging()
        }),
        end: () => {}
    }), [props.moveNode]) as any;

    const [{canDrop, isOverCurrent}, drop] = useDrop(() => ({
        accept: "node",
        drop: (item: any, monitor) => {
            if (!monitor.didDrop() && monitor.isOver({shallow: true}) && monitor.canDrop()) { 
                let index = getMoveIndex(props.node, props.identifier, monitor);

                props.moveNode(item.identifier, props.identifier.concat(index));
            }
        },
        canDrop: (item, monitor) => canDropF(item, monitor, props.identifier),
        collect: (monitor) => ({
            isOverCurrent: monitor.isOver({shallow: true}),
            canDrop: monitor.canDrop()
        }),
        end: () => {}
    }), [props.moveNode])

    const isActive = isOverCurrent && canDrop;

    return <ListItem disableGutters sx={[
                styles.container, 
                isActive ? {borderWidth: 3} : {borderWidth : 1},
                isDragging ? {backgroundColor: "#EEEEEE"} : {backgroundColor: undefined}
            ]} 
            id={`template-node-${props.identifier.toString()}`}
            ref={drag}
        >
        <ListItemButton 
            className="template-room-list-item-button"
            disableGutters
            sx={{paddingLeft: `${(props.identifier.length - 1) * 10}px`, cursor: "grab"}}
            ref={drop}
        >
            <Box 
                className="template-room-list-item"
                onClick={onClick}
                sx={{cursor: "pointer"}}
            >
                <Box className="template-room-list-item">
                    {props.node.children.length > 0 ? (expanded ? <ExpandLess /> : <ExpandMore />) : <></>}
                    <Typography className="template-room-list-item-button-label">{props.node.name}</Typography>
                </Box>
                <Box className="template-room-list-item template-room-list-item-buttons">
                    <Add 
                        addNode={props.addNode} 
                        parent={props.node} 
                        parentIdentifier={props.identifier}
                    />
                    <MoveUp 
                        updateNode={props.updateNode} 
                        identifier={props.identifier}
                        node={props.node}
                        parentIdentifier={props.parentIdentifier} 
                        parent={props.parent}
                    />
                    <MoveDown
                        updateNode={props.updateNode} 
                        identifier={props.identifier}
                        node={props.node}
                        parentIdentifier={props.parentIdentifier} 
                        parent={props.parent}
                    />
                    <Delete 
                        identifier={props.identifier}
                        removeNode={props.removeNode}
                    />
                </Box>
            </Box>
            <Collapse in={expanded} sx={{width: "100%"}}>
                <List >
                {
                    expanded ? 
                        props.node.children.map(
                            (node: InspectionNode, index: number) => <TemplateRoomListItem 
                                key={props.identifier.concat([index]).toString()} 
                                node={node} 
                                parent={props.node}
                                parentIdentifier={props.identifier}
                                identifier={props.identifier.concat([index])}
                                addNode={props.addNode}
                                removeNode={props.removeNode}
                                updateNode={props.updateNode}
                                setSelectedNode={props.setSelectedNode}
                                moveNode={props.moveNode}
                            />
                        ) : 
                        <></>
                }
                </List>
            </Collapse>
        </ListItemButton>
    </ListItem>
};

export default TemplateRoomListItem;
