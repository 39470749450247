import { ContainerProps, Container } from "@mui/material";

interface TemplateRoomEditorContainerProps extends ContainerProps {};

const defaults: ContainerProps = {
    sx: {
        display: "flex",
        flexDirection: "row",
    }
}
function TemplateRoomEditorContainer(props: TemplateRoomEditorContainerProps) {
    return <Container {...{...defaults, ...props}} />
};

export default TemplateRoomEditorContainer;