import { Cancel } from "@mui/icons-material";
import { FormControl, IconButton, InputLabel, MenuItem, Select, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { Inspection, InspectionNode } from "inspector-common/lib/model";
import { InspectionNodeState } from "inspector-common/lib/model/inspection/InspectionNodeState";

interface StateSelectorProps {
    node: InspectionNode
    updateNode: (node: InspectionNode) => void
};

export default function StateSelector(props: StateSelectorProps) {
    const language = useLanguage();
    const empty = language.inspection.state.label;

    return <>
        <Typography variant="h6">{language.inspection.state.label}</Typography>

        <FormControl>
            <InputLabel>{language.inspection.state.label}</InputLabel>
            <Select<InspectionNodeState | string>
                label={language.inspection.state.label}
                placeholder={language.inspection.state.label}
                value={props.node.state ?? language.inspection.state.label}
                onChange={(event) => {
                    const value: InspectionNodeState | null = event.target.value === empty ? null : (event.target.value as InspectionNodeState);
                    props.updateNode({...props.node, state: value})
                }}
            >
                <MenuItem value={empty}><Typography color="grey">{empty}</Typography></MenuItem>

                {
                    Object.values(InspectionNodeState).map((value: InspectionNodeState) => {
                        return <MenuItem key={value} value={value}>{language.inspection.state[value]}</MenuItem>
                    })
                }
            </Select>
        </FormControl>
    </>
}