import { Typography } from "@mui/material";
import Transaction from "inspector-common/lib/model/credits/Transaction";
import TransactionType from "inspector-common/lib/model/credits/TransactionType";

interface TransactionAmountProps {
    transaction: Transaction
};

export default function TransactionAmount(props: TransactionAmountProps) {
    return props.transaction.transaction_type === TransactionType.USE ? 
        <Typography color="#222222" fontWeight="bold">-1</Typography> : 
        <Typography color="green" fontWeight="bold">+{props.transaction.credits_bought}</Typography>

}