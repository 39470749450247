import { useEffect } from "react";
import { useGetTemplates } from "inspector-common/lib/api/hooks";
import { useCreateTemplate } from "inspector-common/lib/api/hooks/templates/useCreateTemplate";
import { TemplateOverviewContainer, TemplateOverviewTitle, TemplateTable } from "../components";
import { useAuth } from "../../../auth";
import { CreateTemplateRequest } from "inspector-common/lib/api/calls";

interface TemplateOverviewPageProps {};

function TemplateOverviewPage(props: TemplateOverviewPageProps) {
    const auth = useAuth();
    const {
        isLoading,
        data,
        execute: getTemplates
    } = useGetTemplates();

    const {
        execute: createTemplate
    } = useCreateTemplate();

    const refresh = () => {getTemplates({})};

    useEffect(() => {refresh()}, [getTemplates, auth.config.idToken])

    return <TemplateOverviewContainer>
        <TemplateOverviewTitle />        
        {
            <TemplateTable 
                createTemplate={(request: CreateTemplateRequest) => createTemplate(request).then(refresh)} 
                templates={data?.templates || []}
                refresh={refresh}
                isLoading={isLoading}
            />
        }
    </TemplateOverviewContainer>
};

export default TemplateOverviewPage;