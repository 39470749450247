import { CircularProgress, Table, TableBody, TableCell, TableContainer, TableHead } from "@mui/material";
import { useCompanies } from "../functions/hooks";
import CompanyListItem from "./CompanyListItem";
import { useState } from "react";
import SignInToCompanyDialog from "./SignInToCompanyDialog";
import { useLanguage } from "inspector-common/lib/language";
import { Company } from "inspector-common/lib/model";
import CompanyDetails from "./CompanyDetails";
import { DeleteDialog } from "../../../components";
import { useDeleteCompany } from "inspector-common/lib/api/hooks";

interface CompanyTableProps {};

const styles = {
    container: {
        marginY: 8,
        "& .MuiTableCell-head": {
            color: "white"
        }
    }
}

export default function CompanyTable(props: CompanyTableProps) {
    const language = useLanguage();
    const { companies, isLoading, refresh } = useCompanies();
    const { execute: deleteCompany } = useDeleteCompany();
    const [companySignInID, setCompanySignInID] = useState<null | string>(null);
    const [selectedCompany, setSelectedCompany] = useState<Company | null>(null);
    const [deleteCompanyID, setDeleteCompanyID] = useState<string | null>(null);

    return isLoading ? <CircularProgress /> : <>
        <CompanyDetails company={selectedCompany} setCompany={setSelectedCompany} />
        <TableContainer sx={styles.container}>
            <Table>
                <TableHead>
                    <TableCell></TableCell>
                    <TableCell>{language.company.subscriptionType}</TableCell>
                    <TableCell>{language.company.registrationTimestamp}</TableCell>
                    <TableCell></TableCell>
                </TableHead>
                <TableBody>
                    {
                        companies.map((company) => <CompanyListItem
                            company={company}
                            setCompanySignInID={setCompanySignInID}
                            key={company.identifier}
                            openCompanyDetails={setSelectedCompany}
                            setDeleteCompanyID={setDeleteCompanyID}
                        />)
                    }
                </TableBody>
            </Table>
            <SignInToCompanyDialog 
                company={companies.filter((c) => c.identifier === companySignInID).at(0)}
                open={companySignInID !== null} 
                close={() => setCompanySignInID(null)} 
            />
            <DeleteDialog 
                open={deleteCompanyID !== null} 
                setOpen={() => setDeleteCompanyID(null)}
                delete={() => {
                    if (deleteCompanyID) {
                        deleteCompany({identifier: deleteCompanyID}).then(refresh)
                    }
                }}
            />
        </TableContainer>
    </>
}