import { Box, Button, Container, Stack, Typography } from "@mui/material";
import { Spinner, TextField } from "../../../components";
import { useState } from "react";
import { useLanguage } from "inspector-common/lib/language";
import { DefaultTerms } from "inspector-common/lib/model/company/DefaultTerms";
import TermsEditor from "../components/TermsEditor";
import { InspectionTerms } from "inspector-common/lib/model/inspection/InspectionTerms";
import { useCreateCompanyTerms } from "inspector-common/lib/api/hooks";
import { useNavigate } from "react-router-dom";

interface CreateTermsPageProps {

};

export default function CreateTermsPage(props: CreateTermsPageProps) {
    const navigate = useNavigate();
    const [name, setName] = useState("");
    const language = useLanguage();
    const [terms, setTerms] = useState<DefaultTerms>({
        identifier: "",
        name: "",
        terms: {
            general: [],
            clauses: [],
            remarks: [],
            clause_cost_party: "",
            clause_cost_split: "",
            signature_text_header: "",
            signature_text_items: []
        }
    })

    const setInspectionTerms = (inspectionTerms: InspectionTerms) => setTerms({...terms, terms: inspectionTerms})
    const { execute: createTerms, isLoading } = useCreateCompanyTerms();

    if (isLoading) {
        return <Container><Spinner /></Container>
    }

    return <Container>
        <Stack direction="column" spacing={4}>
            <Typography variant="h4">{language.generic.new}</Typography>
            <TextField 
                value={name} 
                onChange={(event: any) => setName(event.target.value)}
                label={language.document.terms.name}
                placeholder={language.document.terms.placeholder}
            />
            <TermsEditor terms={terms.terms} setTerms={setInspectionTerms} />
            <Box><Button variant="contained" onClick={() => createTerms({
                terms: terms
            }).then(() => navigate("/terms"))}>{language.generic.save}</Button></Box>
        </Stack>
    </Container>
}