var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
import { Footer, HorizontalPositionRelativeFrom, ImageRun, PageNumber, Paragraph, Tab, TabStopPosition, TabStopType, TextRun, VerticalPositionRelativeFrom } from "docx";
import { createSubtitle } from "./subtitle";
export function createFooter(config, final) {
    var tabs = [
        {
            type: TabStopType.LEFT,
            position: Math.floor(TabStopPosition.MAX / 3)
        },
        {
            type: TabStopType.RIGHT,
            position: TabStopPosition.MAX
        }
    ];
    console.log("Logo: ", config.company.logoSmall);
    return {
        default: new Footer({
            children: [
                createSubtitle(""),
                new Paragraph({
                    children: __spreadArray(__spreadArray([], (config.company.logoSmall ? [
                        new ImageRun({
                            data: config.company.logoSmall,
                            transformation: {
                                width: 50,
                                height: 50
                            },
                            floating: {
                                horizontalPosition: {
                                    offset: 0,
                                    relative: HorizontalPositionRelativeFrom.COLUMN
                                },
                                verticalPosition: {
                                    offset: 0,
                                    relative: VerticalPositionRelativeFrom.LINE
                                }
                            }
                        })
                    ] : []), true), [
                        new TextRun({
                            children: [
                                new Tab(),
                                //config.company.name,
                                new Tab(),
                            ]
                        }),
                        new TextRun({
                            children: [
                                "".concat(config.labels.page, " "),
                                PageNumber.CURRENT,
                                " ".concat(config.labels.of, " "),
                                PageNumber.TOTAL_PAGES
                            ]
                        })
                    ], false),
                    tabStops: tabs
                }),
                new Paragraph({
                    children: [
                        new TextRun({
                            children: [
                                new Tab(),
                                //formatAddress(config.company.address),
                                new Tab(),
                            ]
                        })
                    ],
                    tabStops: tabs
                }),
                new Paragraph({
                    children: [
                        new TextRun({
                            children: [
                                new Tab(),
                                //`${config.company.phone} - ${config.company.vat}`,
                                new Tab(),
                                "".concat(config.labels.reference, ": ").concat(config.reference)
                            ]
                        })
                    ],
                    tabStops: tabs
                })
            ]
        }),
        first: new Footer({
            children: []
        })
    };
}
