import "./Spinner.css";
import { Box, IconButton } from "@mui/material";
import { Logo } from "../logo";

interface SpinnerProps {};

const rotation = {
    animation: "spin 2s linear infinite",
    "@keyframes spin": {
        "0%": {
            transform: "rotate(360deg)",
        },
        "100%": {
            transform: "rotate(0deg)",
        },
    },
    padding: 1,
    fontSize: 0
};

function Spinner(props: SpinnerProps) {
    return <Box className="spinner-box">
        <IconButton disableRipple className="icon-edge" sx={rotation}>
            <Logo width={48} height={48} small/>

        </IconButton>
    </Box>
}
export default Spinner;