import { Button, Dialog, DialogContent, DialogTitle, Stack, TextField } from "@mui/material"
import { useCreateUser } from "inspector-common/lib/api/hooks";
import { useLanguage } from "inspector-common/lib/language"
import { Role } from "inspector-common/lib/model";
import { useState } from "react";
import RoleSelect from "../table/RoleSelect";
import { Refresh } from "@mui/icons-material";

interface CreateUserFormProps {
    open: boolean
    setOpen: (open: boolean) => void
    refresh: () => void
};

export default function CreateUserForm(props: CreateUserFormProps) {
    const language = useLanguage();
    const { execute: createUser } = useCreateUser();

    const [first_name, setFirstName] = useState<string>("");
    const [last_name, setLastName] = useState<string>("");
    const [email, setEmail] = useState<string>("");
    const [password, setPassword] = useState<string>("");
    const [role, setRole] = useState<Role>(Role.USER);

    return <Dialog open={props.open} onClose={() => props.setOpen(false)}>
        <DialogTitle>{language.users.create}</DialogTitle>
        <DialogContent>
            <Stack direction="column" spacing={2} sx={{minWidth: "400px"}} p={2}>
                <TextField
                    label={language.users.firstName}
                    value={first_name}
                    onChange={(event: any) => {setFirstName(event.target.value)}}
                    fullWidth
                />
                <TextField
                    label={language.users.lastName}
                    value={last_name}
                    onChange={(event: any) => {setLastName(event.target.value)}}
                    fullWidth
                />
                <TextField
                    label={language.users.email}
                    value={email}
                    onChange={(event: any) => {setEmail(event.target.value)}}
                    fullWidth
                />
                <RoleSelect role={role} setRole={setRole} isAdmin={true} />
                <Stack direction="row">
                    <Button 
                        onClick={() => {
                            createUser({
                                user: {
                                    first_name, last_name, email, role
                                }
                            }).then((response) => {
                                if (response?.user) {
                                    props.refresh();
                                    props.setOpen(false);
                                }
                            })
                        }}
                        variant="contained"
                    >{language.generic.confirm}</Button>
                    
                </Stack>
            </Stack>
        </DialogContent>
    </Dialog>
}