import { Avatar, Button, Divider, Link, Menu, MenuItem, Stack, Typography } from "@mui/material"
import { useAuth } from "../../../auth"
import { useCompany } from "../../company/hooks/company";
import { useRef, useState } from "react";
import { useLanguage } from "inspector-common/lib/language";
import { useNavigate } from "react-router-dom";
import SubscriptionType from "inspector-common/lib/model/company/SubscriptionType";
import { useUpgradeSubscription } from "inspector-common/lib/api/hooks";

interface HeaderProps {}


const styles = {
    bar: {
        position: "sticky",
        top: 0,
        boxShadow: 12,
        alignItems: "center",
        justifyContent: "space-between",
        flexGrow: 1,
        display: "flex",
        mb: 2,
        px: 4,
        py: 2,
        opacity: 1,
        zIndex: 1,
        backgroundColor: "white"
    },
    freeTrialWarning: {
        alignItems: "center"
    },
    privacyLink: {
        textDecoration: "none",
        color: "black"
    }
}
export default function Header(props: HeaderProps) {
    const auth = useAuth();
    const { company } = useCompany();
    const ref = useRef(null);
    const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
    const open = Boolean(anchorEl);
    const language = useLanguage();
    const navigate = useNavigate();

    let expiration_date = (company ? new Date(company?.registration_timestamp) : new Date())
    expiration_date.setDate(expiration_date.getDate() + 30);
    const expirationDays = new Date().getDate() - expiration_date.getDate()

    const { execute, isLoading } = useUpgradeSubscription();

    const handleClick = (event: any) => {
        if (ref.current) {
            setAnchorEl(ref.current)
        }
      };
      const handleClose = () => {
        setAnchorEl(null);
      };

    return <Stack direction="row" sx={styles.bar}>
        {
            (company !== null && company.subscription_type === SubscriptionType.FREE) ? 
                <Stack direction="row" sx={styles.freeTrialWarning} spacing={2}>
                    <Typography>{
                        expirationDays > 0 ? language.credits.freeTrialExpiring(expirationDays) : language.credits.freeTrialExpired}
                    </Typography>
                    <Button variant="contained" onClick={() => {
                        return execute({}).then((response) => {
                            if (response?.redirect_url) {
                                window.location.href = response?.redirect_url
                            }
                        })
                    }} disabled={isLoading}>
                        {language.credits.upgradeSubscription}
                    </Button> 
                </Stack> : <Typography />
            
        }
        <Avatar onMouseEnter={handleClick}  ref={ref}/>
        <Menu
            id="basic-menu"
            anchorEl={anchorEl}
            open={open}
            onClose={handleClose}
            MenuListProps={{
                'aria-labelledby': 'basic-button',
            }}
        >
        <MenuItem onClick={() => {navigate("/profile"); handleClose()}}>{auth.config.username}</MenuItem>
        <MenuItem onClick={() => {navigate("/company"); handleClose()}}>{company?.name}</MenuItem>
        <Divider />
        <MenuItem><Link href="https://portal.loci.expert/#/privacy" sx={styles.privacyLink}>Privacy & Cookies</Link></MenuItem>
        <MenuItem><Link href="https://portal.loci.expert/#/terms" sx={styles.privacyLink}>Terms & Conditions</Link></MenuItem>
        <Divider />
        <MenuItem onClick={() => auth.signOut()}>{language.users.logout}</MenuItem>
      </Menu>
    </Stack>
}