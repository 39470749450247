import { Add, AddOutlined, Cancel } from "@mui/icons-material";
import { Box, Button, Divider, IconButton, Stack, TextField, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionNode } from "inspector-common/lib/model";


interface RemarksProps {
    node: InspectionNode
    setNode: (node: InspectionNode) => void
};

const styles = {
    title: {

    },
    iconBox: {
        alignItems: "center",
        textAlign: "center",
        width: "10%"
    },
    alignedStack: {
        alignItems: "center",
        width: "100%",
    },
    textBox: {
        flexGrow: 1
    }
};

export default function Remarks(props: RemarksProps) {
    const language = useLanguage();
    const addRemark = () => {
        props.setNode({...props.node, remarks: [
            ...props.node.remarks,
            ""
        ]})
    };

    const updateRemark = (value: string, index: number) => {
        props.setNode({...props.node, remarks: [
            ...props.node.remarks.slice(0, index),
            value,
            ...props.node.remarks.slice(index + 1),
        ]})
    };
    
    const deleteRemark = (index: number) => {
        props.setNode({...props.node, remarks: [
            ...props.node.remarks.slice(0, index),
            ...props.node.remarks.slice(index + 1),
        ]})
    };

    return <Stack direction="column" spacing={2}>
        <Typography variant="h6">{language.inspection.remarks}</Typography>

        {
            props.node.remarks.map((remark: string, index: number) => {
                return <Stack direction="row" spacing={1} key={index.toString()} sx={styles.alignedStack}>
                    <TextField
                        value={remark}
                        key={index}
                        label={""}
                        onChange={(event) => updateRemark(event.target.value, index)}
                        sx={styles.textBox}
                    />
                    <IconButton onClick={() => deleteRemark(index)}>
                        <Cancel />
                    </IconButton>
                </Stack>
            })
        }

        <Box>
            <Button onClick={addRemark} startIcon={<Add />}>
                <Typography>{language.inspection.addRemark}</Typography>
            </Button>
        </Box>
    </Stack>
}