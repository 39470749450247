import { TextField } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";

interface EmailInputProps {
  email: string;
  setEmail: (value: string) => void;
}

const styles = {
  textField: {
    mb: 2,
  },
};

export default function EmailInput(props: EmailInputProps) {
  const language = useLanguage();
  return (
    <TextField
      label={language.users.email}
      placeholder={language.users.email}
      value={props.email}
      onChange={(event: any) => props.setEmail(event.target.value)}
      sx={styles.textField}
    />
  );
}