import { Divider, Stack, Typography } from "@mui/material";
import { InspectionNode, Template } from "inspector-common/lib/model";
import StateSelector from "./StateSelector";
import InspectionEvaluation from "./InspectionEvaluation";
import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";
import ImageGrid from "./ImageGrid";
import Observations from "./Observations";
import { useEffect, useState } from "react";
import RoomHeader from "./RoomHeader";
import Remarks from "./Remarks";

interface InspectionDetailsProps {
    imagesLabel: string
    path: number[]
    node: InspectionNode
    assignmentId: string
    inspectionId: string
    templates: Template[]
    updateNode: (node: InspectionNode, path: number[]) => void
    deleteNode: (path: number[]) => void
    moveNodeUp: (path: number[]) => void
    moveNodeDown: (path: number[]) => void
    moveNode: (path: number[]) => void
    addNode: (path: number[], node: InspectionNode) => void

};

const styles = {
    contentsStack: {
        pl: 4
    }
};

export default function InspectionDetails(props: InspectionDetailsProps) {
    const [collapsed, setCollapsed] = useState<boolean>(true);

    const [localNode, setLocalNode] = useState(props.node);

    useEffect(() => {
        if (JSON.stringify(props.node) !== JSON.stringify(localNode)) {

            const timeout = setTimeout(() => props.updateNode(localNode, props.path), 1000);
            return () => clearTimeout(timeout)
        }
    }, [localNode])

    useEffect(() => {
        if (JSON.stringify(props.node) !== JSON.stringify(localNode)) {
            setLocalNode(props.node)
        }
    }, [JSON.stringify(props.node)])


    return <Stack direction="column" mt={Math.max(4 - props.path.length, 1)} spacing={2}>
        
        <RoomHeader
            path={props.path}
            node={localNode}
            updateThisNode={setLocalNode}
            collapsed={collapsed}
            setCollapsed={setCollapsed}
            templates={props.templates}
            deleteThisNode={() => props.deleteNode(props.path)}
            moveNodeUp={() => props.moveNodeUp(props.path)}
            moveNodeDown={() => props.moveNodeDown(props.path)}
            moveNode={() => props.moveNode(props.path)}
            addNode={(node: InspectionNode) => props.addNode([...props.path.slice(0, -1), props.path[-1] + 1], node)}
        />
        
            {!collapsed ? <Stack direction="column" spacing={2} sx={[styles.contentsStack, {display: collapsed ? "none" : "flex"}]}>
                <StateSelector 
                    node={localNode}
                    updateNode={setLocalNode}
                />

                <InspectionEvaluation node={localNode} updateNode={setLocalNode} />

                <Typography variant="h6">{props.imagesLabel}</Typography>
                <ImageGrid
                    assignmentId={props.assignmentId}
                    inspectionId={props.inspectionId}
                    images={localNode.image_references}
                    setImages={(images: InspectionImage[]) => setLocalNode({...localNode, image_references: images})}
                />
                <Remarks node={localNode} setNode={setLocalNode}/>
                <Observations
                    node={localNode}
                    updateNode={setLocalNode}
                    assignmentId={props.assignmentId}
                    inspectionId={props.inspectionId}
                />
            </Stack> : null}
        
        
        <Divider flexItem />
        <Divider flexItem />
        {
            !collapsed ? localNode.children.map((child: InspectionNode, index) => (
                <InspectionDetails 
                    key={index.toString()}
                    path={props.path.concat([index])}
                    node={child}
                    imagesLabel={props.imagesLabel}
                    assignmentId={props.assignmentId}
                    inspectionId={props.inspectionId}
                    templates={props.templates}
                    updateNode={props.updateNode}
                    deleteNode={props.deleteNode}
                    moveNodeUp={props.moveNodeUp}
                    moveNodeDown={props.moveNodeDown}
                    moveNode={props.moveNode}
                    addNode={props.addNode}

                />
            )) : <></>
        }

    </Stack>
}