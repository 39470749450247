import { useLanguage } from "inspector-common/lib/language";
import { useCreditBalance, useTransactions } from "../functions/hooks";
import { Button, Container, Stack, Typography } from "@mui/material";
import { Spinner } from "../../../components";
import TransactionList from "../components/TransactionList";
import { useNavigate } from "react-router-dom";

interface CreditPageProps {}

const styles = {
    container: {

    },
    button: {
        maxWidth: "300px"
    }

}

export default function CreditPage(props: CreditPageProps) {
    const language = useLanguage();
    const { credits, isLoading: isLoadingCredits } = useCreditBalance();
    const { transactions, isLoading: isLoadingTransactions } = useTransactions();
    const navigate = useNavigate();

    return <Container sx={styles.container}>
        <Stack direction="column" spacing={6}>
            <Typography variant="h2">{language.panels.credits}</Typography>
            
            {
                isLoadingCredits ? null : <Typography variant="h5">{language.credits.yourBalance}: {credits} {language.credits.credits}</Typography>
            }
            <Button onClick={() => navigate("/credits/buy")} variant="contained" sx={styles.button}>
                {language.credits.buyCredits}
            </Button>
            <Typography variant="h5">{language.credits.transactions}</Typography>

            {
                isLoadingTransactions ? <Spinner /> : <TransactionList transactions={transactions} />
            }
        </Stack>
    </Container>
}