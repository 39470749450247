import { Box, Button, Link, Stack, Tooltip, Typography } from "@mui/material"
import { GetDocumentDownloadDetailsResponse } from "inspector-common/lib/api/calls";
import { useGetDocumentDownloadDetails } from "inspector-common/lib/api/hooks";
import { useEffect, useState } from "react";
import { Spinner } from "../../../../components";
import { useLanguage } from "inspector-common/lib/language";
import { InspectionImage } from "inspector-common/lib/model/inspection/InspectionImage";
import JSZip from 'jszip';
import { saveAs } from 'file-saver';
import { downloadDocument } from "../../../inspection/functions";
import { InfoOutlined } from "@mui/icons-material";

interface DocumentDownloadProps {
    docxName: string
    assignmentId: string
    inspectionId: string
    images: InspectionImage[]
};

const styles = {
    box: {
        py: 4
    },
    documentBox: {},
    title: {}
};

export default function DocumentDownload(props: DocumentDownloadProps) {
    const language = useLanguage();
    const { execute: getDocumentDetails, isLoading } = useGetDocumentDownloadDetails();
    const [documentDetails, setDocumentDetails] = useState<GetDocumentDownloadDetailsResponse | null>(null);
    const [downloadingDocx, setDownloadingDocx] = useState<boolean>(false)
    const [downloadingPDF, setDownloadingPDF] = useState<boolean>(false)
    const [downloadingZip, setDownloadingZip] = useState<boolean>(false)

    useEffect(() => {
        getDocumentDetails({assignment_id: props.assignmentId, inspection_id: props.inspectionId}).then(setDocumentDetails);
    }, [])

    async function downloadImages() {
        setDownloadingZip(true);
        const zip = new JSZip();
        
        // Assuming `images` is an array of file URLs
        return Promise.all(
            props.images.map(async (image: InspectionImage, index: number) => {
                const imageUrl = image.url;
                if (imageUrl) {
                    const response = await fetch(imageUrl);
                    const blob = await response.blob();
                
                    zip.file(index.toString().padStart(8, "0")  + ".png", blob);
                }
            })
        ).then(() => {
            return zip.generateAsync({ type: 'blob' }).then((content) => {
                // Save the zip file with a custom name
                saveAs(content, 'images.zip');
                setDownloadingZip(false);
            })
        })
        // Generate the zip file

    }

    return <Box sx={styles.box}>
        <Stack direction="row" spacing={4} alignItems={"center"}>
            <Typography variant="h5">{language.document.title}</Typography>
            <Tooltip title={language.document.recencyWarning}>
                <InfoOutlined />
            </Tooltip>
        </Stack>
        {
            isLoading ? <Spinner /> : (
                documentDetails?.pdf_url && documentDetails?.pdf_present ? (
                    <Stack direction="row" sx={styles.documentBox} spacing={2} pt={2}>
                        {documentDetails?.pdf_present && documentDetails.pdf_url ? 
                            <Link 
                                sx={{cursor: "pointer"}}
                                onClick={() => {
                                    setDownloadingPDF(true);
                                    downloadDocument(documentDetails?.pdf_url ?? "", props.docxName.replace(".docx", ".pdf")).then(() => {
                                        setDownloadingPDF(false);
                                    })
                                }} >
                                <Button variant="contained" disabled={downloadingPDF}>{language.document.downloadLastSavedPDF}</Button>
                            </Link> : <></>}
                        {documentDetails?.docx_present && documentDetails?.docx_url ? 
                            <Link 
                                sx={{cursor: "pointer"}}
                                onClick={() => {
                                    setDownloadingDocx(true);
                                    downloadDocument(documentDetails?.docx_url ?? "", props.docxName).then(() => {
                                        setDownloadingDocx(false);
                                    })
                                }} >
                                <Button variant="contained" disabled={downloadingDocx}>{language.document.downloadLastSavedDocx}</Button>
                            </Link> : <></>}
                        {<Button variant="contained" onClick={downloadImages} disabled={downloadingZip}>{language.document.downloadImages}</Button>}
                    </Stack>
                ) : (
                    <Typography>{language.document.notAvailable}</Typography>
                )
            )
        }
    </Box>
};

