import { Button, Stack, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { useNavigate } from "react-router-dom";

interface NoTermsPlaceholderProps {};

export default function NoTermsPlaceholder(props: NoTermsPlaceholderProps) {
    const language = useLanguage();
    const navigate = useNavigate();
    
    return <Stack direction={"column"} spacing={2}>
        <Typography>{language.document.terms.noTermsPlaceholder}</Typography>
        <Button onClick={() => navigate("/terms/create")}>{language.document.terms.create}</Button>
    </Stack>
}