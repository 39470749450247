import { AlignmentType, BorderStyle, ExternalHyperlink, ImageRun, Paragraph, Table, TableCell, TableRow, TabStopPosition, TextRun, VerticalAlign, WidthType } from "docx";
import { getDateString } from "../utils";
export function createTabbedImages(images, executionDate) {
    var size = 3;
    var batches = [];
    for (var i = 0; i < images.length; i += size) {
        batches.push(images.slice(i, i + size));
    }
    ;
    var cellWidth = Math.floor(TabStopPosition.MAX / size);
    var borders = {
        top: { color: "FFFFFF", size: 0, style: BorderStyle.NIL },
        bottom: { color: "FFFFFF", size: 0, style: BorderStyle.NIL },
        left: { color: "FFFFFF", size: 0, style: BorderStyle.NIL },
        right: { color: "FFFFFF", size: 0, style: BorderStyle.NIL },
    };
    function addEmptyCells(cells) {
        return cells.concat.apply(cells, [1, 2, 3].map(function () { return new TableCell({
            width: {
                size: cellWidth,
                type: WidthType.DXA
            },
            children: [],
            borders: borders
        }); })).slice(0, 3);
    }
    return [
        new Table({
            borders: borders,
            width: {
                size: TabStopPosition.MAX,
                type: WidthType.DXA
            },
            rows: batches.flatMap(function (batch) {
                return [
                    new TableRow({
                        cantSplit: true,
                        children: addEmptyCells(batch.map(function (image) {
                            return new TableCell({
                                verticalAlign: VerticalAlign.CENTER,
                                width: {
                                    size: cellWidth,
                                    type: WidthType.DXA
                                },
                                margins: {
                                    top: 20,
                                    bottom: 20
                                },
                                children: [
                                    new Paragraph({
                                        children: [
                                            new ExternalHyperlink({
                                                children: [
                                                    new ImageRun({ data: image.data, transformation: { width: 180, height: image.height / (image.width / 180) } })
                                                ],
                                                link: image.link
                                            })
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.CENTER,
                                        children: [
                                            new TextRun(""),
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.CENTER,
                                        children: [
                                            new TextRun({
                                                children: [
                                                    getDateString(image.date).length === 10 ? getDateString(image.date) : executionDate
                                                ]
                                            }),
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.CENTER,
                                        children: [
                                            new TextRun(""),
                                        ]
                                    }),
                                    new Paragraph({
                                        alignment: AlignmentType.CENTER,
                                        children: [
                                            new TextRun({
                                                children: [
                                                    image.description
                                                ]
                                            }),
                                        ]
                                    })
                                ],
                                borders: borders,
                            });
                        }))
                    }),
                ];
            })
        })
    ];
}
