import { IPath } from "inspector-common/lib/model/inspection/InspectionImage";

export type Coordinate = {
    x: number;
    y: number;
};
export const PEN_COLOR = "#06d6a0";

export function drawLine(canvas: HTMLCanvasElement, start: Coordinate, end: Coordinate) {

    const context = canvas.getContext('2d');
    if (context) {
        context.strokeStyle = PEN_COLOR;
        context.lineJoin = 'round';
        context.lineWidth = 5;

        context.beginPath();
        context.moveTo(start.x, start.y);
        context.lineTo(end.x, end.y);
        context.closePath();
        context.stroke();
    }
}

function createRotatedImage(canvas: HTMLCanvasElement, img: HTMLImageElement, angle: number, width: number, height: number) {
    const radians = 2 * Math.PI * angle / 360;
    let w = width;
    let h = height;

    if (angle === 90 || angle === 270 || angle === -90 || angle === -270) {
        w = height;
        h = width
    }
    
    canvas.width  = w  ;
    canvas.height = h ;
    var newCtx = canvas.getContext('2d') ;
    if (newCtx) {
        newCtx.translate ( w / 2, h / 2) ;
        newCtx.rotate  (radians);
        newCtx.drawImage ( img, - width / 2, - height / 2, width, height) ; 
        newCtx.rotate   (-radians) ;
        newCtx.translate ( -w / 2, -h / 2) ;

    }

}

export function drawImage(canvas: HTMLCanvasElement, image: HTMLImageElement, width: number, height: number, paths: IPath[], angle: number) {

    let ctx = canvas.getContext("2d");
    try {
        if (!angle) {
            canvas.width = width;
            canvas.height = height;
            ctx?.drawImage(image, 0, 0, width, height);
        } else {
            createRotatedImage(canvas, image, angle, width, height);
        }
        if (canvas && width > 0 && height > 0) {
            paths.forEach((path) => {
                path.segments.forEach((segment, index) => {
                    if (index > 0) {
                        const coordinate1X: number = +(path.segments[index - 1].split(" ")[1]) * width;
                        const coordinate1Y: number = +(path.segments[index - 1].split(" ")[2]) * height;
                        const coordinate2X: number = +(segment.split(" ")[1]) * width;
                        const coordinate2Y: number = +(segment.split(" ")[2]) * height;
                        drawLine(canvas, {x: coordinate1X, y: coordinate1Y}, {x: coordinate2X, y: coordinate2Y})
                    }

                })
            })
        }
        
    } catch {

    }

}