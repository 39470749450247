import { Box, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";

interface ChangePasswordTitleProps { };

const styles = {
    container: {
        my: 2
    },
    text: {
        fontWeight: "bold"
    }
};

export default function ChangePasswordTitle(props: ChangePasswordTitleProps) {
    const language = useLanguage();
    return <Box sx={styles.container}>
        <Typography variant="h4" sx={styles.text}>
            {language.users.changePassword}
        </Typography>
    </Box>
}