var _a;
import mockClient from "./mockClient";
import Client from "./client";
var client = mockClient;
if (window && window.location && window.location.origin) {
    if (((_a = window === null || window === void 0 ? void 0 : window.location) === null || _a === void 0 ? void 0 : _a.href) && window.location.href.includes("localhost:3000")) {
        console.log("Using mock client");
        client = mockClient;
    }
    else {
        console.log("Using real API Client");
        client = new Client();
    }
}
else {
    console.log("Using mock client, assuming app");
    // app
    client = mockClient;
}
;
export default client;
