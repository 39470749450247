import { Box, Typography } from "@mui/material";
import { useLanguage } from "inspector-common/lib/language";
import { MutableRefObject, useEffect, useRef, useState } from "react";
import { Spinner } from "../../../components";
import { useGetCompanyLogo } from "inspector-common/lib/api/hooks";
import { GetCompanyLogoResponse } from "inspector-common/lib/api/calls";

interface LogoUploadProps {}

const makeStyles = (isUploading: boolean) => ({
    imageBox: {
        width: "400px",
        height: "400px",
        position: "relative"
    },
    image: {
        width: "400px",
        height: "400px",
    },
    placeHolder: {
        zIndex: 1,
        opacity: isUploading ? 1 : "0.5",
        position: "absolute",
        left: 0, 
        top: 0,
        width: "394px",
        height: "394px",
        display: "flex",
        backgroundColor: "lightgrey",
        textAlign: "center",
        alignItems: "center",
        cursor: "pointer",
        borderStyle: "dotted",
        borderWidth: 3,
    },
    text: {
        alignContent: "center"
    }
})



export default function LogoUpload(props: LogoUploadProps) {
    const fileInput: MutableRefObject<any> = useRef(null);
    const [isUploading, setIsUploading] = useState(false);
    const language = useLanguage();
    const [logoUrls, setLogoUrls] = useState<GetCompanyLogoResponse | null>(null);
    const styles = makeStyles(isUploading);
    const { execute } = useGetCompanyLogo();

    function handleFileUpload(files: FileList | null) {
        setIsUploading(true);

        if (files && logoUrls?.upload_url) {
            fetch(logoUrls?.upload_url, {
                method: "PUT",
                body: new Blob([files[0]]),
                headers: {"Content-Type": "application/octet-stream"}
            }).then(() => {
                return loadLogo()
            }).catch(console.error)
            .finally(() => setIsUploading(false))
        };
    
    };

    const loadLogo = () => {
        return execute({}).then((response: GetCompanyLogoResponse | null) => {
            if (response?.url) {
                return setLogoUrls(response)
            }
        })
    }

    useEffect(() => {loadLogo()}, []);

    return <Box>
        <input 
            type='file' 
            id='file'
            ref={fileInput}
            style={{display: "none"}}
            onChange={(event) => {handleFileUpload(event.target.files)} }
        />
        { 
            <Box sx={[styles.imageBox]}>
                <Box sx={styles.placeHolder} onClick={() => {
                    if (fileInput.current) {
                        fileInput.current.click()
                    }
                }}>
                    {
                        isUploading ? <Spinner /> :
                        <Typography sx={styles.text}>{language.company.uploadLogo}</Typography>
                    }
                    
                </Box>
                {logoUrls?.url ? <img src={logoUrls.url} style={styles.image as any} /> : <></>}
            </Box>
        }
    </Box> 
}