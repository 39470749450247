import { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { useGetAssignment } from "inspector-common/lib/api/hooks/assignments/useGetAssignment";
import { Spinner } from "../../../components";
import { Assignment, Inspection } from "inspector-common/lib/model";
import { EditAssignmentInputs, InspectionsInput } from "../components";
import EditAssignmentTitle from "../components/title/EditAssignmentTitle";
import { useAuth } from "../../../auth";
import { useGetInspections } from "inspector-common/lib/api/hooks";

interface EditAssignmentPageProps {};

function EditAssignmentPage(props: EditAssignmentPageProps) {
    // TODO Move to shared state
    const auth = useAuth();
    const { assignmentId } = useParams();
    const [inspections, setInspections] = useState<Array<Inspection>>([]);

    const {
        isLoading,
        error,
        data,
        execute,
        setData
    } = useGetAssignment();
    const { isLoading: isLoadingInspections, execute: getInspections } = useGetInspections();

    useEffect(() => {
        if (assignmentId !== undefined) {
            execute({identifier: assignmentId})
            getInspections({assignment_id: assignmentId}).then((response) => {
                if (response?.inspections) {
                    console.log(response)
                    setInspections(response.inspections);
                }
            });
        }

    }, [execute, auth.config.accessToken])
    
    if (error) {
        return <>Error!</>
    }
    if (isLoading || !data?.assignment) {
        return <Spinner />
    } 

    const setAssignmentProp = (value: Assignment) => setData({assignment: value})

    return <>
        <EditAssignmentTitle name={(data?.assignment as Assignment).name} />
        <InspectionsInput
            isLoading={isLoadingInspections}
            inspections={inspections}
            setInspections={setInspections}
            assignmentId={assignmentId ?? ""}
        />

        <EditAssignmentInputs
            assignment={data?.assignment as Assignment}
            setAssignment={setAssignmentProp}
            inspections={inspections}
            setInspections={setInspections}
        />
    </>
};

export default EditAssignmentPage;